import { createPinia } from 'pinia'
import { Fragment, createApp, h } from 'vue'

import App from '@/application/App.vue'
import '@/application/styles/main.scss'
import { i18n } from '@/application/i18n/i18n'
import router from '@/application/router'
import store from '@/common/stores/store'
import { globalErrorHandler } from '@/common/utils/errors/error-handlers'
import startSentryMonitoring from '@/application/utils/sentry'
import { prepareApp } from '@/application/utils/prepare-app'

const pinia = createPinia()

async function mountVueApplication() {
  let app = null

  // Turns on vue-axe if the VUE_APP_ENABLE_AXE flag is set to yes
  if (import.meta.env.VUE_APP_ENABLE_AXE === 'yes') {
    const VueAxe = await import('vue-axe')
    app = createApp({
      render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)]),
    })
    app.use(VueAxe.default)
  } else {
    app = createApp(App)
  }

  // Sentry monitoring must start before making API requests and setting up the application state
  startSentryMonitoring(app)

  const isAppSuccessfullyPrepared = await prepareApp()
  if (!isAppSuccessfullyPrepared) {
    return // prevent the rest of the code from running after the user has been redirected to the login page
  }

  app.use(pinia)
  app.use(store)
  app.use(i18n)

  // Workaround for the case that this.$t is not available in async functions.
  // See https://github.com/intlify/vue-i18n-next/issues/990#issuecomment-1123565067
  app.use({
    install(appInner) {
      appInner.config.globalProperties.$t = i18n.global.t
    },
  })

  app.use(router)

  app.config.errorHandler = globalErrorHandler

  // TODO: Check if this is necessary.
  await router.isReady()

  app.mount('#app')
}

mountVueApplication()
