<template>
  <aside :class="asideClass">
    <div
      class="aside-navigation__overlay"
      @click="closeMobileMenu"
    />
    <header class="aside-navigation__header">
      <button
        class="ui-button ui-button--borderless ui-button--icon-only aside-navigation__button--mobile ui-margin-12--right"
        type="button"
        @click="expandMobileMenu"
      >
        <Fa
          class="ui-icon--small"
          :icon="isMobileMenuVisible ? faClose : faBars"
        />
        <span class="sr-only"> {{ t('aside.toogle.mobile') }} </span>
      </button>
      <a
        id="navigation-logo"
        class="aside-navigation__header-logo"
        href="/"
        @click="NavigationAnalytics.trackNavigationMenuItemClick(
          {name: 'navigation-logo',
           navigationMenuIsExpanded: isExpanded,
           navigationIsTabletOrMobile: isMobileMenuVisible
          })"
      >
        <img
          :src="SendcloudLogo"
          :class="{ 'navigation-logo--cropped': isExpanded }"
          :alt="t('global.navigation.header.logo')"
        >
      </a>
      <UiTooltip
        for="expand-sidebar-button"
        :text="isExpanded ? t('Collapse') : t('Expand')"
        placement="right"
        :offset-size="10"
      />
      <button
        id="expand-sidebar-button"
        class="ui-button ui-button--borderless ui-button--icon-only aside-navigation__button--desktop"
        type="button"
        @click="expandAside"
      >
        <Fa
          class="ui-icon"
          :icon="isExpanded ? faAngleLeft : faAngleRight"
          size="0.65x"
        />
        <span class="sr-only"> {{ expandCollapseLabel }} </span>
      </button>
    </header>

    <div
      :class="{ 'aside-navigation__menu--open': isMobileMenuVisible }"
      class="aside-navigation__menu"
    >
      <AppSidebarNavigation
        :is-expanded="isExpanded"
        :is-mobile-menu-visible="isMobileMenuVisible"
      />
    </div>
  </aside>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch, provide } from 'vue'
import { useRoute } from 'vue-router'
import { useNavigationStore } from '@/application/stores'
import Fa from 'vue-fa'
import {
  faBars,
  faClose,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons'
import { useI18n } from 'vue-i18n'
import { UiTooltip } from '@sendcloud/ui-library'
import { debounce } from '@/common/utils/debounce'
import SendcloudLogo from '@/application/assets/img/sendcloud_logo.svg'
import AppSidebarNavigation from '@/application/components/navigation/AppSidebarNavigation.vue'
import NavigationAnalytics from '@/application/utils/navigation.analytics'

const { t } = useI18n()

const route = useRoute()

const isMobileMenuVisible = ref(false)

const applicationStore = useNavigationStore()
const { setNavigationIsExpanded } = applicationStore

const isExpanded = computed(() => {
  return applicationStore.isNavigationExpanded
})

const expandMobileMenu = () => {
  isMobileMenuVisible.value = !isMobileMenuVisible.value
  if (isMobileMenuVisible.value) {
    document.body.classList.add('topbar__body--no-scroll')
  } else {
    document.body.classList.remove('topbar__body--no-scroll')
  }
}

const closeMobileMenu = () => {
  isMobileMenuVisible.value = false
  document.body.classList.remove('topbar__body--no-scroll')
}

const expandAside = () => {
  const newExpandedValue = !isExpanded.value
  setNavigationIsExpanded(newExpandedValue)
  NavigationAnalytics.trackNavigationExpandOrCollapsed(newExpandedValue)
}

watch(route, () => {
  if (isMobileMenuVisible.value) {
    closeMobileMenu()
  }
})

onMounted(async () => {
  window.addEventListener('resize', windowResizeListener)
  windowResizeListener()
})

onUnmounted(() => {
  window.removeEventListener('resize', windowResizeListener)
})

const windowResizeListener = debounce(() => {
  checkIsTablet()
}, 200)

function checkIsTablet(): void {
  if (isMobileMenuVisible.value) {
    isMobileMenuVisible.value = window.innerWidth <= 1200
  }
}

const expandCollapseLabel = computed(() => {
  return isExpanded.value ? t('aside.collapse.button') : t('aside.expand.button')
})

const asideClass = computed(() => ({
  'aside-navigation--collapsed': !isExpanded.value && !isMobileMenuVisible.value,
  'aside-navigation__menu--mobile': isMobileMenuVisible.value,
  'aside-navigation--expanded': isExpanded.value || isMobileMenuVisible.value,
}))

provide('isExpanded', isExpanded)
provide('isMobileMenuVisible', isMobileMenuVisible)
</script>

<style lang="scss" scoped>
$animation-speed: 0.25s;

.aside-navigation--collapsed {
  background: $sc-dark-blue;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 102;

  @include breakpoint-min-width(extra-large) {
    height: 100vh;
    max-width: 68px;
    left: 0;
    position: fixed;
  }
}

.aside-navigation__header-logo {
  padding: 0;
}

.aside-navigation--collapsed .aside-navigation__header-logo  {
  @include breakpoint-min-width(extra-large) {
    width: 34px;
    height: 34px;
    overflow: hidden;
  }
}

.aside-navigation__header {
  background: $sc-dark-blue;

  // we need to know this in other parts of the app
  height: $app-navigation-header-height;
  padding: 6px 6px 6px 10px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $sc-dark-blue-75;
  position: fixed;
  top: 0;
  z-index: 101;
  overflow: visible;

  .ui-button {
    color: $sc-white;
  }

  @include breakpoint-min-width(extra-large) {
    justify-content: space-between;
  }
}

.aside-navigation--expanded .aside-navigation__header {
  width: 100%;

  @include breakpoint-min-width(extra-large) {
    max-width: 230px;
    background: $sc-dark-blue;
  }
}

.aside-navigation--collapsed .aside-navigation__header {
  width: 100%;

  @include breakpoint-min-width(extra-large) {
    width: 68px;
    justify-content: center;
    padding: 6px;
  }
}

.aside-navigation--collapsed .aside-navigation__item--active {
  @include breakpoint-min-width(extra-large) {
    width: 68px;
    justify-content: flex-start;
  }
}

.aside-navigation__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $sc-dark-blue;
  overflow: hidden;
  z-index: 10;
  transform: translateY(-110%);
  transition: transform $animation-speed ease-in-out;

  @include breakpoint-min-width(medium) {
    transform: translateX(-100%);
    width: 230px;
  }

  @include breakpoint-min-width(extra-large) {
    width: 68px;
    transform: translateX(0);
    overflow: initial;
  }
}

.aside-navigation__menu--open {
  transform: translateY(0);

  @include breakpoint-min-width(medium) {
    transform: translateX(0);
  }
}

.aside-navigation--expanded {
  @include breakpoint-min-width(extra-large) {
    width: 230px;
    min-width: 230px;
  }

  .aside-navigation__menu {
    @include breakpoint-min-width(extra-large) {
      width: 230px;
    }
  }
}

.aside-navigation__button--mobile {
  display: block;

  @include breakpoint-min-width(extra-large) {
    display: none;
  }
}

.aside-navigation__button--desktop {
  display: none;

  @include breakpoint-min-width(extra-large) {
    display: flex;
    background: $sc-dark-blue;
    width: 24px;
    height: 24px;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    border: 1px solid $sc-dark-blue-75;
    bottom: -10px;
    right: -10px;
    opacity: 1;
    z-index: 2;
  }
}

.aside-navigation__header .aside-navigation__button--desktop:not(:disabled):active{
  background:  $sc-informative;
  border: none;
}

.aside-navigation__header .aside-navigation__button--desktop:not(:disabled):focus{
  @include breakpoint-min-width(extra-large) {
    box-shadow: none;
    border: 1px solid #4D5E81;
    display: flex;
    opacity: 1;
  }
}

.aside-navigation__header .aside-navigation__button--desktop:not(:disabled):hover{
  background: $sc-dark-blue-75;
}

.aside-navigation__menu--mobile .aside-navigation__overlay {
  background-color: $sc-dark-blue;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  @include breakpoint-max-width(medium) {
    display: none;
  }

  @include breakpoint-min-width(extra-large) {
    display: none;
  }
}
</style>

<style lang="scss">
.aside-navigation__header .ui-tooltip,
.aside-navigation__menu .ui-tooltip {
  background-color: $sc-dark-blue;
  color: $sc-white;
  padding: $sc-spacing-4 $sc-spacing-8;
  width: max-content;

  .ui-tooltip__arrow {
    display: none;
  }
}</style>
