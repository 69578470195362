export enum ReturnListTab {
  REQUESTS = 'requests',
  IN_TRANSIT = 'in_transit',
  RETURNED = 'returned',
  COMPLETED = 'completed',
  REJECTED_CANCELLED = 'rejected_cancelled',
}

export enum ReturnStatus {
  OPEN = 'open',
  REQUESTED = 'requested',
  AWAITING_PAYMENT = 'awaiting_payment',
  PAYMENT_FAILED = 'payment_failed',
  REQUEST_REJECTED = 'request_rejected',
}
