const GOOGLE_ANALYTICS_ID = 'G-G20N49N1GG'

const gtag = function (...args: unknown[]) {
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  window.dataLayer.push(...args)
}

export default {
  isTrackingAllowed: false,

  trackEvent(category: string, action: string, label?: string, value?: unknown) {
    if (!this.isTrackingAllowed) return

    gtag('event', category, {
      action,
      label,
      value,
    })
  },

  install(installParams: { isTrackingAllowed: boolean, userId: number }) {
    this.isTrackingAllowed = installParams.isTrackingAllowed
    if (!this.isTrackingAllowed) return

    if (navigator.userAgent && !navigator.userAgent.match(/pingdom/i)) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`
      document.body.append(script)

      gtag('js', new Date())
      gtag('set', 'cookie_flags', 'SameSite=None; Secure; Path=/; Partitioned;')
      gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'granted',
      })
      gtag('config', GOOGLE_ANALYTICS_ID, {
        user_id: installParams.userId,
      })
    }
  },
}
