import tippy, { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'

export default function initializeTippy() {
  tippy.setDefaultProps({
    arrow: roundArrow,
    duration: [400, 200],
    inertia: true,
    ignoreAttributes: true,
    theme: 'light',
    trigger: 'mouseenter',
  })
}
