/** @typedef {typeof state} ModuleState */
/** @typedef {import('@/features/addresses/types/addresses.types').SenderAddress} SenderAddress */

import {
  SENDER_ADDRESS_CREATE_ADDRESS,
  SENDER_ADDRESS_DELETE_ADDRESS,
  SENDER_ADDRESS_FETCH_ADDRESSES,
  SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS,
  SENDER_ADDRESS_SET_DEFAULT,
  SENDER_ADDRESS_STORE_LAST_USED_ADDRESS,
  SENDER_ADDRESS_UPDATE_ADDRESS,
  SENDER_ADDRESS_FETCH_ADDRESS,
} from '@/features/addresses/stores/sender-addresses/action.types.js'
import {
  SENDER_ADDRESS_LAST_USED_ADDRESS,
  SENDER_ADDRESS_SET_ADDRESSES,
  SENDER_ADDRESS_SET_ADDRESS,
} from '@/features/addresses/stores/sender-addresses/mutation.types.js'

import { getVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'
import SenderAddressService from '@/features/addresses/api/sender-address.api.js'

const state = {
  /** @type {SenderAddress | {}} */ address: {},
  /** @type {Array<SenderAddress>} */ senderAddresses: [],
  /** @type {number} */ lastUsedSenderAddressId: null,
}

const getters = {
  senderAddresses: (/** @type {ModuleState} */ state) => state.senderAddresses,

  senderCountries: (/** @type {ModuleState} */ state) => {
    return state.senderAddresses
      .filter((address, index, array) => {
        return array.findIndex(element => element.country_name === address.country_name) === index
      })
      .map((address) => {
        return {
          name: address.country_name,
          iso_2: address.country_iso_2,
        }
      })
      .sort((countryA, countryB) => countryA.name.localeCompare(countryB.name))
  },

  currentSenderAddress: (/** @type {ModuleState} */ state) => {
    if (/** @type {ModuleState} */ state.lastUsedSenderAddressId !== null) {
      const lastUsedSenderAddress = state.senderAddresses.find((senderAddress) => {
        return senderAddress.id === state.lastUsedSenderAddressId
      })

      if (lastUsedSenderAddress !== undefined) {
        return lastUsedSenderAddress
      }
    }

    const activeSenderAddress = state.senderAddresses.find(address => address.is_active === true)
    if (activeSenderAddress !== undefined) {
      return activeSenderAddress
    }

    if (/** @type {ModuleState} */ state.senderAddresses[0] !== undefined) {
      return state.senderAddresses[0]
    }

    return null
  },

  findSenderAddressById: (/** @type {ModuleState} */ state) => {
    return (senderAddressId) => {
      return state.senderAddresses.find(senderAddress => senderAddress.id === senderAddressId)
    }
  },

  hasInitialAddress: (/** @type {ModuleState} */ state) => {
    return state.senderAddresses.some(senderAddress => senderAddress.initial_address)
  },
}

const actions = {
  async [SENDER_ADDRESS_CREATE_ADDRESS]({ dispatch }, senderAddress) {
    await SenderAddressService.create(senderAddress)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
  },

  async [SENDER_ADDRESS_DELETE_ADDRESS]({ dispatch, state }, senderAddressId) {
    await SenderAddressService.destroy(senderAddressId)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)

    if (senderAddressId === state.lastUsedSenderAddressId) {
      dispatch(SENDER_ADDRESS_STORE_LAST_USED_ADDRESS, null)
    }
  },

  async [SENDER_ADDRESS_FETCH_ADDRESSES]({ commit }) {
    const response = await SenderAddressService.findAll()
    commit(SENDER_ADDRESS_SET_ADDRESSES, response.data)
  },

  async [SENDER_ADDRESS_UPDATE_ADDRESS]({ dispatch }, address) {
    await SenderAddressService.update(address)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
  },

  async [SENDER_ADDRESS_SET_DEFAULT]({ dispatch }, senderAddressId) {
    await SenderAddressService.setActive(senderAddressId)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
  },

  async [SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS]({ commit, getters }) {
    const senderAddressId = getVariable(getters.user.id, LOCAL_STORAGE_KEYS.SENDER_ADDRESS_ID)

    if (senderAddressId) {
      commit(SENDER_ADDRESS_LAST_USED_ADDRESS, senderAddressId)
    }
  },

  [SENDER_ADDRESS_STORE_LAST_USED_ADDRESS]({ commit, getters }, senderAddressId) {
    setVariable(getters.user.id, LOCAL_STORAGE_KEYS.SENDER_ADDRESS_ID, senderAddressId)
    commit(SENDER_ADDRESS_LAST_USED_ADDRESS, senderAddressId)
  },

  async [SENDER_ADDRESS_FETCH_ADDRESS]({ commit }, senderAddressId) {
    const response = await SenderAddressService.find(senderAddressId)
    commit(SENDER_ADDRESS_SET_ADDRESS, response.data)
  },
}

const mutations = {
  /**
   * @param {ModuleState} state
   * @param {Array<SenderAddress>} senderAddresses
   */
  [SENDER_ADDRESS_SET_ADDRESSES](state, senderAddresses) {
    state.senderAddresses = senderAddresses
  },

  /**
   * @param {ModuleState} state
   * @param {number} senderAddressId
   */
  [SENDER_ADDRESS_LAST_USED_ADDRESS](state, senderAddressId) {
    state.lastUsedSenderAddressId = senderAddressId
  },

  [SENDER_ADDRESS_SET_ADDRESS](state, address) {
    state.address = address
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
