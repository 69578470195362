import { createStore } from 'vuex'

import core from '@/common/stores/core'
import integrations from '@/features/integrations/stores'
import notifications from '@/features/notifications/stores/notifications'
import boxes from '@/features/boxes/store/boxes'
import pickupAddresses from '@/features/addresses/stores/pickup-addresses'
import senderAddresses from '@/features/addresses/stores/sender-addresses'
import shipping from '@/features/shipment-tabs/stores/shipping'
import shippingDefaults from '@/features/shipping-defaults/stores'
import returnAddresses from '@/features/addresses/stores/return-addresses'
import returns from '@/features/returns/stores'
import returnDefaults from '@/features/return-defaults/stores'
import returnsSettings from '@/features/return-portal/stores'
import invoiceAddresses from '@/features/addresses/stores/invoice-addresses'
import carrierContracts from '@/features/carriers/stores/carrier-contracts'
import returnRules from '@/features/return-rules/stores/return-rules'
import checkoutRules from '@/features/dynamic-checkout-rules/stores'
import support from '@/features/support/stores'
import onboarding from '@/features/onboarding-wizard/stores'

/** @type {import('node_modules/vuex/types/index').StoreOptions} */
export const storeConfig = {
  modules: {
    carrierContracts,
    core,
    integrations,
    notifications,
    boxes,
    pickupAddresses,
    senderAddresses,
    shipping,
    shippingDefaults,
    returnAddresses,
    returns,
    returnDefaults,
    returnsSettings,
    invoiceAddresses,
    returnRules,
    checkoutRules,
    support,
    onboarding,
  },
  // NOTE: Turning this on with a large store (e.g. with many shipping methods in the store) will cause
  // significant slowdowns on navigation and interaction, as it runs a deep watch on the state.
  // It must never be turned on for production builds.
  // You can optionally turn it on locally to check that you did not mutate store state outside of mutations.
  // Read more: https://vuex.vuejs.org/guide/strict.html
  strict: false,
}

const store = createStore(storeConfig)

export default store
