import type { RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

export const SHIPPING_LABEL_DETAILS_ROUTE = 'parcels.parcel.details'
export default {
  name: SHIPPING_LABEL_DETAILS_ROUTE,
  path: '/shipping/:id/details',
  component: () => import('@/features/label-details/pages/LabelDetailsPage.vue'),
  props: (route: RouteRecordRaw & { params: { id: string } }): { parcelId: number } => ({
    parcelId: Number.parseInt(route.params.id),
  }),
  meta: {
    pageTitle: () => i18n.t('Label details'),
    permissions: ['view_parcels'],
  },
} satisfies RouteRecordRaw
