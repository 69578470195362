import axios from 'axios'
import { getAbsoluteBackendURL } from '@/common/utils/backend'
import { logError } from '@/common/utils/errors/error-handlers'

const client = axios.create({
  baseURL: getAbsoluteBackendURL(import.meta.env.VUE_APP_DYNAMIC_CHECKOUT_BASE_URL),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

client.interceptors.response.use(
  response => response,
  (error) => {
    logError(error)
    return Promise.reject(error)
  },
)

export default client
