import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { defineStore } from 'pinia'

import { getVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'

export const useNavigationStore = defineStore('navigation', () => {
  const store = useStore()

  /**
   * STATE
   */
  const navigationIsExpanded = ref(getVariable(store.getters.user.id, LOCAL_STORAGE_KEYS.NAVIGATION_IS_EXPANDED) ??
    true)

  /**
   * GETTERS
   */
  const isNavigationExpanded = computed(() => navigationIsExpanded.value)

  /**
   * ACTIONS
   */
  const setNavigationIsExpanded = (isExpanded: boolean) => {
    navigationIsExpanded.value = isExpanded
  }

  watch(navigationIsExpanded, (newValue) => {
    setVariable(store.getters.user.id, LOCAL_STORAGE_KEYS.NAVIGATION_IS_EXPANDED, newValue)
  })

  return {
    // STATE
    navigationIsExpanded,
    // GETTERS
    isNavigationExpanded,
    // ACTIONS
    setNavigationIsExpanded,
  }
})
