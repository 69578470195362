import { nextTick } from 'vue'

import store from '@/common/stores/store'
import { CORE_UPDATE_PAGE_TITLE } from '@/common/stores/core/action.types'
import type { RouteLocationNormalized } from 'vue-router'

/**
 * Responsible for setting the `<title>` element’s content
 * according to the route’s meta properties.
 */
export function setPageTitle(to: RouteLocationNormalized) {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    const pageTitle = to.meta?.pageTitle?.() || 'Sendcloud'
    store.dispatch(CORE_UPDATE_PAGE_TITLE, pageTitle)
  })
}
