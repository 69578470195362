import { Context } from '../context'
import type { Parser } from '../types/parsers.types'
import { generateErrorMessage } from './helpers'

export const parse = <T>(
  data: unknown,
  parser: Parser<T>,
  inContextOf: string,
  handleErrorMsg: (e: string) => void,
): T => {
  const ctx = new Context({
    onAssertionError: ({ path, expected, got }) => {
      handleErrorMsg(generateErrorMessage({ path, expected, got, inContextOf }))
    },
  })
  return parser.parse(data, ctx)
}
