import { faSearch } from '@fortawesome/pro-solid-svg-icons'

import i18n from '@/application/i18n/i18n'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'

export const CSV_UPLOAD_ROUTE = 'base.csv-importer.upload'
export const CSV_CREATE_ROUTE = 'base.csv-importer.preset-create'

export default [
  {
    name: CSV_UPLOAD_ROUTE,
    path: '/importers/csv/upload',
    component: () => import('@/features/csv-upload/pages/CsvUpload.vue'),
    meta: {
      pageTitle: () => i18n.t('Upload new file'),
      permissions: ['import_orders'],
      buttons: [
        {
          name: SHIPPING_INCOMING_ORDERS_ROUTE,
          title: () => i18n.t('View uploaded data'),
          icon: faSearch,
          mode: 'primary',
        },
      ],
    },
  },
  {
    name: CSV_CREATE_ROUTE,
    path: '/importers/csv/preset-create',
    component: () => import('@/features/csv-upload/pages/CsvPresetCreator.vue'),
    meta: {
      pageTitle: () => i18n.t('Create preset'),
      permissions: ['import_orders'],
    },
  },
]
