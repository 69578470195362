import axios from '@/common/utils/axios'
import type { PaginatedResponse } from '@/types/models'
import type { Notification, NotificationSummary } from '@/features/notifications/types/notifications'

export async function fetchNotificationSummary(): Promise<NotificationSummary> {
  const response = await axios.get('/xhr/notification/summary')
  return response.data
}

export async function fetchNotifications(params = {}): Promise<PaginatedResponse<Notification>> {
  const response = await axios.get('/xhr/notification', { params })
  return response.data
}

export async function fetchNotification(notificationId: number): Promise<Notification> {
  const response = await axios.get(`/xhr/notification/${notificationId}`)
  return response.data
}

export async function markNotificationsAsRead(notificationIds: number[]): Promise<NotificationSummary> {
  const response = await axios.post(`/xhr/notification/mark_as_read?ids=${notificationIds.join(',')}`)
  return response.data
}
