import EventBus from '@/common/utils/event-bus'

/**
 * ## Using AppModal through ModalService
 *
 * - create the contents of the modal in a vue component
 * - register the component and lazy load it in `AppModal.vue`. example:
 *
 * ```
 *   components: {
 *     TestComponent: () => import('@/path/to/TestComponent.vue')
 *   },
 * ```
 * - use `ModalService.build(ComponentName, ComponentProperties)` to setup the modal,
 *  ComponentName is required, the rest are optional
 * - use `ModalService.destroy()` to clear the modal and close it
 *
 */
export default {
  /**
   * Builds a modal containing the specified componentName
   * The component to be opened must be registered in AppModal.vue
   * and should preferably be lazy-loaded
   */
  build(componentName: string, componentProperties?: { [key: string]: any }) {
    return EventBus.$emit('app-modal:build', componentName, componentProperties)
  },

  /**
   * Removes the current dynamic component from AppModal and closes the modal
   */
  destroy() {
    return EventBus.$emit('app-modal:destroy')
  },
}
