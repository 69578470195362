const isEnabled = () => !!window._sift

export default {
  isHijackedByAdmin: false,
  isStaff: false,

  trackPageView() {
    if (this.isHijackedByAdmin || this.isStaff) return

    if (!isEnabled()) {
      return
    }
    window._sift.push(['_trackPageview'])
  },

  install(installParams:
    { userId: number, accountId: string, siftSessionId: string, isHijackedByAdmin: boolean, isStaff: boolean }) {
    this.isHijackedByAdmin = installParams.isHijackedByAdmin
    this.isStaff = installParams.isStaff
    // Sift should never be loaded for anyone inside the company.
    if (this.isHijackedByAdmin || this.isStaff) return

    // In v2 this snippet is not in the html anymore.
    // This snippet should be always be loaded for customers.
    // Even the ones that opted out of being tracked!
    const e = document.createElement('script')
    e.src = 'https://cdn.sift.com/s.js'
    document.body.appendChild(e)

    const interval = setInterval(() => {
      if (!isEnabled()) {
        return
      }
      clearInterval(interval)
      window._sift.push(['_setAccount', installParams.accountId])

      if (installParams.userId) {
        window._sift.push(['_setUserId', installParams.userId])
      }
      if (installParams.siftSessionId) {
        window._sift.push(['_setSessionId', installParams.siftSessionId])
      }
      this.trackPageView()
    }, 5000)
  },
}
