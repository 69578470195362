<template>
  <footer
    v-if="isFooterVisible && !isFooterDismissed"
    :class="footerClasses"
  >
    <div class="main-wrapper">
      <span class="opportunity-center-footer__title">
        {{ t('opportunity_center.footer.title') }}
      </span>
      <div class="opportunity-center-footer__buttons">
        <router-link
          :to="
            {
              name: OPPORTUNITY_CENTER_ROUTE,
            }"
          class="ui-button ui-button--primary ui-margin-12--right"
          @click="returnToOpportunity"
        >
          <Fa
            :icon="faRocketLaunch"
            class="opportunity-center-footer__icon ui-margin-12--right"
          />
          <span>
            {{ t('opportunity_center.footer.button.text') }}
          </span>
        </router-link>
        <button
          class="ui-button ui-button--borderless ui-button--icon-only opportunity-center-footer__icon ui-margin-12--right"
          @click="dismissOpportunity"
        >
          <Fa
            :icon="faClose"
            type="button"
          />
          <span class="sr-only"> {{ t('opportunity_center.footer.button.close') }} </span>
        </button>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { OPPORTUNITY_CENTER_ROUTE } from '@/features/opportunity-center/routes'
import { getVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'
import Fa from 'vue-fa'
import {
  faClose,
  faRocketLaunch,
} from '@fortawesome/pro-regular-svg-icons'
import { trackButtonClicked } from '@/common/utils/tracking/events.analytics'

const route = useRoute()
const store = useStore()

const { t } = useI18n()

const user = computed(() => store.getters.user)

const isFooterDismissed = ref(getVariable(user.value.id, LOCAL_STORAGE_KEYS.FOOTER_OPPO_IS_VISIBLE) || false)
const isFooterVisible = computed(() => route.query.source === 'oppo' && route.query.action !== undefined)

const isAsideNavigationExpanded = ref(false)
const checkAsideNavigation = () => {
  isAsideNavigationExpanded.value = !!document.querySelector('.aside-navigation--expanded')
}

const observer = new MutationObserver(checkAsideNavigation)

onMounted(() => {
  checkAsideNavigation()
  observer.observe(document.body, { childList: true, subtree: true })
})

onUnmounted(() => observer.disconnect())

watch(
  [isFooterVisible, isFooterDismissed],
  ([visible, dismissed]) => {
    const webMessenger = document.getElementById('web-messenger-container')
    if (webMessenger) {
      webMessenger.style.bottom = visible && !dismissed ? '70px' : '20px'
    }
  },
  { immediate: true },
)

const footerClasses = computed(() => ({
  'opportunity-center-footer': true,
  '--expanded': isAsideNavigationExpanded.value,
}))

const returnToOpportunity = () => {
  trackButtonClicked({ button_action: 'Return to Discover Sendcloud', button_title: 'Return to Discover Sendcloud' })
}

const dismissOpportunity = () => {
  setVariable(user.value.id, LOCAL_STORAGE_KEYS.FOOTER_OPPO_IS_VISIBLE, true)
  isFooterDismissed.value = true
  trackButtonClicked({ button_action: 'Close Discover Sendcloud', button_title: 'Close' })
}
</script>

<style lang="scss" scoped>
.opportunity-center-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $sc-white;
    border-top: 1px solid $sc-light-grey;
    box-shadow: $sc-box-shadow;
    z-index: 2;

    .main-wrapper {
        @include breakpoint-min-width(large) {
            display: flex;
        }

        @include breakpoint-min-width(1440px) {
            margin-left: 69px;
            max-width: calc(100% - 69px);
        }

        @include breakpoint-min-width(1800px) {
            max-width: 1630px;
            margin-left: auto;
        }

        padding: $sc-spacing-20;
        display: none;
        justify-content: space-between;
        gap: $sc-spacing-16;
        flex-direction: row;
        align-items: center;
    }

    &.--expanded {
        .main-wrapper {
            @include breakpoint-min-width(1420px) {
                margin-left: 260px;
                max-width: calc(100% - 260px);
            }
        }
    }

}

.opportunity-center-footer__buttons {
    display: flex;
    gap: $sc-spacing-20;

    .ui-button {
        flex-grow: 1;
    }
}
</style>
