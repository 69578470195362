import type { RouteRecordRaw } from 'vue-router'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { PRODUCTS_BETA_FEATURE } from '@/features/products/beta-features'
import { SETTINGS_PRODUCTS_ROUTE } from '@/application/routes/settings.routes.names'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import type { RouteButton } from '@/common/types/route.types'
import i18n from '@/application/i18n/i18n'

export const SETTINGS_PRODUCTS_WIZARD_INTRO_ROUTE = 'products.wizard.intro'
export const SETTINGS_PRODUCTS_WIZARD_UPLOAD_ROUTE = 'products.wizard.upload'
export const SETTINGS_PRODUCTS_UPLOAD_ROUTE = 'products.upload'

const UPLOAD_BUTTON: RouteButton = {
  title: () => i18n.t('settings.products.upload_button'),
  name: SETTINGS_PRODUCTS_UPLOAD_ROUTE,
  icon: faUpload,
  mode: 'borderless',
  permissions: ['manage_products'],
}

export default [
  {
    name: SETTINGS_PRODUCTS_ROUTE,
    path: '/settings/products',
    component: () => import('@/features/products/pages/ProductsPage.vue'),
    beforeEnter: betaGuard(PRODUCTS_BETA_FEATURE, SHIPPING_INCOMING_ORDERS_ROUTE),
    meta: {
      pageTitle: () => i18n.t('settings.products.page_title'),
      permissions: ['manage_products'],
      parent: 'settings-products',
      buttons: [UPLOAD_BUTTON],
    },
  },
  {
    name: SETTINGS_PRODUCTS_WIZARD_INTRO_ROUTE,
    path: '/settings/products/wizard/intro',
    component: () => import('@/features/products/pages/ProductsWizardIntroPage.vue'),
    meta: {
      pageTitle: () => i18n.t('settings.products.upload_csv_page_title'),
      permissions: ['manage_products'],
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PRODUCTS_ROUTE },
        }
      },
      parent: 'settings-products',
    },
  },
  {
    name: SETTINGS_PRODUCTS_WIZARD_UPLOAD_ROUTE,
    path: '/settings/products/wizard/upload',
    component: () => import('@/features/products/pages/ProductsWizardUploadCSVPage.vue'),
    meta: {
      pageTitle: () => i18n.t('settings.products.upload_csv_page_title'),
      permissions: ['manage_products'],
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PRODUCTS_WIZARD_INTRO_ROUTE },
        }
      },
      parent: 'settings-products',
    },
  },
  {
    name: SETTINGS_PRODUCTS_UPLOAD_ROUTE,
    path: '/settings/products/upload',
    component: () => import('@/features/products/pages/ProductsUploadCSVPage.vue'),
    meta: {
      pageTitle: () => i18n.t('settings.products.upload_csv_page_title'),
      permissions: ['manage_products'],
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PRODUCTS_ROUTE },
        }
      },
      parent: 'settings-products',
    },
  },
] satisfies RouteRecordRaw[]
