import i18n from '@/application/i18n/i18n'

import incomingOrdersRoute from '@/features/incoming-orders/routes'
import labelDetailsRoute from '@/features/label-details/routes'
import shipmentTabsRoutes from '@/features/shipment-tabs/routes'
import shipmentFormRoutes from '@/features/shipment-forms/routes'
import supportRoutes from '@/features/support/routes'
import settingsRoutes from '@/application/routes/settings.routes'
import returnsRoutes from '@/features/returns/routes'
import { trackingPagesPreviewRoutes } from '@/features/tracking-pages/routes'
import pickupsRoutes from '@/features/pickups/routes'
import packgoRoutes from '@/features/packgo/routes'
import insurance from '@/features/insurance/routes'
import onboardingWizardRoutes from '@/features/onboarding-wizard/routes'
import opportunityCenterRoutes from '@/features/opportunity-center/routes'
import notificationsRoutes from '@/features/notifications/routes'
import fallbackRoute from '@/application/routes/fallback-route'
import csvUploadRoutes from '@/features/csv-upload/routes'
import checkoutRoutes from '@/features/dynamic-checkout/routes'
import analyticsRoutes from '@/features/analytics/routes'
import whatsappConversationsRoutes from '@/features/whatsapp-conversations/routes'
import productsRoutes from '@/features/products/routes'

export const TERMS_AND_CONDITIONS_ROUTE = 'terms-and-conditions'

/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
export default [
  ...analyticsRoutes,
  checkoutRoutes,
  ...notificationsRoutes,
  ...packgoRoutes,
  ...insurance,
  ...pickupsRoutes,
  ...returnsRoutes,
  incomingOrdersRoute,
  ...shipmentTabsRoutes,
  ...shipmentFormRoutes,
  labelDetailsRoute,
  ...settingsRoutes,
  ...supportRoutes,
  ...csvUploadRoutes,
  trackingPagesPreviewRoutes,
  onboardingWizardRoutes,
  ...whatsappConversationsRoutes,
  opportunityCenterRoutes,
  ...productsRoutes,

  {
    name: TERMS_AND_CONDITIONS_ROUTE,
    path: '/terms-and-conditions',
    component: () => import('@/application/pages/AppTermsAndConditions.vue'),
    meta: {
      pageTitle: () => i18n.t('Terms and Conditions'),
      permissions: ['accept_terms'],
    },
  },
  fallbackRoute,
]
