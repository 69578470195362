export const HOURS_TO_SHOW_RESOLVED_STATUS_FOR = 2

export const MAX_NUMBER_OF_INCIDENTS_TO_SHOW = 3

export const POLL_DELAY_IN_MINUTES = 5

/**
 * Maps Statuspage component names for carriers to their associated carrier codes.
 *
 * This manual mapping is necessary in order to avoid storing information like the carrier code
 * along with a component’s name on statuspage.io.
 */
export const CARRIER_CODE_MAP: Record<string, string[] | null> = {
  'Amazon': ['amazon'],
  'Bol': ['bol'],
  'Bpost': ['bpost'],
  'Bring': ['bring'],
  'BRT': ['brt'],
  'Chronopost': ['chronopost'],
  'Cirro': ['cirro'],
  'Colis Privé': ['colisprive'],
  'Colissimo': ['colissimo'],
  'Colissimo EC2C': ['colissimo_ec2c'],
  'Correos Express': ['correos_express'],
  'Correos': ['correos'],
  'CTT Express': ['ctt_express'],
  'Delivengo': ['delivengo'],
  'Deutsche Post': ['dp'],
  'DHL (Germany)': ['dhl_de'],
  'DHL eCommerce': ['dhl_ecommerce'],
  'DHL Express': ['dhl_express'],
  'DHL Parcel (Benelux)': ['dhl'],
  'DHL Parcel UK': ['dhl_gb'],
  'DHL Parcel UK V2': ['dhl_parcel_gb'],
  'DHL Parcel Iberia': ['dhl_parcel_iberia'],
  'DHL 2-man-handling': ['dhl_2_man_handling'],
  'DPD AT': ['dpd_at'],
  'DPD FR': ['dpd_fr'],
  'DPD Local': ['dpd_local'],
  'DPD UK': ['dpd_gb'],
  'DPD': ['dpd'],
  'Fairsenden': ['fairsenden'],
  'FedEx': ['fedex'],
  'FedEx Crossborder': ['fedexcrossborder'],
  'Fietskoeriers': ['fietskoeriers'],
  'GLS ES': ['gls_es'],
  'GLS EU': ['gls_eu'],
  'GLS Italy': ['gls_it'],
  'GLS NL': ['gls_nl'],
  'GLS': ['gls_de'],
  'Glovo': ['glovo'],
  'Hermes DE': ['hermes_de'],
  'Hermes UK': ['hermes_gb'],
  'Hived': ['hived'],
  'Hurby': ['hurby'],
  'InPost GB': ['inpost_gb'],
  'InPost IT': ['inpost_it'],
  'InPost PL': ['inpost_pl'],
  'Lettre Suivie': ['lettresuivie'],
  'MHI': ['mhi'],
  'Mondial Relay': ['mondial_relay'],
  'MRW': ['mrw'],
  'Nacex': ['nacex'],
  'Paack': ['paack'],
  'Packs': ['packs'],
  'Parcelforce': ['parcelforce'],
  'Österreichische Post': ['postat'],
  'Poste Italiane': ['poste_italiane'],
  'Poste Italiane Delivery': ['poste_it_delivery'],
  'PostNL': ['postnl', 'postnl_fulfilment'],
  'Quicargo': ['quicargo'],
  'Red je Pakketje': ['rjp'],
  'Red je Pakketje V2': ['rjpv2'],
  'Royal Mail': ['royal_mail'],
  'Sandd': ['van_straaten'],
  'Sendcloud': null, // Not an actual carrier
  'SEUR': ['seur'],
  'Storeshippers': ['storeshippers'],
  'TIPSA': ['tipsa'],
  'TNT Italy': ['tnt_it'],
  'Transmission': ['transmission'],
  'UPS': ['ups'],
  'Van Straaten Post': null, // Not in the carriers endpoint
  'Viatim': ['viatim'],
  'Yodel': ['yodel'],
  'Europaket': ['europaket'],
  'Geodis': ['geodis'],
  'Spring': ['spring'],
  'Evri International': ['evri_international'],
  'Asendia': ['asendia'],
  'Transforce': ['transforce'],
}
