import store from '@/common/stores/store'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

/**
 * Ensure that a user has a specific beta flag enabled before allowing them to access a route.
 * @param failRoute The route name to redirect to if the user does not have the beta flag
 */
export const betaGuard = (betaFlag: string, failRoute: string) => (
  to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext,
) => {
  if (!store.getters.hasBetaFeature(betaFlag)) {
    return next({ name: failRoute, query: to.query })
  }

  next()
}
