import {
  RETURNS_SET_STATIC_DATA,
  RETURNS_SET_INCOMING_PARCEL,
  RETURNS_SET_OUTGOING_PARCEL,
  RETURNS_SET_ALLOWED_METHODS,
  RETURNS_SET_RETURN_SHIPPING_METHOD_ERROR,
} from '@/features/returns/stores/mutation.types.js'

import {
  RETURNS_FETCH_STATIC_DATA,
  RETURNS_FETCH_DETAILS,
  RETURNS_FETCH_ALLOWED_METHODS,
} from '@/features/returns/stores/action.types.js'

import ReturnApi from '@/features/returns/api/return.api'
import ParcelService from '@/features/shipment-tabs/services/parcel.service'
import ParcelStatusApi from '@/features/parcel-monitor/api/parcel-status.api'

/**
 *
 * @type {import('@/types/returns').ReturnsState}
 */
const initialState = {
  allReasons: [],
  allDeliveryOptions: [],
  allRefundOptions: [],
  allPaymentProviders: [],
  incomingParcel: null,
  incomingParcelStatus: null,
  outgoingParcel: null,
  availableShippingMethods: new Set(),
  returnShippingMethodErrors: new Map(),
  images: [],
}

/**
 * @type {import('@/types/returns').ReturnsGetters}
 */
const getters = {
  isReturnsEnabled: (_, getters) => {
    const { returns } = getters.definitions
    return returns && returns.enabled
  },
  returnReasonById(state) {
    return id => state.allReasons.find(item => item.id === id)
  },
  returnReasons: state => state.allReasons || [],
  returnCarriers: (_, getters) => {
    return getters.carriers.filter(carrier => carrier.supports_returns)
  },
  returnDeliveryOptions: state => state.allDeliveryOptions,
  returnRefundOptions: state => state.allRefundOptions,
  returnAvailableShippingMethods: state => state.availableShippingMethods,
  returnIsAvailableShippingMethod: state => method => state.availableShippingMethods.has(method) || method == null,
  returnShippingMethodError: state => orderId => state.returnShippingMethodErrors.get(orderId) || null,
  returnShippingMethodHasError: state => orderId => state.returnShippingMethodErrors.has(orderId),
}

const actions = {
  async [RETURNS_FETCH_STATIC_DATA]({ commit }) {
    const { data } = await ReturnApi.fetchStaticData()
    commit(RETURNS_SET_STATIC_DATA, data)
  },

  async [RETURNS_FETCH_DETAILS]({ commit }, returnShipment) {
    if (returnShipment.incoming_parcel) {
      const incomingParcel = await ParcelService.find(returnShipment.incoming_parcel)
      const incomingParcelStatus = await ParcelStatusApi.find(incomingParcel.global_status_id)
      const images = returnShipment.images || []
      commit(RETURNS_SET_INCOMING_PARCEL, { incomingParcel, incomingParcelStatus, images })
    }
  },

  async [RETURNS_FETCH_ALLOWED_METHODS]({ state, commit }) {
    if (state.availableShippingMethods.size === 0) {
      const { data } = await ReturnApi.getReturnAvailableMethods()
      commit(RETURNS_SET_ALLOWED_METHODS, data?.shipping_methods)
    }
  },
}

const mutations = {
  [RETURNS_SET_INCOMING_PARCEL](state, { incomingParcel, incomingParcelStatus, images }) {
    state.incomingParcel = incomingParcel
    state.incomingParcelStatus = incomingParcelStatus
    state.images = images
  },

  [RETURNS_SET_OUTGOING_PARCEL](state, outgoingParcel) {
    state.outgoingParcel = outgoingParcel
  },

  [RETURNS_SET_STATIC_DATA](state, payload) {
    state.allDeliveryOptions = payload.delivery_options || []
    state.allRefundOptions = payload.refund_options || []
    state.allReasons = payload.return_reasons || []
    state.allPaymentProviders = payload.payment_provider || []
  },

  /**
   * @param {import('@/types/returns').ReturnsState} state
   * @param {number[]} availableShippingMethods
   */
  [RETURNS_SET_ALLOWED_METHODS](state, availableShippingMethods) {
    state.availableShippingMethods = new Set(availableShippingMethods)
  },

  /**
   * @param {import('@/types/returns').ReturnsState} state
   * @param {object} payload
   * @param {number} payload.orderId
   * @param {string} payload.error
   */
  [RETURNS_SET_RETURN_SHIPPING_METHOD_ERROR](state, { orderId, error }) {
    if (error) {
      state.returnShippingMethodErrors.set(orderId, error)
    } else {
      state.returnShippingMethodErrors.delete(orderId)
    }
  },
}

export default {
  state: () => initialState,
  getters,
  actions,
  mutations,
}
