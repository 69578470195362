import Segment from '@/common/utils/tracking/segment'
import type { NavigationItemTrackingData, NavigationCategoryTrackingData } from '@/application/types/navigation-tracking.types'

export default {

  trackNavigationMenuItemClick(params: NavigationItemTrackingData) {
    Segment.track('App - Selected Navigation Item', {
      menu_selected: params.name,
      parent_menu: params?.parentSection,
      navigation_expanded: params.navigationIsTabletOrMobile ? false : params.navigationMenuIsExpanded,
      navigation_tablet_or_mobile: params.navigationIsTabletOrMobile,
    })
  },

  trackNavigationExpandOrCollapsed(isExpanded: boolean) {
    Segment.track('App - Navigation - Expand/Collapse', {
      navigation_expanded: isExpanded,
      navigation_collapsed: !isExpanded,
    })
  },

  trackNavigationCategoryClicked(params: NavigationCategoryTrackingData) {
    Segment.track('App - Navigation - Selected Category ', {
      navigation_category_name: params.name,
      navigation_expanded: params.isExpanded,
    })
  },

  openFeedbackNavigation() {
    Segment.track('App - Navigation feedback')
  },
}
