<template>
  <div class="app-error-panel">
    <div class="ui-panel app-error-panel__contents">
      <p>
        {{ t('Something went wrong. Please, reload the application.') }}
      </p>

      <button
        class="ui-button ui-button--primary"
        type="button"
        @click="reloadPage"
      >
        {{ t('Reload') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

function reloadPage() {
  window.location.reload()
}
</script>

<style scoped lang="scss">
.app-error-panel {
  display: grid;
  font-size: 1.125rem;
  height: 100vh;
  padding-inline: $sc-spacing-16;
  place-content: center;
  text-align: center;
}

.app-error-panel__contents {
  display: grid;
  gap: $sc-spacing-24;
  justify-items: center;
}
</style>
