import store from '@/common/stores/store'
import { useOpportunityStore } from '@/features/opportunity-center/stores/'
import { ANALYTICS_SHIPPING_ROUTE } from '@/features/analytics/routes'
import { RETURNS_ROUTE } from '@/features/returns/routes'
import { PICKUPS_ROUTE } from '@/features/pickups/routes'
import { SETTINGS_BRANDS_ROUTE } from '@/features/brands/routes'
import { SETTINGS_FINANCIAL_ROUTE } from '@/features/financial/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { SHIPPING_CREATED_LABELS_ROUTE } from '@/features/shipment-tabs/routes'
import { OPPORTUNITY_CENTER_ROUTE } from '@/features/opportunity-center/routes'
import type { UserPermissions } from '@/types/models'
import { OPPORTUNITY_CENTER } from '@/features/opportunity-center/beta-features'

export const FALLBACK_ROUTE = 'fallback-route'

export const isOpportunityAvailable = async () => {
  if (store.getters.hasBetaFeature(OPPORTUNITY_CENTER)) {
    const opportunityStore = useOpportunityStore()
    await opportunityStore.fetchOpportunitiesData()
    return opportunityStore.isOpportunityAvailable
  }
  return false
}
/**
 * When a user does not have access to a certain route we need to fallback to a
 * different one. We need to pick a fallback route the user does have access to,
 * hence, we need to check permissions.
 *
 */
export async function getFallbackRedirect(permissions: UserPermissions): Promise<{ name: string }> {
  if (await isOpportunityAvailable()) {
    return { name: OPPORTUNITY_CENTER_ROUTE }
  }
  if (permissions.process_orders) {
    return { name: SHIPPING_INCOMING_ORDERS_ROUTE }
  }
  if (permissions.manage_shipping) {
    return { name: SHIPPING_CREATED_LABELS_ROUTE }
  }
  if (permissions.manage_invoices) {
    return { name: SETTINGS_FINANCIAL_ROUTE }
  }
  if (permissions.manage_brands) {
    return { name: SETTINGS_BRANDS_ROUTE }
  }
  if (permissions.view_analytics) {
    return { name: ANALYTICS_SHIPPING_ROUTE }
  }
  if (permissions.manage_pickups) {
    return { name: PICKUPS_ROUTE }
  }
  if (permissions.manage_returns) {
    return { name: RETURNS_ROUTE }
  }
  // This case should normally not happen -- given the role/permission definitions,
  // one of the above should really match.
  return { name: SHIPPING_INCOMING_ORDERS_ROUTE }
}

export default {
  name: FALLBACK_ROUTE,
  path: '/:pathMatch(.*)*',
  beforeEnter: async () => {
    const fallback = await getFallbackRedirect(store.getters.user?.permissions ?? {})
    return { name: fallback.name }
  },
  meta: {
    pageTitle: 'Redirecting ...',
    permissions: [],
  },
}
