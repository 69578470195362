import axios from '@/common/utils/axios'
import type { ReturnPortalSettings } from '@/features/return-portal/types'

type ConnectPaymentProviderPayload = {
  state: string
  code: string
  type: 'mollie'
}

export default {
  async findAll(): Promise<ReturnPortalSettings> {
    const { data } = await axios.get('/xhr/return-portal')
    return data
  },

  async create(rawPayload: ReturnPortalSettings): Promise<ReturnPortalSettings> {
    // the `return_address` key is mapped to `chosen_return_address` in the PUT call
    const payload = { ...rawPayload }
    payload.chosen_return_address = payload.return_address
    delete payload.return_address
    const { data } = await axios.post(`/xhr/return-portal`, payload)
    return data
  },

  update(id: number, rawPayload: ReturnPortalSettings): Promise<ReturnPortalSettings> {
    // the `return_address` key is mapped to `chosen_return_address` in the PUT call
    const payload = { ...rawPayload }
    payload.chosen_return_address = payload.return_address
    delete payload.return_address
    return axios.patch(`/xhr/return-portal/${id}`, payload)
  },

  connectPaymentProvider(id: number, payload: ConnectPaymentProviderPayload) {
    return axios.post(`/xhr/return-portal/${id}/payments`, payload)
  },

  deletePaymentProvider(id: number) {
    return axios.delete(`/xhr/return-portal/${id}/payments`)
  },
}
