import Cookies from 'js-cookie'

/**
 * Turns a relative backend path into an absolute URL, gracefully taken into
 * account situations where the input path is already an absolute URL (e.g. for
 * development purposes, where we point to a different locally running service).
 *
 * For now, in case the 'pod-url' cookie is not present, it returns the path
 * unchanged. Not returning an absolute URL is fine, as the panel and frontend
 * are still on the same domain. Though, in the future, once everything is truly
 * split and we have no clue what the POD URL is, we should simply assume we're
 * not logged in and redirect to https://account.sendcloud.com here.
 * @throws {Error} when pod-url has not been set and `failSilently` is set to `false`
 */
export function getAbsoluteBackendURL(path = '', failSilently = true): string {
  const POD_URL: string = Cookies.get('pod-url')

  if (!POD_URL) {
    if (failSilently) {
      return path
    }
    throw new Error('pod-url cookie not set')
  }

  if (path.includes('://')) {
    return path
  }

  return new URL(path, POD_URL).href
}

/**
 * Redirects to the accounts service, preserving the current URL we're currently
 * at by passing it as '?next=URL'.
 */
export function redirectToAccountsService() {
  const targetUrl = window.location.href
  window.location.href = `${import.meta.env.VUE_APP_ACCOUNTS_BASE_URL}/login/?next=${encodeURIComponent(targetUrl)}`
}

/**
 * Checks if the `pod-url` cookie is present (which is needed for communication with the backend)
 */
export function hasAbsoluteBackendURL(): boolean {
  try {
    getAbsoluteBackendURL('', false) // will throw an error if pod-url cookie is not set
    return true
  } catch {
    return false
  }
}

/**
 * Helper method to return the backend logout URL.
 */
export function getLogoutURL(): string {
  return getAbsoluteBackendURL('/accounts/logout/')
}
