import axios from '@/common/utils/axios'
import type { Carrier, CarrierContractFormData } from '@/features/carriers/types/carrier.types'

export default {
  async findAll(): Promise<Carrier[]> {
    const response = await axios.get('/xhr/carriers')
    return response.data
  },

  toggle(
    enable: boolean,
    id: number,
    fromCountry: string): Promise<{ data: { id: number, state: string, activation_type: string } }> {
    const payload = {
      enable,
      from_country: fromCountry,
    }

    return axios.post(`/xhr/carriers/${id}/toggle`, payload)
  },

  pendingContract(id: number): Promise<{ data: { id: number, state: string, activation_type: string } }> {
    const payload = { activate_pending_contract: true }
    return axios.post(`/xhr/carriers/${id}/pending_contract`, payload)
  },

  async getContractFormDataForCarrier(id: number): Promise<CarrierContractFormData> {
    const response = await axios.get(`/xhr/carriers/${id}/contract_form_data`)
    return response.data
  },
}
