<template>
  <transition
    :duration="1500"
    name="sc-fade"
  >
    <div
      v-if="currentRequestCount > 0"
      class="loading-bar-background"
    >
      <div class="loading-bar" />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useAxiosRequestCounter } from '@/common/services/use-axios-request-counter'

const currentRequestCount = useAxiosRequestCounter()
</script>

<style lang="scss" scoped>
.sc-fade-enter-active,
.sc-fade-leave-active {
  --fade-transition-time: 0.5s;
}

@keyframes loading-bar {
  from {
    transform: translateX(-50vw);
  }

  to {
    transform: translateX(100vw);
  }
}

.loading-bar-background {
  height: 5px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.loading-bar {
  animation: loading-bar 1.5s infinite;
  animation-timing-function: ease-in-out;
  backface-visibility: hidden;
  background-color: $sc-dark-blue;
  height: 100%;
  position: absolute;
  transform: translateX(-50vw);
  transform-style: preserve-3d;
  width: 50%;
}
</style>
