import axios from '@/common/utils/axios'

export default {
  async create(newAddress) {
    const { data } = await axios.post('/xhr/returnaddress', newAddress)
    return data
  },

  async update(id, updatedData) {
    const { data } = await axios.put(`/xhr/returnaddress/${id}`, updatedData)
    return data
  },

  delete(id) {
    return axios.delete(`/xhr/returnaddress/${id}`)
  },

  async findAll() {
    const { data } = await axios.get('/xhr/returnaddress')
    return data
  },

  async find(id) {
    const { data } = await axios.get(`/xhr/returnaddress/${id}`)
    return data
  },

  async setActive(id) {
    const { data } = await axios.post(`/xhr/returnaddress/${id}/activate`, {})
    return data
  },
}
