import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

import store from '@/common/stores/store'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'

function isBoxesFeatureEnabled() {
  return store.getters.user.features?.boxes === true
}

function boxesRouteGuard(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void {
  if (!isBoxesFeatureEnabled()) {
    next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  } else {
    next()
  }
}

export default boxesRouteGuard
