import store from '@/common/stores/store'
import { TERMS_AND_CONDITIONS_ROUTE } from '@/application/routes'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

function redirectToTermsAndConditions(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext) {
  if (store.getters.userShouldSeeTermsAndConditions && to.name !== TERMS_AND_CONDITIONS_ROUTE) {
    next(TERMS_AND_CONDITIONS_ROUTE)
  } else {
    next()
  }
}

export default redirectToTermsAndConditions
