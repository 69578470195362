import type { RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

export const EDIT_CARRIER_RATES_ROUTE = 'carrier-rates.edit'

export default {
  name: EDIT_CARRIER_RATES_ROUTE,
  path: '/contracts/:contractId/rates',
  component: () => import('@/features/carrier-rates/pages/CarrierRatesPage.vue'),
  props: ({ params }) => ({ contractId: +params.contractId }),
  meta: {
    pageTitle: () => i18n.t('carrier_rates.page_title'),
    permissions: ['manage_shipping'],
  },
} satisfies RouteRecordRaw
