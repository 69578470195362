import store from '@/common/stores/store'
import EventBus from '@/common/utils/event-bus'
import { CORE_DISPLAY_PAYMENT_WALL } from '@/common/stores/core/action.types'
import AdyenPaymentService from '@/features/payment-wall/services/adyen-payment.service'
import type { User } from '@/types/models'

export const TYPES = {
  NONE: undefined,
  INITIAL: 'initial',
  OVERDUE: 'overdue',
} as const

type WallType = typeof TYPES[keyof typeof TYPES]

type PaymentWallResult = {
  wallType: WallType
  createLabels: boolean
  announceToCarrier: boolean
  parcels?: undefined
  labels?: undefined
}

/**
 * Gets the payment wall type based on the user's payment status or selected shipping methods for announcement.
 * The current user object.
 * Indicates if only unstamped letters are being announced.
 * The type of payment wall to show.
 */
function getWallType(user: User, isOnlyAnnouncingWithUnstampedLetters: boolean): WallType {
  if (!user.allowed_to_announce && user.is_account_on_hold) {
    return TYPES.NONE
  }

  if (user.allowed_to_announce || (isOnlyAnnouncingWithUnstampedLetters && !user.has_overdue_invoices) ||
    user.has_chargebee_payment_source) {
    return TYPES.NONE
  } else if (user.has_overdue_invoices) {
    return TYPES.OVERDUE
  } else {
    return TYPES.INITIAL
  }
}

/**
 * Check if we should display the payment wall based on the user's financial health and/or types of shipments.
 *
 *  Whether the shipping method for all the requested shipments is unstamped letter.
 *  Resolves with an object containing wall type, createLabels, and announceToCarrier properties.
 *                                         Rejects with the dialog type if dismissed.
 */
function show(isOnlyAnnouncingWithUnstampedLetters: boolean = false): Promise<PaymentWallResult> {
  const user = store.getters.user as User
  return new Promise((resolve, reject) => {
    const wallType = getWallType(user, isOnlyAnnouncingWithUnstampedLetters)

    if (wallType === TYPES.NONE) {
      return resolve({
        wallType: TYPES.NONE,
        createLabels: true,
        announceToCarrier: true,
      })
    }

    // Event listeners for payment wall confirmation or dismissal
    EventBus.$once('payment-wall-confirmed', (confirmedWallType: WallType) => {
      resolve({
        wallType: confirmedWallType,
        createLabels: confirmedWallType !== TYPES.OVERDUE,
        announceToCarrier: false,
      })
    })

    EventBus.$once('payment-wall-dismissed', (dismissedWallType: WallType) => {
      reject(new Error(dismissedWallType))
    })

    // Dispatch the action to show the payment wall
    store.dispatch(CORE_DISPLAY_PAYMENT_WALL, wallType)
  })
}

/**
 * Shows the Adyen payment modal to the user.
 * @returns {Promise<void>}
 */
async function doPayment(): Promise<void> {
  await AdyenPaymentService.show(AdyenPaymentService.PAYMENT_INVOICE_REF_TYPE.INITIAL)
}

export default {
  show,
  doPayment,
  TYPES,
}
