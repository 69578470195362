/** @typedef {typeof state} ModuleState */
import {
  SUPPORT_ADD_TICKET_COMMENT,
  SUPPORT_DESTROY_CURRENT_TICKET,
  SUPPORT_FETCH_TICKET_DETAILS,
  SUPPORT_FETCH_TICKETS_SHIPMENTS,
  SUPPORT_GO_TO_DRAFT_TICKET,
} from './action.types.js'
import {
  SUPPORT_ADD_REPLY_ATTACHMENTS,
  SUPPORT_SET_CURRENT_TICKET,
  SUPPORT_SET_TICKETS_SHIPMENTS,
  SUPPORT_SET_REPLY_ATTACHMENTS,
  SUPPORT_REMOVE_REPLY_ATTACHMENTS,
  SUPPORT_SET_DRAFT_TICKET,
} from './mutation.types.js'

import SupportService from '@/features/support/services/support.service'

const state = {
  currentTicket: null,
  replyAttachments: [],
  selectedDraftTicket: null,
  ticketsShipments: {
    items: [],
    page: 1,
    pages: 1,
  },
}

const getters = {
  waitForTicketCreated: (/** @type {ModuleState} */ state) => state.waitForTicketCreated,
  selectedDraftTicket: (/** @type {ModuleState} */ state) => state.selectedDraftTicket,
}

const actions = {
  async [SUPPORT_ADD_TICKET_COMMENT]({ dispatch }, payload) {
    const { id, comment, attachments } = payload

    const data = await SupportService.addComment(id, comment, attachments)

    const updatedId = parseInt(data.ticket_id)
    return dispatch(SUPPORT_FETCH_TICKET_DETAILS, { id: updatedId })
  },
  [SUPPORT_DESTROY_CURRENT_TICKET]({ commit }) {
    commit(SUPPORT_SET_CURRENT_TICKET, null)
  },
  async [SUPPORT_FETCH_TICKET_DETAILS]({ commit }, payload) {
    const { id } = payload
    try {
      commit(SUPPORT_SET_CURRENT_TICKET, await SupportService.getTicket(id))
    } catch (err) {
      commit(SUPPORT_SET_CURRENT_TICKET, null)

      if (err.status === 404) {
        throw new Error(this.$t('Ticket not found.'))
      }

      throw err
    }
  },
  [SUPPORT_GO_TO_DRAFT_TICKET]({ commit }, selectedDraftTicket) {
    commit(SUPPORT_SET_DRAFT_TICKET, selectedDraftTicket)
  },
  async [SUPPORT_FETCH_TICKETS_SHIPMENTS]({ commit }, urlParams) {
    const { pageNum = 1, extraParams, queryType } = urlParams
    const params = {
      page: pageNum,
      ...extraParams,
    }
    const data = await SupportService.getTicketsByType(queryType, params)
    commit(SUPPORT_SET_TICKETS_SHIPMENTS, {
      items: data.items,
      page: data.page,
      pages: data.pages,
    })
  },
}

const mutations = {
  [SUPPORT_SET_CURRENT_TICKET](state, ticket) {
    state.currentTicket = ticket
  },
  [SUPPORT_SET_TICKETS_SHIPMENTS](state, { items, page, pages }) {
    state.ticketsShipments.items = items
    state.ticketsShipments.page = page
    state.ticketsShipments.pages = pages
  },
  [SUPPORT_SET_REPLY_ATTACHMENTS](state, selectedFiles) {
    state.replyAttachments = []
    for (let i = 0; i < selectedFiles.length; i++) {
      state.replyAttachments.push(selectedFiles[i])
    }
  },
  [SUPPORT_ADD_REPLY_ATTACHMENTS](state, selectedFiles) {
    [...selectedFiles].forEach(file => state.replyAttachments.push(file))
  },
  [SUPPORT_REMOVE_REPLY_ATTACHMENTS](state, key) {
    state.replyAttachments.splice(key, 1)
  },
  [SUPPORT_SET_DRAFT_TICKET](state, selectedDraftTicket) {
    state.selectedDraftTicket = selectedDraftTicket
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
