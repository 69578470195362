/**
 * Wrapper around SpeedCurve functions which is inspired on a React-based
 * implementation that covers quick navigation between routes
 *
 * @see https://medium.com/@samijaber/how-to-setup-speedcurve-real-user-monitoring-using-redux-observable-8e647079646
 */

let isPendingLuxTracking = false
let isLuxTrackingConfigured = false

export const IS_SPEEDCURVE_ENABLED = import.meta.env.VUE_APP_SPEED_CURVE_ENABLED === 'yes'

/**
 * Sets the recommended values for proper SPA LUX tracking
 *
 * @param {boolean} isUserAnalyticsEnabled
 * @param {boolean} isSpeedcurveEnabled
 *
 * @see https://support.speedcurve.com/docs/single-page-applications#recommended-implementation-for-a-spa
 */
export function configureSpeedCurveLux(isUserAnalyticsEnabled, isSpeedcurveEnabled) {
  if (!isSpeedcurveEnabled || !isUserAnalyticsEnabled || isLuxTrackingConfigured) {
    return
  }

  if (typeof window.LUX?.auto === 'boolean') {
    window.LUX.auto = false
  }

  if (typeof window.LUX?.sendBeaconOnPageHidden === 'boolean') {
    window.LUX.sendBeaconOnPageHidden = false
  }

  isLuxTrackingConfigured = true
}

/**
 * First try to send the remaining events to ensure that every `LUX.init()` is followed up with a `LUX.send()`
 * before initializing again after page transition
 */
function initializeSpeedCurveLuxEvent() {
  if (typeof window.LUX?.init === 'function') {
    sendSpeedCurveLuxEventIfInitialized()
    window.LUX.init()
    isPendingLuxTracking = true
  }
}

/**
 * Only send the LUX event when the page first has been initialized
 */
function sendSpeedCurveLuxEventIfInitialized() {
  if (isPendingLuxTracking && typeof window.LUX?.send === 'function') {
    window.LUX.send()
    isPendingLuxTracking = false
  }
}

/**
 * Wrapper around the `LUX.send()` utility and uses a simple locking to ensure `LUX.send()` is
 * always called _after_ `LUX.init()` for every page transition.
 *
 * @param {boolean} shouldSendEventsPreviousPage
 * @param {boolean} shouldInitEventsNextPage
 */
export function sendSpeedCurveLuxEvent(shouldSendEventsPreviousPage, shouldInitEventsNextPage) {
  if (!isLuxTrackingConfigured) {
    return
  }

  if (shouldSendEventsPreviousPage) {
    sendSpeedCurveLuxEventIfInitialized()
  }

  // instantly initialize again so all events triggered on the new page will be catched
  if (shouldInitEventsNextPage) {
    initializeSpeedCurveLuxEvent()
  }
}

/**
 * Record when a single page application (SPA) page has finished loading.
 * This will be used as the "Load Time" metric in SpeedCurve dashboards.
 *
 * @see https://support.speedcurve.com/docs/rum-js-api#luxmarkloadtime
 */
export function sendSpeedCurveMarkLoadTime() {
  if (!isLuxTrackingConfigured) {
    return
  }

  if (isPendingLuxTracking && typeof window.LUX?.markLoadTime === 'function') {
    window.LUX.markLoadTime()
  }
}
