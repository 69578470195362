import Cookies from 'js-cookie'

const storageKey = 'sessionId'

/**
 * Generates a unique user's session ID by hashing the provided CSRF token using the SHA-256 algorithm.
 */
export async function generateSessionId() {
  const csrfToken = Cookies.get('csrftoken')
  const encoder = new TextEncoder()
  const data = encoder.encode(csrfToken)

  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const newId = hashArray.map(byte => byte.toString(16)).join('')
  sessionStorage.setItem(storageKey, newId)
  return newId
}

export function sessionId() {
  return sessionStorage.getItem(storageKey) || ''
}
