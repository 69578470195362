import { RouterView, type RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'

import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'

export const PREVIEW_ROUTE = 'preview'
export const PREVIEW_TRACKING_PAGE_ROUTE = 'preview.tracking-page'
export const SETTINGS_NOTIFY_TRACKING_PAGE_ROUTE = 'notify.tracking-page'

export const trackingPagesPreviewRoutes: RouteRecordRaw = {
  name: PREVIEW_ROUTE,
  path: '/preview',
  redirect: { name: SHIPPING_INCOMING_ORDERS_ROUTE },
  component: RouterView,
  children: [
    {
      name: PREVIEW_TRACKING_PAGE_ROUTE,
      path: '/preview/track-and-trace',
      component: () => import('@/features/tracking-pages/pages/TrackingPagePreviewPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Tracking page preview'),
        permissions: ['configure_tracking'],
      },
    },
  ],
}

export default {
  name: SETTINGS_NOTIFY_TRACKING_PAGE_ROUTE,
  path: '/tracking/page',
  component: () => import('@/features/tracking-pages/pages/TrackingPagePage.vue'),
  meta: {
    pageTitle: () => i18n.t('Tracking page'),
    permissions: ['configure_tracking'],
    parent: 'tracking-page',
  },
  alias: ['/settings/track-and-trace/page'],
} satisfies RouteRecordRaw
