import { getAbsoluteBackendURL } from '@/common/utils/backend'
import i18n from '@/application/i18n/i18n'

export function getHelpCenterUrl(code: string, category: string, senderCountry?: string): string {
  if (senderCountry) {
    code = `${code}-${senderCountry.toLowerCase()}`
  }
  return getAbsoluteBackendURL(`/support/article/${category}-${code}?language=${encodeURIComponent(i18n.locale)}`)
}

/**
 * Get the URL for the home page of our help center, correctly prefixed with the correct domain for the user's POD
 * (e.g. `https://eu-central-1-0.app.sendcloud.com/support/article/home`)
 */
export function getHelpCenterUrlHome(): string {
  return getAbsoluteBackendURL(`/support/article/home?language=${encodeURIComponent(i18n.locale)}`)
}
