import mixpanel from 'mixpanel-browser'
import type { MiddlewareParams } from '@segment/analytics-next/src/plugins/middleware'

export default function sessionRetryMiddleware({ payload, next }: MiddlewareParams) {
  if (payload.obj.type === 'track' || payload.obj.type === 'page') {
    const segmentDeviceId = payload.obj.anonymousId
    // original id
    mixpanel.register({ $device_id: segmentDeviceId, distinct_id: segmentDeviceId })
    // simplified id
    mixpanel.register({ $device_id: segmentDeviceId, distinct_id: '$device:' + segmentDeviceId })
    const sessionReplayProperties = mixpanel.get_session_recording_properties()
    payload.obj.properties = {
      ...payload.obj.properties,
      ...sessionReplayProperties,
    }
  }
  if (payload.obj.type === 'identify') {
    const userId = payload.obj.userId
    mixpanel.identify(userId || undefined)
  }
  next(payload)
}
