<template>
  <UiAlert
    v-if="hasReachedMaxVolume"
    icon
    class="ui-margin-16--bottom"
    mode="warning"
    data-test="alert-max-volume-reached"
  >
    {{ message }}
  </UiAlert>
</template>

<script setup lang="ts">
import { UiAlert } from '@sendcloud/ui-library'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import SubscriptionsService from '@/features/subscription/services/subscriptions.service'
import { currency } from '@/common/utils/filters'

const ADDITIONAL_LABEL_FEE = 0.12
const ALERT_DATA_UPDATE_INTERVAL = 1000 * 60 * 60 * 2

const store = useStore()
const { t } = useI18n()

const userIsOnPricingPilot = computed<boolean>(() => store.getters.userIsOnPricingPilot)

const hasReachedMaxVolume = ref(false)

const message = computed<string>(() => t(
  'You have now used 100% of your shipping labels included in your current plan. An additional fee of {fee} will be added per label. Please upgrade your plan or contact our support for more information.',
  { fee: currency(ADDITIONAL_LABEL_FEE) },
))

async function updateLabelCount(): Promise<void> {
  // TODO: remove once pricing pilot is completed
  if (userIsOnPricingPilot.value) {
    hasReachedMaxVolume.value = false
    return
  }

  try {
    const shipmentCountObj = await SubscriptionsService.getShipmentCount()
    hasReachedMaxVolume.value =
      !!shipmentCountObj?.data?.subscription_allowed_volume &&
      shipmentCountObj?.data?.shipment_count >= shipmentCountObj?.data?.subscription_allowed_volume
  } catch {
    // The request should fail silently and the existing state should be kept as it is
  }
}

onMounted(() => {
  // TODO: remove once pricing pilot is completed
  if (userIsOnPricingPilot.value) {
    hasReachedMaxVolume.value = false
    return
  }

  updateLabelCount()
  setInterval(() => {
    updateLabelCount()
  }, ALERT_DATA_UPDATE_INTERVAL)
})
</script>
