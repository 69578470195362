import { wait } from './wait'

/**
 * Calls a given function and keeps calling it after the specified delay has passed.
 *
 * Note: This implementation copies [poll][1] to allow our build process to transpile
 * the package.
 *
 * [1]: https://www.npmjs.com/package/poll
 */
async function poll(
  fn: () => unknown,
  delay: number,
  shouldStopPolling: () => boolean = () => false,
): Promise<void> {
  delay = Math.max(0, delay)

  do {
    await fn()

    if (shouldStopPolling()) {
      break
    }

    await wait(delay)
  } while (!shouldStopPolling())
}

export default poll
