/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
import i18n from '@/application/i18n/i18n'
import { type RouteRecordRaw, type RouteLocationNormalized, RouterView } from 'vue-router'

export const SETTINGS_SAVED_CONTACTS_ROUTE = 'saved-contacts'
export const SETTINGS_SAVED_CONTACTS_LIST_ROUTE = 'saved-contacts.list'
export const SETTINGS_SAVED_CONTACTS_CREATE_ROUTE = 'saved-contacts.create'
export const SETTINGS_SAVED_CONTACTS_EDIT_ROUTE = 'saved-contacts.edit'

export default {
  name: SETTINGS_SAVED_CONTACTS_ROUTE,
  path: 'contacts',
  redirect: { name: SETTINGS_SAVED_CONTACTS_LIST_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_SAVED_CONTACTS_LIST_ROUTE,
      path: 'list',
      component: () => import('@/features/saved-contacts/pages/SavedContactListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Contacts'),
        permissions: ['manage_shipping'],
        parent: 'settings-contacts',
      },
    },
    {
      name: SETTINGS_SAVED_CONTACTS_CREATE_ROUTE,
      path: 'create',
      component: () => import('@/features/saved-contacts/pages/SavedContactFormPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Create contact'),
        permissions: ['manage_shipping'],
        parent: 'settings-contacts',
      },
    },
    {
      name: SETTINGS_SAVED_CONTACTS_EDIT_ROUTE,
      path: 'edit/:contactId',
      component: () => import('@/features/saved-contacts/pages/SavedContactFormPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        contactId: Number.parseInt(route.params.contactId as string),
      }),
      meta: {
        pageTitle: () => i18n.t('Edit contact'),
        permissions: ['manage_shipping'],
        parent: 'settings-contacts',
      },
    },
  ],
} satisfies RouteRecordRaw
