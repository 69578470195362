/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
import i18n from '@/application/i18n/i18n'
import { type RouteRecordRaw, type RouteLocationNormalized, RouterView } from 'vue-router'

export const SETTINGS_CHECKOUT_RULES_ROUTE = 'checkout-rules'
export const SETTINGS_CHECKOUT_RULES_LIST_ROUTE = 'checkout-rules.list'
export const SETTINGS_CHECKOUT_RULES_ADD_ROUTE = 'checkout-rules.add'
export const SETTINGS_CHECKOUT_RULES_EDIT_ROUTE = 'checkout-rules.edit'

export default {
  name: SETTINGS_CHECKOUT_RULES_ROUTE,
  path: 'checkout-rules',
  redirect: { name: SETTINGS_CHECKOUT_RULES_LIST_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_CHECKOUT_RULES_LIST_ROUTE,
      path: '',
      component: () => import('@/features/dynamic-checkout-rules/pages/CheckoutRulesListViewPage.vue'),
      meta: {
        pageTitle: () => i18n.t('settings.checkout_rules.path_title'),
        permissions: ['configure_checkout'],
        parent: 'settings-checkout-rules',
      },
    },
    {
      name: SETTINGS_CHECKOUT_RULES_ADD_ROUTE,
      path: 'add',
      component: () => import('@/features/dynamic-checkout-rules/pages/CheckoutRuleDetailsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('checkout_rules.routes.new_rule_title'),
        permissions: ['configure_checkout'],
        parent: 'settings-checkout-rules',
      },
    },
    {
      name: SETTINGS_CHECKOUT_RULES_EDIT_ROUTE,
      path: ':ruleId',
      component: () => import('@/features/dynamic-checkout-rules/pages/CheckoutRuleDetailsPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        ruleId: Number.parseInt(route.params.ruleId as string),
      }),
      meta: {
        pageTitle: () => i18n.t('checkout_rules.routes.edit_rule_title'),
        permissions: ['configure_checkout'],
        parent: 'settings-checkout-rules',
      },
    },
  ],
} satisfies RouteRecordRaw
