import i18n from '@/application/i18n/i18n'
import type { RouteButton } from '@/common/types/route.types'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import type { RouteRecordRaw } from 'vue-router'

export const SHIPMENT_PROTECTION_ROUTE = 'shipment-protection'

const howToMakeClaimButton: RouteButton = {
  id: 'open-help-center-xcover',
  title: () => i18n.t('settings.shipping.protection.header.button.label'),
  name: 'Help center button',
  href: 'https://support.sendcloud.com/hc/articles/23126505906449',
  newTab: true,
  mode: 'borderless',
  icon: faInfoCircle,
  align: 'right',
  permissions: ['configure_shipping'],
}

const xCoverButton: RouteButton = {
  id: 'open-xcover',
  title: () => i18n.t('settings.shipping.protection.header.xcover_button.label'),
  name: 'Go to XCover',
  href: 'https://www.xcover.com/login',
  newTab: true,
  mode: 'secondary',
  permissions: ['configure_shipping'],
}

export default [
  {
    name: SHIPMENT_PROTECTION_ROUTE,
    path: '/shipping/shipment-protection',
    component: () => import('@/features/insurance/pages/ShippingProtectionPage.vue'),
    meta: {
      buttons: [howToMakeClaimButton, xCoverButton],
      pageTitle: () => i18n.t('settings.shipping.protection.header.title'),
      permissions: ['configure_shipping'],
      parent: SHIPMENT_PROTECTION_ROUTE,
    },
  },

] satisfies RouteRecordRaw[]
