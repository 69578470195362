import { OBJECT_PARSER_TYPE } from '../constants'
import { Context } from '../context'
import { checkIsObject } from '../utils/checks'
import { generateErrorMessage, doNothing, getErrorMsg, toReadableTypeOf } from '../utils/helpers'
import { object } from './magic-object'
import type { CTX } from '../types/context.types'
import type {
  ParsersArray,
  ParserResult,
  Parser,
  BaseObjectParser,
  ParsersObject,
  AnyObjectParser,
} from '../types/parsers.types'

export class UnionParser<T extends ParsersArray> implements Parser<ParserResult<T[number]>> {
  readonly expected: string
  constructor(private readonly parsersArray: T) {
    this.expected = `(${parsersArray.map(({ expected }) => expected).join(' | ')})`
  }

  parse(data: unknown, ctx: CTX): ParserResult<T[number]> {
    const errors: string[] = []
    const { parsersArray } = this
    const l = parsersArray.length

    if (!l) {
      return data as ParserResult<T[number]>
    }

    // TODO: could be creating this inside the loop to preserve original context for each iteration
    // (but YAGNI and more expensive)
    const throwingCtx = new Context({
      originalCtx: ctx,
      shouldStopParsingOnError: true,
      onAssertionError: (params) => {
        throw new Error(generateErrorMessage(params))
      },
    })

    for (let i = 0; i < l; i++) {
      try {
        return parsersArray[i]!.parse(data, throwingCtx) as ParserResult<T[number]>
      } catch (e) {
        errors.push(getErrorMsg(e))
      }
    }

    // If we got here, all parsers have failed (since no return happened in the loop).
    // !!false to fail the assertion and avoid TS unreachable code error
    ctx.assert(
      !!false,
      `to match ${this.expected}`,
      `no match: [${errors.reduce((acc, curr, i) => {
        acc += `\n\tParser ${i + 1}: ${curr},`
        return acc
      }, '')}\n].\nFalling back to Parser 1`,
    )

    const doNothingCtx = new Context({
      originalCtx: ctx,
      onAssertionError: doNothing,
    })

    // fallback to one of the parsers, while ignoring errors
    // (to still perform the expected parsing/coercing, because our error could be not that breaking)
    return parsersArray[0]!.parse(data, doNothingCtx) as ParserResult<T[number]>
  }
}

export class DiscriminatedUnion<
  T extends ReadonlyArray<BaseObjectParser<ParsersObject>>,
  Key extends keyof T[number]['parsersObject'],
> implements AnyObjectParser<ParserResult<T[number]>> {
  readonly type = OBJECT_PARSER_TYPE
  readonly expected: string
  readonly discriminatorArray: Array<BaseObjectParser<Record<string, ParsersObject[Key]>>>

  constructor(private readonly key: Key, private readonly parsersArray: T) {
    // we create expected string and a special parser array, that will only check Key parser
    const result = parsersArray.reduce<{
      expected: string
      discriminatorArray: Array<BaseObjectParser<Record<string, ParsersObject[Key]>>>
    }>(
      (acc, parser) => {
        // build expected string
        acc.expected += !acc.expected ? parser.expected : ` | ${parser.expected}`
        acc.discriminatorArray.push(object({ [key]: parser.parsersObject[key] }))
        return acc
      },
      {
        expected: '',
        discriminatorArray: [],
      },
    )

    this.expected = `(${result.expected})`
    this.discriminatorArray = result.discriminatorArray
  }

  parse(data: unknown, ctx: CTX): ParserResult<T[number]> {
    const isObject = checkIsObject(data)
    ctx.assert(isObject, this.expected, toReadableTypeOf(data))
    // ctx.assert does not have to throw error (can just log it), so we have to re-check conditions ourselves
    if (!isObject) {
      return data as ParserResult<T[number]>
    }

    const errors: string[] = []
    const { parsersArray, discriminatorArray } = this
    const l = discriminatorArray.length

    if (!l) {
      return data as ParserResult<T[number]>
    }

    // TODO: could be creating this inside the loop to preserve original context for each iteration
    // (but YAGNI and more expensive)
    const isInitialCtxPartial = ctx.isPartial
    const throwingCtx = new Context({
      originalCtx: ctx,
      shouldStopParsingOnError: true,
      onAssertionError: (params) => {
        throw new Error(generateErrorMessage(params))
      },
    })

    let foundParser: T[number] | undefined = undefined

    for (let i = 0; i < l; i++) {
      try {
        // need to reset partial each time (since any object parser can disable it)
        // could also create new populateErrorsCtx each time, but that's more expensive
        throwingCtx.isPartial = isInitialCtxPartial
        // check if it throws
        discriminatorArray[i].parse(data, throwingCtx)
        // if line above did not throw, assign as foundParser and break
        foundParser = parsersArray[i]
        break
      } catch (e) {
        errors.push(getErrorMsg(e))
      }
    }

    if (foundParser) {
      return foundParser.parse(data, ctx) as ParserResult<T[number]>
    }

    // If we got here, all parsers have failed (since no return happened in the loop).
    // !!false to fail the assertion and avoid TS unreachable code error
    ctx.assert(
      !!foundParser,
      `to match ${this.expected}`,
      `no match: [${errors.reduce((acc, curr, i) => {
        acc += `\n\tParser ${i + 1}: ${curr},`
        return acc
      }, '')}\n].\nFalling back to Parser 1`,
    )

    const doNothingCtx = new Context({
      originalCtx: ctx,
      onAssertionError: doNothing,
    })

    // fallback to one of the parsers, while ignoring errors
    // (to still perform the expected parsing/coercing, because our error could be not that breaking)
    return parsersArray[0]!.parse(data, doNothingCtx) as ParserResult<T[number]>
  }
}

/**
 * `union()` accepts any of the types passed to it and fails if none match.
 *
 * Works for any parsers and value types.
 *
 * **WARNINGS (especially WHEN PARSING OBJECTS):**
 * 1. If ANY error occurs in all of the parsers, `union()` will fallback to the first passed parser.
 * 2. Put your most specific parsers first, so that they are checked and chosen first.\
 * Otherwise you might lose some data (check examples below).
 *
 * If you parse objects and want more precise behavior - use the recommended `discriminatedUnion()`.\
 * It allows you to target parses based on a specific key in the object.
 *
 * ```ts
 * union(string(), number()) // valid: 1, 'str', invalid: other types
 *
 * union(object({ a: number() }), number()) // valid: 1, 'str', invalid: other types
 *
 * // WARNING 1 - fallback behavior:
 * const unionObjects = union(
 *   object({ b: boolean() }),
 *   object({ a: number(), b: optional(number()), c: number() }),
 * )
 * // correctly chooses second parser, as first one gave errors:
 * parse({ a: 1, b: 2, c: 1 }, unionObjects) // result: { a: 1, b: 2, c: 1 }
 * // will fallback to first parser, because 'b' gave an error in both parsers:
 * parse({ a: 1, b: 'string', c: 1 }, unionObjects) // result: { b: 'string' }
 *
 * // WARNING 2 - put most specific parsers first:
 * // WRONG:
 * const unionObjects2 = union(
 *  object({ a: number() }),
 *  object({ a: number(), importantKey: number() }),
 * )
 * // Chooses 1st parser, as it is the first one without errors, importantKey is LOST:
 * parse({ a: 1, importantKey: 2 }, unionObjects2) // result: { a: 1 }
 *
 * // CORRECT:
 * const unionObjects2 = union(
 *  object({ a: number(), importantKey: number() }),
 *  object({ a: number() }),
 * )
 * // Chooses 1st parser, as it is the first one without errors:
 * parse({ a: 1, importantKey: 2 }, unionObjects2) // result: { a: 1 }
 * // Chooses the second parser as importantKey is missing in the first parser:
 * parse({ a: 1 }, unionObjects2) // result: { a: 1 }
 * ```
 */
export const union = <First extends Parser, Others extends ParsersArray>(
  ...parsersArray: [firstParser: First, ...otherParsers: Others]
) => new UnionParser(parsersArray)

/**
 *
 * `discriminatedUnion(discriminatorKey, [parser1, parser2])` allows you to target specific type (of union types)
 * based on the provided `discriminatorKey`. This makes your unions safer and clearer.
 *
 * **How it works:** `discriminatedUnion()` finds the first parser where key `discriminatorKey`
 * does not throw an error (parses successfully) and uses that parser to parse the whole object.
 *
 * Differences from normal `union()`:
 *
 * 1. `union()` will fallback to first parser if any error occurs in each of the parsers.
 * `discriminatedUnion()` will always stick to the parser chosen based on `discriminatorKey`
 * 2. `union()` accepts all types, `discriminatedUnion()` only accepts plain object parsers
 * 3. `union()` will skip the parser based on any error, `discriminatedUnion()` will stick to the chosen parser,
 * no matter how many errors it receives
 *
 * ```ts
 * const responseUnion = discriminatedUnion('status', [
 *   object({ status: literal('success'), data: string() }),
 *   object({ status: literal('failed'), isError: boolean(), errorMsg: string() }),
 * ])
 *
 * const data1 = { status: 'success', data: 'any data' }
 * const data2 = { status: 'failed', isError: 'str', errorMsg: 'hey' } // wrong data, isError should be boolean
 *
 * // chooses 1st parser and correctly parses:
 * parse(data1, responseUnion) // result: { status: 'success', data: 'any data' }
 * // chooses 2nd parser and logs parsing error on `isError`:
 * parse(data2, responseUnion) // result: { status: 'failed', isError: 'str', errorMsg: 'hey' }
 *
 * // Difference from normal union():
 * const normalUnion = union(
 *   object({ status: literal('success'), data: string() }),
 *   object({ status: literal('failed'), isError: boolean(), errorMsg: string() }),
 * )
 *
 * // chooses 1st parser and correctly parses:
 * parse(data1, normalUnion) // result: { status: 'success', data: 'any data' }
 * // will fallback to 1st parser, since data did not fit any of the union types:
 * parse(data2, normalUnion) // result: { status: 'failed', data: undefined }
 *
 * ```
 */
export const discriminatedUnion = <
  First extends BaseObjectParser<ParsersObject>,
  Others extends ReadonlyArray<BaseObjectParser<ParsersObject>>,
  Key extends keyof [First, ...Others][number]['parsersObject'],
>(
  discriminatorKey: Key,
  parsersArray: [First, ...Others],
) => new DiscriminatedUnion(discriminatorKey, parsersArray)
