import ClientHelperService from '@/features/printing/services/client-helper.service'
import type { AxiosInstance } from 'axios'

export function setUpPrintClientInterceptor(axiosClient: AxiosInstance) {
  axiosClient.interceptors.response.use(
    function (response) {
      // Get the latest Print client versions and save to the ClientHelperService
      const printClient = {
        platform: response.headers['x-sendcloud-client-platform'],
        type: response.headers['x-sendcloud-client-type'],
        version: response.headers['x-sendcloud-client-version'],
      }
      ClientHelperService.updateInstalledVersions(printClient)

      return response
    },

    function (error) {
      return Promise.reject(error)
    },
  )
}
