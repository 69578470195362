import { OBJECT_PARSER_TYPE } from '../constants'
import type { CTX } from '../types/context.types'
import { CtxCaseMode } from '../types/enums'
import type { ParsersObject, ParsersObjectResult, BaseObjectParser } from '../types/parsers.types'
import type { NonEmptyString } from '../types/type-helpers.types'
import { toCamelCase } from '../utils/change-case'
import { checkIsObject } from '../utils/checks'
import { toReadableTypeOf } from '../utils/helpers'

const SNAKE_TO_CAMEL_KEY_MAP: Record<string, string> = Object.create(null)

export class MagicObjectParser<T extends ParsersObject> implements BaseObjectParser<T> {
  readonly type = OBJECT_PARSER_TYPE

  private readonly keys: Array<keyof T>
  // parser has at least one snake_case key
  private readonly hasSnakeCaseKeys: boolean

  constructor(readonly expected: string, readonly parsersObject: T) {
    this.keys = Object.keys(parsersObject)
    // check if parser has at least 1 snake_case key
    let hasSnakeCaseKeys = false
    for (let i = 0, l = this.keys.length; i < l; i++) {
      const key = this.keys[i] as string
      if (SNAKE_TO_CAMEL_KEY_MAP[key]) {
        // has snake_case key and its already in the map
        hasSnakeCaseKeys = true
        continue
      }
      const transformedKey = toCamelCase(key)
      // example: `id` => `id` (would camelize to the same thing), we do not need it in the map (to save memory)
      if (transformedKey !== key) {
        // we need a map to have reliable conversion snake_case => camelCase => snake_case:
        // for example: both `country_iso_2`, `country_iso2` transform to `countryIso2`
        // (going back to snake is impossible without initial key being saved)
        // Approach: parser object is defined in snake_case and we save our camelCased version in a map
        // map also caches conversion globally and helps avoid running toCamelCase on each parse
        // (all magic objects share this 1 map)
        SNAKE_TO_CAMEL_KEY_MAP[key] = transformedKey
        // has snake_case keys (and added it to the map)
        hasSnakeCaseKeys = true
      }
    }
    this.hasSnakeCaseKeys = hasSnakeCaseKeys
  }

  parse(data: unknown, ctx: CTX): ParsersObjectResult<T> {
    const { isPartial, isDeepPartial, caseMode } = ctx
    const { keys, hasSnakeCaseKeys } = this

    const shouldSkipUndefined = isPartial || isDeepPartial

    // we must disable shallow isPartial to not influence the nested objects
    ctx.isPartial = false

    const isObject = checkIsObject(data)
    ctx.assert(isObject, this.expected, toReadableTypeOf(data))
    // ctx.assert does not have to throw error (can just log it), so we have to re-check conditions ourselves
    if (!isObject) {
      return data as ParsersObjectResult<T>
    }
    // TODO: allow disabling masking and keeping ...rest keys?
    const result: Record<string, unknown> = {}
    const { parsersObject } = this

    // we only convert, if parser has at least one snake_case key (hasSnakeCaseKeys === true)
    const fromSnakeToCamel = hasSnakeCaseKeys && caseMode === CtxCaseMode.snakeToCamel
    const fromCamelToSnake = hasSnakeCaseKeys && caseMode === CtxCaseMode.camelToSnake
    for (let i = 0, l = keys.length; i < l; i++) {
      // initial key is in snake_case (otherwise conversion will not happen)
      const parserKey = this.keys[i] as string

      // with toCamel the result should have camelKeys (with toSnake or None - resultKey === parserKey)
      const resultKey = fromSnakeToCamel ? SNAKE_TO_CAMEL_KEY_MAP[parserKey] || parserKey : parserKey
      // with toSnake initial data keys are camelCased (with toCamel or None - dataKey === parserKey)
      const dataKey = fromCamelToSnake ? SNAKE_TO_CAMEL_KEY_MAP[parserKey] || parserKey : parserKey

      const dataToTest = data[dataKey]
      // skipping partial keys
      if (shouldSkipUndefined && typeof dataToTest === 'undefined') {
        result[resultKey] = undefined
        continue
      }
      result[resultKey] = ctx.deepen(dataKey, () => parsersObject[parserKey]!.parse(dataToTest, ctx))
    }

    return result as ParsersObjectResult<T>
  }
}

/**
 * This is a Magic Object parser.
 * It parses object types.
 *
 *  It takes an object of parsers as its first argument
 * and an optional name (expected string) as a second argument.
 *
 * It can also do magic and perform deep (including nested objects)
 * conversion for all keys from **snake_case** to **camelCase** and back.
 * You can do it by wrapping your parser with corresponding parsers like so:
 * `snakeToDeepCamel(object({...}))`, `camelToDeepSnake(object({...}))`.
 *
 * Check examples below or hover over `snakeToDeepCamel` and `camelToDeepSnake` for more information.
 *
 * **Note:**
 * The second argument will be used to generate errors, for example `expected: ProvidedName, got: null`.
 *
 * If no name was provided, errors will look like this: `expected: object, got: null`
 *
 * ```ts
 *
 * // Examples:
 *
 * // will accept data of type: { a: number, c?: string }
 * const parser = object({ a: number(), c: optional(string()) }, 'MyAmazingObject')
 * // data can contain other keys, but they won't be included in the result
 * const someData = { a: 1, b: 'otherKey' }
 * // result: { a: 1 }
 * const result = parser.parse(someData, ctx)
 *
 * const otherData = { a: -9, c: 'optional' }
 * // result: { a: -9, c: 'optional' }
 * const result2 = parser.parse(otherData, ctx)
 *
 * // will parse { snake_key: number } => { snake_key: number }
 * const baseParser = object({ snake_key: number() })
 * // will parse { snake_key: number } => { snakeKey: number }
 * const snakeBEtoCamelFE = snakeToDeepCamel(baseParser)
 * // will parse { snakeKey: number } => { snake_key: number }
 * const camelFEtoSnakeBE = camelToDeepSnake(baseParser)
 *
 * // resultExample will be: { snake_key: 1 }
 * const resultExample = baseParser.parse({ snake_key: 1}, ctx)
 * // resultExample1 will be: { snakeKey: 1 }
 * const resultExample1 = snakeBEtoCamelFE.parse({ snake_key: 1}, ctx)
 * // resultExample2 will be: { snake_key: 1 }
 * const resultExample2 = camelFEtoSnakeBE.parse({ snakeKey: 1}, ctx)
 * ```
 */
export const object = <T extends ParsersObject, Name extends string>(
  parsersObject: T,
  expected?: NonEmptyString<Name>,
) => new MagicObjectParser(expected ?? 'object', parsersObject)
