import { TOAST_CONTAINER_CLASS_NAME } from '@sendcloud/ui-library/src/components/constants'

import i18n from '@/application/i18n/i18n'
import { logError } from '@/common/utils/errors/error-handlers'
import { wait } from '@/common/utils/wait'

const DEFAULT_OPTIONS = {
  autoDismiss: true,
  timeout: 5000,
}
const TOAST_ANIMATION_MS = 250 // Must stay in sync with _toast.scss
const VERTICAL_GAP = 20

let toasts: HTMLParagraphElement[] = []

export type ToastOptions = {
  autoDismiss?: boolean
  timeout?: number
}

type ToastOptionsFull = ToastOptions & {
  type: 'error' | 'info' | 'success' | 'warning'
}

function generateId(): string {
  return Math.random()
    .toString(36)
    .substring(2, 11)
}

function extractHeightFromTransform(string: string): string {
  const numberRegex = /-?[0-9]+/

  const matchedHeightData = string.match(numberRegex)

  if (matchedHeightData) {
    return matchedHeightData[0]
  } else {
    return '0'
  }
}

function createToastContainer(): void {
  const toastContainer = document.createElement('section')
  toastContainer.setAttribute('aria-labelledby', 'toast-container-heading')
  toastContainer.setAttribute('aria-live', 'polite')
  toastContainer.classList.add('sc-toast-container')
  const toastContainerHeading = document.createElement('h1')
  toastContainerHeading.setAttribute('id', 'toast-container-heading')
  toastContainerHeading.classList.add('sr-only')
  toastContainerHeading.textContent = i18n.t('Messages')
  toastContainer.appendChild(toastContainerHeading)
  document.body.appendChild(toastContainer)
}

function createToastElement({ message, type }: { message: string, type: string }): HTMLParagraphElement {
  const toast = document.createElement('p')
  toast.id = `toast-${generateId()}`
  toast.classList.add('sc-toast', `sc-toast--${type}`)
  toast.textContent = message
  toast.setAttribute('data-test', `toast-${type}`)

  const toastBtn = document.createElement('button')
  toastBtn.classList.add('sc-toast__close')
  const toastBtnText = document.createElement('span')
  toastBtnText.textContent = i18n.t('Close')
  toastBtnText.classList.add('sr-only')
  toastBtn.appendChild(toastBtnText)
  toast.appendChild(toastBtn)

  return toast
}

async function show(message: string, options: ToastOptionsFull) {
  // This to ensure temporary UiModal components are closed before we call the toast function.
  // When UiModals are open, we render toast messages inside a container in that component, due
  // to the use of `<dialog>` elements in UiModals. However, this can result in a race condition
  // with closing modals, where they are still briefly open as the toast method is called, so the toasts
  // render but then disappear with the modal.
  await wait(0)

  const toastOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  }

  const toast = createToastElement({
    message,
    type: toastOptions.type,
  })

  // First check if we have an open dialog containing a toast container.
  // Otherwise, use the one created by the init() function.
  // This is so that toasts show up in front of <dialog> elements (used in UiModals).
  const toastContainerElement = document.querySelector(`.${TOAST_CONTAINER_CLASS_NAME}`) ||
    document.querySelector('.sc-toast-container')

  if (!toastContainerElement) {
    return
  }

  toastContainerElement?.appendChild(toast)
  if (toasts.length) {
    const height = toast.getBoundingClientRect().height

    toasts.forEach((oldToast) => {
      if (oldToast.style.transform) {
        const previousHeight = parseInt(extractHeightFromTransform(oldToast.style.transform), 10)
        const newHeight = height + previousHeight
        oldToast.style.transform = `translateY(${newHeight + VERTICAL_GAP}px)`
      } else {
        oldToast.style.transform = `translateY(${height + VERTICAL_GAP}px)`
      }
    })
  }

  toast.querySelector('button')?.addEventListener('click', () => {
    close(toast)
  })

  toasts = toasts.concat([toast])

  setTimeout(() => {
    toast.classList.add('sc-toast--active')
  }, TOAST_ANIMATION_MS)

  if (toastOptions.autoDismiss) {
    setTimeout(() => {
      close(toast)
    }, toastOptions.timeout)
  }
}

function close(toast: HTMLParagraphElement) {
  toast.classList.remove('sc-toast--active')

  const height = toast.getBoundingClientRect().height
  const indexOfRemovedToast = toasts.findIndex(t => t.id === toast.id)

  setTimeout(() => {
    toast.remove()

    if (toasts.length > 1) {
      for (let i = indexOfRemovedToast - 1; i >= 0; i--) {
        if (toasts[i] && toasts[i].style.transform) {
          const previousHeight = parseInt(extractHeightFromTransform(toasts[i].style.transform), 10)
          const newHeight = previousHeight - height - VERTICAL_GAP
          toasts[i].style.transform = `translateY(${newHeight}px)`
        }
      }
    }
    toasts = toasts.filter(t => t.id !== toast.id)
  }, TOAST_ANIMATION_MS)
}

export default {
  /**
   * Initialise the ToastService by creating the toast container element. This must be run once before any other
   * methods in the service are run.
   */
  setup(): void {
    try {
      if (!document.querySelector('.sc-toast-container')) {
        createToastContainer()
      }
    } catch (error) {
      // We don't expect this to error, but if it does, the error should not affect the rest of
      // `@/common/utils/prepare-app.js`, and we should send it to Sentry just in case.
      logError(error)
    }
  },

  /**
   * Display a toast message with error styling.
   */
  error(message: string, options: ToastOptions = {}): void {
    show(message, { type: 'error', ...options })
  },

  /**
   * Display a toast message with info styling.
   */
  info(message: string, options: ToastOptions = {}): void {
    show(message, { type: 'info', ...options })
  },

  /**
   * Display a toast message with success styling.
   */
  success(message: string, options: ToastOptions = {}): void {
    show(message, { type: 'success', ...options })
  },

  /**
   * Display a toast message with warning styling.
   */
  warning(message: string, options: ToastOptions = {}): void {
    show(message, { type: 'warning', ...options })
  },

  closeAll(): void {
    const toastNodes = document.querySelectorAll('.sc-toast')
    toastNodes.forEach((toast) => {
      toast.remove()
    })
    toasts = []
  },
}
