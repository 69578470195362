import axiosRetry from 'axios-retry'
import { createAxios } from '@/common/utils/axios'

const retryClient = createAxios()
axiosRetry(retryClient, {
  retries: 100,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error => axiosRetry.isRetryableError(error) || axiosRetry.isNetworkError(error),
})

export default retryClient
