/** @typedef {import('@/types/payment').PaymentSessionConfigurationData} PaymentSessionConfigurationData */

import { CORE_DISPLAY_PAYMENT_MODAL } from '@/common/stores/core/action.types'
import EventBus from '@/common/utils/event-bus'
import axios from '@/common/utils/axios'
import store from '@/common/stores/store'

export const PAYMENT_INVOICE_REF_TYPE = {
  NONE: undefined,
  INITIAL: 'initial',
}

export const RESULT_CODES = {
  AUTHENTICATION_FINISHED: 'AuthenticationFinished',
  AUTHENTICATION_NOT_REQUIRED: 'AuthenticationNotRequired',
  AUTHORISED: 'Authorised',
  CANCELLED: 'Cancelled',
  CHALLENGE_SHOPPER: 'ChallengeShopper',
  ERROR: 'Error',
  IDENTIFY_SHOPPER: 'IdentifyShopper',
  NONE: undefined,
  PENDING: 'Pending',
  PRESENT_TO_SHOPPER: 'PresentToShopper',
  RECEIVED: 'Received',
  REDIRECT_SHOPPER: 'RedirectShopper',
  REFUSED: 'Refused',
  TECHNICAL_ERROR: 'TechnicalError',
}

export const LOCALE_MAP = {
  'nl-nl': 'nl-NL',
  'en-gb': 'en-US',
  'en-us': 'en-US',
  'fr-fr': 'fr-FR',
  'de-de': 'de-DE',
  'it-it': 'it-IT',
  'es-es': 'es-ES',
}

/**
 * Request a payment session to be created for the given invoice ID
 *
 * @param {string} invoiceId - the ID of the invoice for which the payment session will be created
 * @param {string} returnUrl - the URL that the user should be loaded once the payment is processed
 * @param {boolean} recurring - whether the payment session should be created to store the payment information
 *
 * @return {Promise<PaymentSessionConfigurationData>} Session configuration object
 *
 */
async function createSession(invoiceId, returnUrl, recurring) {
  if (invoiceId === undefined) {
    return
  }

  const url = `/xhr/payments/sessions/${invoiceId}`
  const payload = {
    return_url: returnUrl,
    recurring,
  }

  const response = await axios.post(url, payload)
  return response.data
}

/**
 * Displays the payment modal based on the ID of an invoice.
 * When the invoiceId is "INITIAL", the backend will find or create
 * an initial payment invoice and create the payment session for it.
 *
 * Example:
 * <code>
 * AdyenPaymentService.show(invoiceId)
 *     .then((result) => console.log(result.invoiceId))
 *     .catch ((invoiceId) => console.log(invoiceId + ' dismissed!'))
 * </code>
 *
 * @param {string} invoiceId - the ID of the invoice for which the payment session will be created
 *
 * @return {Promise} Rejected with the invoice reference if it's dismissed.
 *                   Resolved with an object containing the invoice reference
 *
 */
function show(invoiceId) {
  return new Promise((resolve, reject) => {
    if (!invoiceId) {
      return reject(new Error(`Unexpected invoice ID value: "${invoiceId}"`))
    }
    EventBus.$once('payment-modal-completed', (invoiceId) => {
      return resolve({
        invoiceId,
      })
    })
    EventBus.$once('payment-modal-error', (invoiceId) => {
      return reject(new Error(invoiceId))
    })
    store.dispatch(CORE_DISPLAY_PAYMENT_MODAL, invoiceId)
  })
}

export default {
  PAYMENT_INVOICE_REF_TYPE,
  LOCALE_MAP,
  RESULT_CODES,
  createSession,
  show,
}
