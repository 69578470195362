import type { RouteRecordRaw } from 'vue-router'
import { faCalendarAlt, faSearch } from '@fortawesome/pro-solid-svg-icons'

import i18n from '@/application/i18n/i18n'
import { pickupsGuard, pickupsGuardUpgrade } from '@/features/pickups/guards/pickups-guard'

import type { RouteButton } from '@/common/types/route.types'

export const PICKUPS_ROUTE = 'pickups'
export const PICKUPS_ARCHIVE_SCHEDULED_ROUTE = 'pickups-archive-scheduled'
export const PICKUPS_ARCHIVE_HISTORY_ROUTE = 'pickups-archive-history'
export const PICKUPS_ARCHIVE_CANCELLED_ROUTE = 'pickups-archive-cancelled'
export const PICKUPS_SCHEDULE_ROUTE = 'pickups-schedule'
export const PICKUPS_SCHEDULE_WIZARD_ROUTE = 'pickups-schedule-wizard'
export const PICKUPS_SCHEDULE_RECURRING_ROUTE = 'pickups-schedule-recurring'
export const PICKUPS_FEATURE_TEASER = 'pickups-feature-teaser'

const viewRequestedPickupsBtn: RouteButton = {
  title: () => i18n.t('View requested pickups'),
  name: PICKUPS_ARCHIVE_SCHEDULED_ROUTE,
  mode: 'primary',
  icon: faSearch,
  permissions: ['manage_pickups'],
}

const requestPickupBtn: RouteButton = {
  title: () => i18n.t('Request a pickup'),
  icon: faCalendarAlt,
  mode: 'primary',
  name: PICKUPS_SCHEDULE_WIZARD_ROUTE,
  dataTest: 'request-a-pickup-button',
  permissions: ['manage_pickups'],
}

export default [
  {
    name: PICKUPS_ROUTE,
    path: '/shipping/pickups',
    beforeEnter: pickupsGuard,
    component: () => import('@/features/pickups/components/PickupsListNav.vue'),
    redirect: { name: PICKUPS_ARCHIVE_SCHEDULED_ROUTE },
    meta: {
      pageTitle: () => i18n.t('Pickups'),
      permissions: ['manage_pickups'],
    },
    alias: ['/pickups', '/shipping/pickups'],
    children: [
      {
        name: PICKUPS_ARCHIVE_SCHEDULED_ROUTE,
        path: '/shipping/pickups/archive/scheduled',
        component: () => import('@/features/pickups/pages/PickupsListScheduledPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Scheduled pickups'),
          permissions: ['manage_pickups'],
          buttons: [requestPickupBtn],
          parent: 'shipping-pickups',
        },
        alias: ['/pickups/archive/scheduled', '/shipping/pickups/archive/scheduled'],
      },
      {
        name: PICKUPS_ARCHIVE_HISTORY_ROUTE,
        path: '/shipping/pickups/archive/archive',
        component: () => import('@/features/pickups/pages/PickupsListArchivedPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Pickup archive'),
          permissions: ['manage_pickups'],
          buttons: [requestPickupBtn],
          parent: 'shipping-pickups',
        },
        alias: ['/pickups/archive/archive', '/shipping/pickups/archive/archive'],
      },
      {
        name: PICKUPS_ARCHIVE_CANCELLED_ROUTE,
        path: '/shipping/pickups/archive/cancelled',
        component: () => import('@/features/pickups/pages/PickupsListCancelledPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Cancelled pickups'),
          permissions: ['manage_pickups'],
          buttons: [requestPickupBtn],
          parent: 'shipping-pickups',
        },
        alias: ['/pickups/archive/cancelled', '/shipping/pickups/archive/cancelled'],
      },
    ],
  },
  {
    name: PICKUPS_SCHEDULE_ROUTE,
    path: '/shipping/pickups/schedule',
    beforeEnter: pickupsGuard,
    redirect: { name: PICKUPS_SCHEDULE_WIZARD_ROUTE },
    component: () => import('@/features/pickups/pages/PickupRequestPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Request a pickup'),
      permissions: ['manage_pickups'],
      parent: 'shipping-pickups',
    },
    alias: ['/pickups/schedule', '/shipping/pickups/schedule'],
    children: [
      {
        name: PICKUPS_SCHEDULE_WIZARD_ROUTE,
        path: '/shipping/pickups/schedule/wizard',
        component: () => import('@/features/pickups/pages/PickupRequestFormPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Request a pickup'),
          permissions: ['manage_pickups'],
          buttons: [viewRequestedPickupsBtn],
          parent: 'shipping-pickups',
        },
        alias: ['/pickups/schedule/wizard', '/shipping/pickups/schedule/wizard'],
      },
      {
        name: PICKUPS_SCHEDULE_RECURRING_ROUTE,
        path: '/shipping/pickups/schedule/recurring',
        component: () => import('@/features/recurring-pickups/pages/RecurringPickupRequestFormPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Request a pickup'),
          permissions: ['manage_pickups'],
          buttons: [viewRequestedPickupsBtn],
          parent: 'shipping-pickups',
        },
        alias: ['/pickups/schedule/recurring', '/shipping/pickups/schedule/recurring'],
      },
    ],
  },
  {
    name: PICKUPS_FEATURE_TEASER,
    path: '/shipping/pickups/upgrade-your-plan',
    beforeEnter: pickupsGuardUpgrade,
    component: () => import('@/features/pickups/pages/PickupsFeatureTeaserPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Pickups'),
      permissions: ['manage_pickups'],
      parent: 'shipping-pickups',
    },
    alias: ['/pickups/upgrade-your-plan', '/shipping/pickups/upgrade-your-plan'],
  },
] satisfies RouteRecordRaw[]
