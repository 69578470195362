import ToastService from '@/common/services/toast.service'
import { logError } from '@/common/utils/errors/error-handlers'
import { completeAction, fetchAllOpportunities } from '@/features/opportunity-center/api/opportunity-center.api'
import { type ActionResponse, type OpportunityCenterResponse, STATE } from '@/features/opportunity-center/types/opportunity-center.types'
import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useOpportunityStore = defineStore('opportunityStore', () => {
  // State
  const opportunities = ref<OpportunityCenterResponse | null>(null)
  const isLoading = ref(false)
  const error = ref<string | null>(null)
  const opportunityNotification = ref(0)

  // Getters
  const getOpportunityNotification = computed<number>(() => opportunityNotification.value)

  const isOpportunityAvailable = computed(() => opportunityNotification.value > 0)

  // Fetch all opportunities
  const fetchOpportunitiesData = async (updateIsLoading: boolean = true) => {
    if (updateIsLoading) {
      isLoading.value = true
    }

    error.value = null

    try {
      const response = await fetchAllOpportunities()
      opportunities.value = response
      updateOpportunityNotification()
    } catch {
      // temporary error response
      error.value = 'An error occurred while fetching opportunities'
      ToastService.error(error.value)
    } finally {
      isLoading.value = false
    }
  }

  /**
   * Send a request to the backend to mark an action as completed
   */
  const completeOpportunityAction = async (action: ActionResponse): Promise<void> => {
    try {
      await completeAction(action)

      // For now, we re-fetch the entire catalog when we've completed an action. This
      // is because there is a lot of logic behind the scenes that we don't want to
      // replicate here.
      // todo(Jason): return the updated catalog from the backend
      await fetchOpportunitiesData(false)
    } catch (err) {
      if (isAxiosError(err)) {
        ToastService.error('There was a problem with your request, try again later')
      }

      logError(err)
    }
  }

  // Update the opportunity notification count
  const updateOpportunityNotification = () => {
    if (opportunities.value) {
      opportunityNotification.value = opportunities.value.topics.reduce((total, topic) => {
        const createdCount = topic.opportunities.filter(
          opportunity => opportunity.state === STATE.NOT_STARTED || opportunity.state === STATE.IN_PROGRESS,
        ).length
        return total + createdCount
      }, 0)
    }
  }

  return {
    // State
    opportunities,
    isLoading,
    error,
    opportunityNotification,

    // Getters
    isOpportunityAvailable,
    getOpportunityNotification,

    // Actions
    fetchOpportunitiesData,
    completeOpportunityAction,
    updateOpportunityNotification,
  }
})
