import axios from 'axios'
import { getAbsoluteBackendURL } from '@/common/utils/backend'
import FileUploadApi from '@/common/api/file-upload.api'
import type {
  SubbrokerCarrierSchema,
  DSFProofOfDeliveryResponse,
  SupportRequestPayload,
} from '@/features/support/types/support.legacy'
import type {
  SupportPaginatedResponse,
  SupportTab,
  TicketAttachmentToken,
  ClaimQueryResponse,
  EspertoClaimResponse,
  CSATSurveyValues,
  TicketCategories,
  SupportTicketListColumns,
} from '@/features/support/types/support.types'
import type { DFSchema, DFUploadedFile } from '@/common/components/dynamic-form/dynamic-form.types'
import { getVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'

const axiosDSFClient = axios.create({
  baseURL: getAbsoluteBackendURL(import.meta.env.VUE_APP_SUPPORT_DSF_BASE_URL),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

const axiosClient = axios.create({
  baseURL: getAbsoluteBackendURL(import.meta.env.VUE_APP_SUPPORT_SERVICE_BASE_URL),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

/**
 * Builds the DSF Service URL for the route passed.
 */
function buildDSFUrl(route: string): string {
  const baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_SUPPORT_DSF_BASE_URL)
  return `${baseUrl}${route}`
}

type LocalStorageColumnKey = typeof LOCAL_STORAGE_KEYS.SUPPORT_SAAS_TICKET_LIST_COLUMNS
  | typeof LOCAL_STORAGE_KEYS.SUPPORT_SHIPPING_TICKET_LIST_COLUMNS

/**
 * Builds the Support Service URL for the route passed.
 */
function buildSupportUrl(route: string): string {
  const baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_SUPPORT_SERVICE_BASE_URL)
  return `${baseUrl}${route}`
}

function removeEmptyKeys(obj: Record<string, unknown>) {
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([_key, value]) => value && value !== 'all'),
  )
  return filteredObj
}

export default {
  async getContractSubbrokerForm(carrierCode: string): Promise<SubbrokerCarrierSchema> {
    const endpoint = `/carrier-activation/${carrierCode}`
    const { data } = await axiosDSFClient.get(buildDSFUrl(endpoint))
    return data
  },

  async sendSubbrokerContractRequest(carrierCode: string, payload: Record<string, unknown>) {
    const endpoint = `/carrier-activation/${carrierCode}/request`
    const { data } = await axiosDSFClient.post(buildDSFUrl(endpoint), payload)
    return data
  },

  /**
   * Request a proof of delivery document from the DSF service.
   */
  async getProofOfDelivery(trackingNumber: string, carrierCode: string): Promise<DSFProofOfDeliveryResponse> {
    const response = await axiosDSFClient.get(buildDSFUrl(`/proof-of-deliveries/${carrierCode}/${trackingNumber}`), {
      responseType: 'arraybuffer',
    })
    return response
  },

  /**
   * Retrieve a specific dynamic support form schema.
   */
  async getSchema(supportRequestType: string, trackingNumber: string): Promise<DFSchema> {
    let url = buildDSFUrl(`/support-form-types/${supportRequestType}/fields`)

    if (trackingNumber) {
      url = `${url}?tracking_number=${trackingNumber}`
    }

    return await axiosDSFClient.get(url)
  },

  /**
   * Submit a dynamic support form to the DSF service.
   */
  async createTicketViaDSF(supportRequestType: string, supportRequestPayload: SupportRequestPayload) {
    const url = buildDSFUrl(`/support-form-types/${supportRequestType}/support-requests`)
    const response = await axiosDSFClient.post(url, supportRequestPayload)
    return response.data
  },

  /**
   * Fetch the support ticket categories from the DSF service.
   */
  async getCategories(): Promise<TicketCategories> {
    const { data } = await axiosDSFClient.get(buildDSFUrl('/support-form-types/support-categories'))
    return data
  },

  /**
   * Fetch support tickets by its type
   */
  async getTicketsByType(ticketType: SupportTab, params: Record<string, unknown>): Promise<SupportPaginatedResponse> {
    params = removeEmptyKeys(params)
    const url = buildDSFUrl(`/tickets/${ticketType}`)
    const { data } = await axiosDSFClient.get(url, { params })
    return data
  },

  /**
   * Update a ticket status
   */
  async updateTicketStatus(ticketId: number, status: string) {
    const { data } = await axiosDSFClient.put(`/tickets/${ticketId}/update-status`, {
      status: status,
    })
    return data
  },

  /**
   * Retrieve ticket details
   */
  async getTicket(id: number) {
    const { data } = await axiosDSFClient.get(buildDSFUrl(`/support-form-types/tickets/${id}`))
    return data
  },

  async addComment(id: string, comment: string, attachments: TicketAttachmentToken[]) {
    const url = buildDSFUrl(`/support-form-types/tickets/${id}/comment`)
    const { data } = await axiosDSFClient.post(url, {
      id,
      comment,
      attachments,
    })
    return data
  },

  /**
   * Validate tracking number for the support category selected by user.
   */
  async findParcel(supportType: string, trackingNumber: string) {
    const url = buildDSFUrl(`/support-form-types/tracking/parcel/${supportType}`)
    const { data } = await axiosDSFClient.post(url, {
      tracking_number: trackingNumber,
    })
    return data
  },

  /**
   * Uploads a file to DSF. If the url is not present, upload to the default path (SCP).
   */
  uploadToDSF(file: File, url: string): Promise<{ data: DFUploadedFile }> {
    if (!url) {
      return FileUploadApi.upload(file)
    }
    const payload = new FormData()
    payload.append('file', file)

    return axiosDSFClient.post(`/support-form-types${url}`, payload)
  },

  /**
   * Defines if the user accepts immediately or waits for the refund claim
   */
  manageRefundClaim(action: string, payload: string): Promise<{ data: ClaimQueryResponse }> {
    const url = buildSupportUrl(`/tickets/refund-claim/${action}`)
    return axiosClient.post(url, {
      enc_payload: payload,
    })
  },

  getEspertoClaimInfo(payload: string): Promise<{ data: EspertoClaimResponse }> {
    const url = buildSupportUrl(`/tickets/esperto-claim`)
    return axiosClient.get(url, {
      params: {
        enc_payload: payload,
      },
    })
  },

  /**
   * Saves the CSAT survey for a solved ticket
   */
  async saveTicketCsat(ticketId: number, payload: CSATSurveyValues): Promise<void> {
    return await axiosDSFClient.post(`/tickets/${ticketId}/rate`, payload)
  },

  /**
   * Fetches the columns for the support ticket list from localstorage, defaulting to the constant if not found
   */
  getStoredTicketListColumns(userId: number, storageKeyName: LocalStorageColumnKey,
    originalColumnsData: SupportTicketListColumns[]) {
    let storedColumns = getVariable(userId, storageKeyName) || []

    if (storedColumns.length) {
      const storedKeys = storedColumns.map(col => col.id)
      const originalKeys = originalColumnsData.map(col => col.id)

      originalKeys.forEach((key, index) => {
        if (!storedKeys.includes(key)) {
          storedColumns.splice(index, 0, originalColumnsData[index])
        }
      })
    } else {
      storedColumns = originalColumnsData
    }
    return storedColumns
  },
}
