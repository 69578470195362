import { isOpportunityCenterEnabled } from '@/features/opportunity-center/utils/utilities'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import type { NavigationGuardNext } from 'vue-router'

export async function opportunityCenterRouteGuard(_to: unknown, _from: unknown, next: NavigationGuardNext) {
  if (!isOpportunityCenterEnabled()) {
    next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  }
  next()
}
