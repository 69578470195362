import type { ErrorObject } from '@/common/components/errors/errors.types'

/**
 * Returns a list of error objects from a given error payload
 * in order to display error messages in a streamlined manner.
 *
 * The following error payload formats are supported:
 *
 * - a single error message
 * - a single error object
 * - a list of error messages
 * - a list of error objects
 *
 * Error objects are defined as objects that have both a `code` and a `message` property.
 */
export function normalizeErrorPayload(fieldErrors: string | Array<string | ErrorObject> | ErrorObject): ErrorObject[] {
  const errors = Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors]

  return errors.map((error) => {
    if (typeof error === 'string') {
      return { code: null, message: error }
    } else {
      if (!Object.prototype.hasOwnProperty.call(error, 'code')) {
        error.code = null
      }

      return error
    }
  })
}
