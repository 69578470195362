import type { Brand, BrandBlank } from '@/features/brands/types/brands.types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import BrandsApi from '@/features/brands/api/brands.api'
import { useStore } from 'vuex'
import { getVariable, LOCAL_STORAGE_KEYS, setVariable } from '@/common/utils/storage'
import { logError } from '@/common/utils/errors/error-handlers'
import type { ViewportDevice } from '@/features/tracking-pages/types/tracking-pages.types'
import { getWhatsAppSettingsList } from '@/features/tracking-messages/api/tracking-messages-wa.api'
import { WHATSAPP_PROVIDER } from '@/features/tracking-messages/constants'

export const useBrandsStore = defineStore('brands', () => {
  const vuexStore = useStore()

  const brands = ref<Brand[]>([])
  const lastSelectedBrandID = ref<number | null>(null)
  const previewViewportMode = ref<ViewportDevice>('desktop')

  const currentBrand = computed<Brand | undefined>(() => {
    return brands.value.find(brand => brand.id === lastSelectedBrandID.value)
  })

  const brandById = (brandId: number) => brands.value.find(brand => brand.id === brandId)

  const fetchAllBrands = async ({ forceRefetch = true, shouldUpdateCurrentBrand = false } = {}) => {
    if (!forceRefetch && !!brands.value.length) {
      return
    }
    brands.value = await BrandsApi.findAll()
    shouldUpdateCurrentBrand && readLastSelectedBrand()
  }

  const createBrand = async (brand: BrandBlank) => {
    const createdBrand = await BrandsApi.create(brand)
    brands.value.push(createdBrand)

    return createdBrand
  }

  const updateBrand = async (brand: Brand) => {
    if (!brand.id) return
    const updatedBrand = await BrandsApi.update(brand.id, brand)
    brands.value = brands.value.map(brandItem => brandItem.id === brand.id ? updatedBrand : brandItem)

    return updatedBrand
  }

  const deleteBrand = async (brandId: number) => {
    await BrandsApi.delete(brandId)
    await fetchAllBrands()
  }

  const optimisticBrandUpdate = (currentBrand: Brand) => {
    const index = brands.value.findIndex(brand => brand.id === currentBrand.id)
    brands.value[index] = currentBrand
  }

  const readLastSelectedBrand = () => {
    const brandId = getVariable(vuexStore.getters.user.id, LOCAL_STORAGE_KEYS.LAST_SELECTED_BRAND_ID)

    const brand = brands.value.find(brandItem => brandItem.id === brandId) || brands.value[0]
    updateLastSelectedBrand(brand)
  }

  const updateLastSelectedBrand = (brand: Brand) => {
    if (!brand.id) return
    setVariable(vuexStore.getters.user.id, LOCAL_STORAGE_KEYS.LAST_SELECTED_BRAND_ID, brand?.id)
    lastSelectedBrandID.value = brand.id
  }

  const checkIsPersonalWabaConnectedToAnyBrand = async () => {
    try {
      await fetchAllBrands({ forceRefetch: false })

      for (const brand of brands.value) {
        const hasPersonalWabaConnected = await checkHasPersonalWabaConnectedToBrand(brand.id)
        if (hasPersonalWabaConnected) {
          return true
        }
      }

      return false
    } catch (e) {
      const formatError = (e: unknown) => {
        return new Error('Check Personal Waba Connected To Any Brand error', {
          cause: e,
        })
      }
      logError(e, { formatError })
      return false
    }
  }

  async function checkHasPersonalWabaConnectedToBrand(brandId: number) {
    try {
      const whatsAppSettings = await getWhatsAppSettingsList(brandId)
      return whatsAppSettings.some(setting => setting.provider === WHATSAPP_PROVIDER.PERSONAL_WABA)
    } catch (e) {
      const formatError = (e: unknown) => {
        return new Error(`Error fetching WhatsApp settings for brand`, {
          cause: e,
        })
      }
      logError(e, { formatError })
      return false
    }
  }

  return {
    // State
    brands,
    lastSelectedBrandID,
    previewViewportMode,

    // Getters
    currentBrand,

    // Actions
    brandById,
    fetchAllBrands,
    createBrand,
    updateBrand,
    deleteBrand,
    optimisticBrandUpdate,
    readLastSelectedBrand,
    updateLastSelectedBrand,
    checkIsPersonalWabaConnectedToAnyBrand,
  }
})
