import axios from '@/common/utils/axios'
import i18n from '@/application/i18n/i18n'
import { normalizeError } from '@/common/utils/errors/normalize-error'
import type { ShippingProductParameters } from '@/types/api'
import type { ShippingFunctionalityDefinitions, ShippingProduct } from '@/types/models'

/**
 * Note: Query parameters are processed so that they conform to the [“form” and “explode” style][1].
 *
 * **Example**:
 *
 * ```
 * /xhr/shipping-products?carrier=postnl&last_mile=home_delivery&last_mile=mailbox
 * ```
 *
 * [1]: https://swagger.io/docs/specification/serialization/#query
 */
export async function fetchShippingProducts(options: ShippingProductParameters = {}): Promise<ShippingProduct[]> {
  try {
    const response = await axios.get('/xhr/shipping-products', { params: buildFetchShippingProductsParams(options) })
    return response.data
  } catch (error) {
    throw normalizeError(error, i18n.t('Could not load shipping services.'))
  }
}

function buildFetchShippingProductsParams(options: ShippingProductParameters): URLSearchParams {
  const params = new URLSearchParams()
  const {
    carrierCode,
    fromCountryIso2,
    toCountryIso2,
    leadTimeHours,
    hasDeliveryDays,
    ...filterParameters
  } = options

  if (carrierCode !== undefined) {
    params.append('carrier', carrierCode)
  }

  if (fromCountryIso2 !== undefined) {
    params.append('from_country', fromCountryIso2)
  }

  if (toCountryIso2 !== undefined) {
    params.append('to_country', toCountryIso2)
  }

  if (Number.isInteger(leadTimeHours)) {
    params.append('lead_time_hours', String(leadTimeHours))
  } else if (typeof leadTimeHours === 'object') {
    for (const [operator, value] of Object.entries(leadTimeHours)) {
      params.append(`lead_time_hours[${operator}]`, String(value))
    }
  }

  if (hasDeliveryDays !== undefined) {
    params.append('has_delivery_days', String(hasDeliveryDays))
  }

  for (const [filterKey, filterValues] of Object.entries(filterParameters)) {
    if (Array.isArray(filterValues)) {
      for (const filterValue of filterValues) {
        params.append(filterKey, filterValue)
      }
    } else {
      params.append(filterKey, filterValues)
    }
  }

  return params
}

export async function fetchShippingFunctionalities(): Promise<ShippingFunctionalityDefinitions> {
  try {
    const response = await axios.get('/xhr/shipping-functionalities')
    return response.data
  } catch (error) {
    throw normalizeError(error, i18n.t('Could not load shipping functionalities.'))
  }
}
