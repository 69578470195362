import axios from '@/common/utils/axios'
import type { ParcelStatusResponse } from '@/types/shipping.models'

export default {
  async find(id: number): Promise<ParcelStatusResponse> {
    const response = await axios.get(`/xhr/parcelstatus/${id}`)
    return response.data
  },

  async getInTransitStatuses(): Promise<ParcelStatusResponse[]> {
    const { data } = await axios.get('/xhr/parcelstatus?state=in_transit')
    return data
  },
}
