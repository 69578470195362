import type { Carrier } from '@/features/carriers/types/carrier.types'
import type { Contract as ContractXHR, Contract_V2 } from '@/features/carriers/types/contract.types'
import type { User } from '@/types/models'
import { CARRIERS_ID_UPS, CARRIERS_WITH_ZONAL_PRICING, COUNTRY_CODE_SPAIN } from '@/features/carriers/constants'

/** @info All the logic here based on the src/features/carriers/components/CarrierPanel.vue */

/**
 * A carrier is active if SC rates are active OR Direct contract is active
 * @param country
 * @param carrier
 * @param carrierContracts
 */
export const isCarrierActive = (country: string, carrier: Carrier, carrierContracts: Contract_V2[] = []): boolean =>
  areSCRatesActive(carrier, country) || carrierContracts.some(isDirectContractActive)

const isDirectContract = (contract: Contract_V2 | ContractXHR) => Boolean(contract.client_id)

const isDirectContractActive = (contract: Contract_V2 | ContractXHR): boolean =>
  isDirectContract(contract) && contract.is_active

/**
 * @param carrier
 * @param country
 */
export const areSCRatesActive = (carrier: Carrier, country: string) => {
  const isDirectContractOnly = isDirectContractOnlyForCountry(carrier, country)
  return carrier.is_disabled && (!isDirectContractOnly ||
    (isDirectContractOnly && usesPendingContractActivation(carrier)))
}

/**
 * Royal Mail, DHL UK and DPD FR use a pending contract activation. When user wants to create a direct contract,
 * a Zendesk ticket is created to activate the carrier.
 * @param carrier
 */
export const usesPendingContractActivation = (carrier: Carrier) =>
  ['dhl_gb', 'royal_mail', 'dpd_fr'].includes(carrier.code)

export const isDirectContractOnlyForCountry = (carrier: Carrier, country: string) =>
  carrier.direct_contract_only_countries.includes(country)

/**
 * Disabling "Request Sendcloud Rates" for GLS in any country that is not Austria since it's treated as a
 * single carrier in the CMS and it's not actually the same for each country.
 * This option should only appear for Austria for GLS_DE.
 * Proper info for all the different carriers is needed to get rid of all the hardcodes in this component.
 */
export const hasSubbrokerContractAvailable = (carrier: Carrier, country: string) => {
  if (carrier.requires_manual_activation && carrier.code === 'gls_de' && country !== 'AT') {
    return false
  }
  return carrier.requires_manual_activation
}

/**
 * Determine what the value of the contract id should be given the path
 * @param path
 */
export const determinePathContractId = (path: undefined | string | string[]) => {
  if (Array.isArray(path)) {
    return parseInt(path.join(''), 10)
  }

  if (path == null || path.trim().length === 0) {
    return null
  }

  return parseInt(path, 10)
}

export const isZonalPriceCarrier = (carrierId: number, user?: User) => {
  if (carrierId === CARRIERS_ID_UPS) {
    // Among UPS carriers, the `UPS ES` uses Zonal Pricing.
    return user?.invoice_country_iso_2 === COUNTRY_CODE_SPAIN
  }

  return CARRIERS_WITH_ZONAL_PRICING.includes(carrierId)
}
