import mixpanel from 'mixpanel-browser'

export default function startMixpanelSDK(MIXPANEL_TOKEN: string) {
  mixpanel.init(MIXPANEL_TOKEN, {
    persistence: 'localStorage',

    /**
     * Options for Mixpanel Replay
     *
     * Note: this is temporary for proof-of-concept purposes. If we keep using Replay then we'll do this properly
     * (ideally by using a specific attribute to mask sensitive data)
     */
    record_sessions_percent: 5,
    record_block_selector: '',
    record_mask_text_selector: '.iov-table__name, .address, .orders-table, .ui-label, .phone-input, .ui-input, .orders-table__cell, .address-switcher, .parcel-table__cell, .tm__phone, .email-content-editor__email, .invoice-table-wrapper',
  })
}
