import { normalizeErrorPayload } from '@/common/utils/errors/normalize-error-payload'
import type { NormalizedErrorBackendErrors, FlatNormalizedErrors } from '@/common/components/errors/errors.types'

/**
 * Normalizes error objects thrown by axios calls when making network requests to one of our backend applications.
 *
 * This process ensures that the resulting error object has the following properties:
 *
 * - The `normalizedError` will always have a `code` property.
 * - The `normalizedError` will always have a `message` property. The value `message` property is determined as follows:
 *
 *   - If `error` is just a plain string, the `message` property is set to `error`.
 *   - If the `error` holds a `non_field_errors` field in its response body, its message value will be used.
 *   - In all other cases, the `defaultErrorMessage` will be used.
 * - The `normalizedError` will always have a `backendErrors` property. It’s `null` if no backend errors can be found.
 *   Otherwise, it will match the structure of the error’s response body.
 *   All individual errors in the response body are provided as error objects
 *   which have both a `code` and a `message` property.
 */
export function normalizeError(error: any, defaultErrorMessage: string): NormalizedError {
  let message = defaultErrorMessage
  let code = null
  let backendErrors = null
  const originalResponseData = error?.response?.data

  if (typeof error === 'string') {
    message = error
  } else if (typeof error?.response?.data === 'string') {
    // In case we get a plain error string in the response payload (e.g. a Python stack trace),
    // we can instead expose the error message provided by axios (e.g. “Request failed with status code 404”).
    message = error.message
  } else if (
    typeof error?.response?.data === 'object' &&
    error.response.data !== null &&
    Object.keys(error.response.data).length > 0
  ) {
    backendErrors = processErrorTree(error.response.data)

    // Extract the first non-field error from the backend errors.
    // We typically consume non-field errors via toast notifications
    // while backend errors are associate to individual fields in the UI.
    // It’s useful to have them appear in separate structures.
    if (Array.isArray(backendErrors.non_field_errors) && backendErrors.non_field_errors?.length > 0) {
      message = backendErrors.non_field_errors[0].message
      code = backendErrors.non_field_errors[0].code
    }
  }

  return new NormalizedError(message, code, backendErrors, originalResponseData)
}

/**
 * Recursive algorithm for traversing a backend error response body and turning all its field errors into error objects.
 */
function processErrorTree(errorData: object): NormalizedErrorBackendErrors {
  const errorDataEntries = Object.entries(errorData)

  const errorSubTree: NormalizedErrorBackendErrors = {}

  for (const [fieldName, fieldErrors] of errorDataEntries) {
    if (Array.isArray(fieldErrors) || typeof fieldErrors === 'string') {
      errorSubTree[fieldName] = normalizeErrorPayload(fieldErrors)
    } else if (typeof fieldErrors === 'object' && fieldErrors !== null) {
      errorSubTree[fieldName] = processErrorTree(fieldErrors) as FlatNormalizedErrors
    } else {
      errorSubTree[fieldName] = fieldErrors
    }
  }

  return errorSubTree
}

export class NormalizedError extends Error {
  public name: string
  public code: string | null
  public backendErrors: NormalizedErrorBackendErrors | null
  public originalResponseData: unknown

  constructor(
    message: string,
    code: string | null = null,
    backendErrors: NormalizedErrorBackendErrors | null = null,
    originalResponseData: any = null) {
    super(message)
    this.name = 'NormalizedError'
    this.code = code
    this.backendErrors = backendErrors
    this.originalResponseData = originalResponseData
  }

  toJSON() {
    return {
      name: this.name,
      code: this.code,
      backendErrors: this.backendErrors,
    }
  }
}
