import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { unreadCount as pullUnread } from '@/features/notifications/api/new-notifications.api'
import { logError } from '@/common/utils/errors/error-handlers'

export const useNotificationsStore = defineStore('notifications', () => {
  /**
   * STATE
   */
  const unreadCount = ref(0)
  const notificationsPanelVisible = ref(false)

  /**
   * GETTERS
   */
  const getUnreadCount = computed<number>(() => unreadCount.value)
  const isNotificationPanelVisible = computed(() => notificationsPanelVisible.value)

  /**
   * ACTIONS
   */
  const setNotificationPanelVisible = (isNotificationPanelVisible: boolean) => {
    notificationsPanelVisible.value = isNotificationPanelVisible
  }

  const fetchUnreadCount = async () => {
    try {
      const { count } = await pullUnread()
      unreadCount.value = count
    } catch (error) {
      logError(error)
    }
  }

  return {
    // GETTERS
    getUnreadCount,
    isNotificationPanelVisible,
    // ACTIONS
    setNotificationPanelVisible,
    fetchUnreadCount,
  }
})
