import { type AxiosResponse } from 'axios'
import axios from '@/common/utils/axios'
import { getAbsoluteBackendURL } from '@/common/utils/backend'
import type { Notification } from '@/features/notifications/types/notifications.types'
import { getPaginationLinks } from '@/common/utils/pagination-headers'

let baseUrl = '/xhr'

if (process.env.NODE_ENV === 'development') {
  // in dev it's listening on another port so we need a different baseUrl
  baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_NOTIFICATION_SERVICE_BASE_URL)
}

type PaginatedResponse = {
  data: Notification[]
  next: string | null
}

const getPartialUrl = (url: string | null) => {
  return url?.includes('/notifications') ? url.substring(url.indexOf('/notifications')) : null
}

export async function fetchList(onlyUnseen: boolean, cursor?: string): Promise<PaginatedResponse> {
  let url = cursor ? `${cursor}` : '/notifications'

  if (onlyUnseen) {
    const param = 'is_seen=false'
    url += url.includes('?') ? `&${param}` : `?${param}`
  }

  const response: AxiosResponse<{
    data: Notification[]
    next: string | null
  }> = await axios.get(`${baseUrl}${url}`)

  const { data } = response.data
  const link = response.headers.link
  const { next } = getPaginationLinks(link)
  const parsedNext = getPartialUrl(next)
  return { data, next: parsedNext }
}

export async function fetchNotification(notificationId: string): Promise<Notification> {
  const { data } = await axios.get(`${baseUrl}/notifications/${notificationId}`)
  return data.data
}

export async function unreadCount(): Promise<{ count: number }> {
  const { data } = await axios.get(`${baseUrl}/notifications/unread-count`)
  return data.data
}

export async function remove(notificationId: string): Promise<unknown> {
  const response = await axios.delete(`${baseUrl}/notifications/${notificationId}`)
  return response
}

export async function markAsRead(notificationIds: string[]): Promise<unknown> {
  const queryParams = notificationIds.map(id => `notification_ids=${encodeURIComponent(id)}`).join('&')
  const response = await axios.post(`${baseUrl}/notifications/mark-as-read?${queryParams}`)
  return response
}

export async function markAsSeen(notificationIds: string[]): Promise<unknown> {
  const queryParams = notificationIds.map(id => `notification_ids=${encodeURIComponent(id)}`).join('&')
  const response = await axios.post(`${baseUrl}/notifications/mark-as-seen?${queryParams}`)
  return response
}
