type AnyKey = symbol | number | string

type GenerateKeyFunc<Item, Key extends AnyKey> = (
  item: Item,
  index: number,
) => Key | false

type GenerateValueFunc<Item, Value> = (
  item: Item,
  index: number,
) => Value

type Options<Item, Key extends AnyKey, Value> = {
  getKey: GenerateKeyFunc<Item, Key>
  getValue: GenerateValueFunc<Item, Value>
}

/**
 * Generates an object (object map) out of the provided Item[]
 *
 * You have to provide `getKey` and `getValue` functions which receive array Item and its index as an arguments
 * and must return key and value of the map accordingly
 *
 * To skip certain Keys, you can return `false` from `getKey`
 */
export const arrayToObject = <Item, Key extends AnyKey, Value>(
  array: readonly Item[],
  { getKey, getValue }: Options<Item, Key, Value>,
) => {
  return array.reduce<Record<Key, Value>>((acc, item, index) => {
    const resultKey = getKey(item, index)
    if (resultKey !== false) {
      acc[resultKey] = getValue(item, index)
    }
    return acc
  }, Object.create(null))
}
