import type {
  Integration,
  IntegrationFilter,
  IntegrationListItem,
} from '@/features/integrations/types/integrations.types'

import {
  INTEGRATIONS_DELETE_INTEGRATION,
  INTEGRATIONS_FETCH_ALL,
  INTEGRATIONS_FETCH_INTEGRATION_TYPES,
} from '@/features/integrations/stores/action.types'
import {
  INTEGRATIONS_SET_INTEGRATIONS,
  INTEGRATIONS_SET_SYSTEMS,
  INTEGRATIONS_SET_INTEGRATION_FILTERS,
} from '@/features/integrations/stores/mutation.types'

import IntegrationsApi from '@/features/integrations/api/integrations.api'

import { getVariable, removeVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'
import type { Commit, Dispatch } from 'vuex'

interface ModuleState {
  integrations: Integration[]
  integrationsWarning: boolean
  integrationFilters: IntegrationFilter[]
  systems: IntegrationListItem[]
}

const state: ModuleState = {
  integrations: [],
  integrationsWarning: false,
  integrationFilters: [],
  systems: [],
}

const getters = {
  integrations: (state: ModuleState) => state.integrations,

  integrationsWarning: (state: ModuleState) => state.integrationsWarning,

  integrationById: (state: ModuleState) => (id: Integration['id']) =>
    state.integrations.find(integration => integration.id === id),

  integrationFilters: (state: ModuleState) => state.integrationFilters,

  systemByCode: (state: ModuleState) => (code: IntegrationListItem['code']) => {
    return state.systems.find(integration => integration.code === code)
  },

  systems: (state: ModuleState) => state.systems,

  hasActiveIntegrations: (state: ModuleState) => state.integrations.length > 0,
}

const actions = {
  async [INTEGRATIONS_FETCH_ALL]({ commit }: { commit: Commit }) {
    const integrations = await IntegrationsApi.findAll()
    commit(INTEGRATIONS_SET_INTEGRATIONS, integrations)
  },

  async [INTEGRATIONS_FETCH_INTEGRATION_TYPES]({ commit }: { commit: Commit }) {
    const integrationTypes = await IntegrationsApi.getFullIntegrationsList()
    commit(INTEGRATIONS_SET_SYSTEMS, integrationTypes.results)
    commit(INTEGRATIONS_SET_INTEGRATION_FILTERS, integrationTypes.filters)
  },

  async [INTEGRATIONS_DELETE_INTEGRATION](
    { dispatch, getters }: { dispatch: Dispatch, getters: any }, integrationId: number) {
    await IntegrationsApi.delete(integrationId)
    await dispatch(INTEGRATIONS_FETCH_ALL)

    const userId = getters.user.id
    const incomingOrdersStoredFilters = getVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_FILTERS)

    if (incomingOrdersStoredFilters) {
      delete incomingOrdersStoredFilters[integrationId]
      delete incomingOrdersStoredFilters.all
      setVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_FILTERS, incomingOrdersStoredFilters)
    }

    const savedIntegrationId = getVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_LAST_INTEGRATION_ID)

    if (integrationId === savedIntegrationId) {
      removeVariable(userId, LOCAL_STORAGE_KEYS.INCOMING_ORDERS_LAST_INTEGRATION_ID)
    }
  },
}

const mutations = {
  [INTEGRATIONS_SET_INTEGRATIONS](state: ModuleState, integrations: Integration[]) {
    state.integrations = integrations
    if (integrations.length) {
      state.integrationsWarning = integrations.some(integration => integration.failing_since)
    }
  },

  [INTEGRATIONS_SET_INTEGRATION_FILTERS](state: ModuleState, integrationFilters: IntegrationFilter[]) {
    state.integrationFilters = integrationFilters
  },

  [INTEGRATIONS_SET_SYSTEMS](state: ModuleState, systems: IntegrationListItem[]) {
    state.systems = systems
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
