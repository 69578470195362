import i18n from '@/application/i18n/i18n'
import { type RouteRecordRaw, type RouteLocationNormalized, RouterView } from 'vue-router'

export const SETTINGS_BRANDS_ROUTE = 'brands'
export const SETTINGS_BRANDS_LIST_ROUTE = 'brands.list'
export const SETTINGS_BRANDS_ADD_ROUTE = 'brands.add'
export const SETTINGS_BRANDS_EDIT_ROUTE = 'brands.edit'

export default {
  name: SETTINGS_BRANDS_ROUTE,
  path: 'brands',
  redirect: { name: SETTINGS_BRANDS_LIST_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_BRANDS_LIST_ROUTE,
      path: 'list',
      component: () => import('@/features/brands/pages/BrandListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('settings.brands.overview.title'),
        permissions: ['manage_brands'],
        parent: 'settings-brands',
      },
    },
    {
      name: SETTINGS_BRANDS_ADD_ROUTE,
      path: 'add',
      component: () => import('@/features/brands/pages/BrandEditorPage.vue'),
      props: {
        editMode: false,
      },
      meta: {
        pageTitle: () => i18n.t('Add brand'),
        permissions: ['manage_brands'],
        backLinkRoute() {
          return {
            title: i18n.t('settings.brands.edit.button_return'),
            to: { name: SETTINGS_BRANDS_LIST_ROUTE },
          }
        },
        parent: 'settings-brands',
      },
    },
    {
      name: SETTINGS_BRANDS_EDIT_ROUTE,
      path: ':id',
      component: () => import('@/features/brands/pages/BrandEditorPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        id: Number.parseInt(route.params.id as string),
        editMode: true,
      }),
      meta: {
        pageTitle: () => i18n.t('settings.brands.edit.title'),
        permissions: ['manage_brands'],
        backLinkRoute() {
          return {
            title: i18n.t('settings.brands.edit.button_return'),
            to: { name: SETTINGS_BRANDS_LIST_ROUTE },
          }
        },
        parent: 'settings-brands',
      },
    },
  ],
} satisfies RouteRecordRaw
