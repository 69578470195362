import OppoAllCreated from '@/features/opportunity-center/mocks/oppo_all_created.json'
import type { OpportunityCenterResponse } from '@/features/opportunity-center/types/opportunity-center.types'

const proxy = {
  'GET /opportunity-center/opportunities': (_req: unknown, res: { json: (body: OpportunityCenterResponse) => void }) => {
    setTimeout(() => {
      res.json(OppoAllCreated as OpportunityCenterResponse)
    }, 1000)
  },
}

export default proxy
