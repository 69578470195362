import i18n from '@/application/i18n/i18n'
import store from '@/common/stores/store'
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { NEW_NOTIFICATIONS_BETA } from '@/features/support/beta-features'
export const NOTIFICATION_ROUTE = 'notifications.list'
export const NOTIFICATION_DETAILS_ROUTE = 'notifications.details'

const isNotificationsBeta = () => {
  if (store.getters.hasBetaFeature(NEW_NOTIFICATIONS_BETA)) {
    return true
  }
  return false
}

export default [
  {
    name: NOTIFICATION_ROUTE,
    path: '/notifications',
    component: () => import('@/features/notifications/pages/NotificationList.vue'),
    meta: {
      pageTitle: () => i18n.t('Notifications'),
      permissions: [
        /* Intentionally left empty */
      ],
    },
  },
  {
    name: NOTIFICATION_DETAILS_ROUTE,
    path: '/notifications/:notificationId',
    component: () => {
      if (isNotificationsBeta()) {
        return import ('@/features/notifications/pages/NewNotificationDetail.vue')
      }
      return import('@/features/notifications/pages/NotificationDetail.vue')
    },
    props: (route: RouteLocationNormalized) => {
      return { notificationId: route.params.notificationId }
    },
    meta: {
      pageTitle: () => i18n.t('Notifications'),
      permissions: [
        /* Intentionally left empty */
      ],
      buttons: () => {
        if (isNotificationsBeta()) {
          return []
        }
        return [
          {
            id: 'open-notifications-list',
            name: NOTIFICATION_ROUTE,
            title: () => i18n.t('Notifications'),
            mode: 'secondary',
            permissions: [
              /* Intentionally left empty */
            ],
          },
        ]
      },
    },
  },
] satisfies RouteRecordRaw[]
