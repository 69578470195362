import type { CTX, CreateContextParams } from './types/context.types'
import { CtxCaseMode } from './types/enums'
import type { AnyKey } from './types/type-helpers.types'
import { doNothing } from './utils/helpers'

export class Context implements CTX {
  readonly path: AnyKey[]
  isPartial: boolean
  isDeepPartial: boolean
  // allows to safe time when using union() with array(), for example
  shouldStopParsingOnError: boolean
  maxErrorsInOneMsg: number
  caseMode: CtxCaseMode
  onAssertionError: CreateContextParams['onAssertionError']

  constructor({ onAssertionError, originalCtx, maxErrorsInOneMsg, shouldStopParsingOnError }: CreateContextParams) {
    this.path = []
    this.maxErrorsInOneMsg = maxErrorsInOneMsg ?? originalCtx?.maxErrorsInOneMsg ?? 3
    this.shouldStopParsingOnError = shouldStopParsingOnError ?? originalCtx?.shouldStopParsingOnError ?? false
    // TODO: not sure if we need to preserve it, but so far did not have issues with it
    this.isPartial = originalCtx?.isPartial ?? false
    this.isDeepPartial = originalCtx?.isDeepPartial ?? false
    this.caseMode = originalCtx?.caseMode ?? CtxCaseMode.none
    this.onAssertionError = onAssertionError
  }

  deepen<T>(key: AnyKey, callback: () => T): T {
    this.path.push(key)
    try {
      return callback()
    } finally {
      this.path.pop()
    }
  }

  assert(condition: boolean, expected: string, got: string): boolean {
    if (condition) {
      return true
    }
    // we spread to avoid mutation by receivers and also to help testing
    this.onAssertionError({ path: [...this.path], expected, got })
    return false
  }
}

// to not create it over and over again, when we do not need to throw
export const DO_NOTHING_CTX = new Context({ onAssertionError: doNothing })
