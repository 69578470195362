import axios from '@/common/utils/axios'

export default {
  /**
   * Uploads a file via the backend.
   *
   * @param {File} file
   * @returns {Promise<{ data: { id: number, file: string, content_type: string }}>}
   * an object with a “file” field containing a URL to the uploaded file.
   */
  upload(file) {
    const payload = new FormData()
    payload.append('file', file)

    return axios.post('/xhr/fileupload/', payload)
  },

  /**
   * Uploads a public file i.e.(response payload 'file' field will allow preview of the file) via the backend.
   *
   * @param {File} file
   * @returns {Promise<{ data: { id: number, file: string, content_type: string }}>}
   * an object with a “file” field containing a URL to the uploaded file.
   */
  uploadPublic(file) {
    const payload = new FormData()
    payload.append('file', file)

    return axios.post('/xhr/fileupload/public', payload)
  },

  async uploadMultipart(payload) {
    const response = await axios.post('/xhr/fileupload/', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  },

  /**
   * Sends a `File` object to the backend.
   *
   * @param {File} file
   * @param {Boolean} isPublic, whether the returning file should be public or not
   * @returns Promise<{{ id: number, contentType: string, src: string }}>
   * an object that includes the uploaded file’s ID, the file’s content type,
   * and a URL that can be used as an image’s “src” attribute.
   */
  async sendFile(file, isPublic = false) {
    try {
      const { data } = isPublic ? await this.uploadPublic(file) : await this.upload(file)

      return {
        id: data.id,
        src: data.file,
        contentType: data.content_type,
      }
    } catch (error) {
      let message

      if (error.response && error.response.data && Array.isArray(error.response.data.file)) {
        message = error.response.data.file.join('<br>')
      } else {
        message = error.message
      }

      throw new Error(message)
    }
  },
}
