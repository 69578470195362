<template>
  <footer
    :class="{ 'hijack-footer--top': moveToTop }"
    class="hijack-footer"
  >
    <span>
      You are working on behalf of <strong>{{ user.company_name }}</strong> (ID: {{ user.id }}).
    </span>

    <button
      class="ui-button ui-button--hijack"
      type="button"
      @click="copyUserData"
    >
      Copy user data
    </button>

    <span class="ui-padding-8--right">
      Release this user and go to
    </span>

    <button
      class="ui-button ui-button--hijack"
      type="button"
      @click="releaseUser('user-details')"
    >
      User details
    </button>

    <button
      class="ui-button ui-button--hijack"
      type="button"
      @click="releaseUser('users-list')"
    >
      Users list
    </button>

    <button
      class="ui-button ui-button--hijack"
      type="button"
      @click="releaseUser"
    >
      Admin
    </button>

    <button
      v-if="isOnboardingRoute"
      class="ui-button ui-button--hijack"
      type="button"
      @click="skipOWAndGoToApp"
    >
      Skip Onboarding Wizard
    </button>

    <button
      class="hijack-footer__chevron"
      type="button"
      @click="moveToTop = !moveToTop"
    >
      <svg
        aria-hidden="true"
        focusable="false"
        height="24px"
        version="1.1"
        viewBox="0 0 306 250"
        width="24px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="matrix(-1.837e-16 1 -1 -1.837e-16 306 0)">
          <polygon
            points="94.35 0 58.65 35.7 175.95 153 58.65 270.3 94.35 306 247.35 153"
            fill="#bf3c5c"
          />
        </g>
      </svg>

      <span class="sr-only">
        Switch position
      </span>
    </button>
  </footer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import type { Integration } from '@/features/integrations/types/integrations.types'
import type { User } from '@/types/models'

import UsersApi from '@/features/account/api/users.api'
import { getAbsoluteBackendURL, getLogoutURL } from '@/common/utils/backend'
import { ONBOARDING_WIZARD_SKIP } from '@/features/onboarding-wizard/stores/action.types.js'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import OnboardingAnalytics from '@/features/onboarding-wizard/utils/onboarding.analytics'

const store = useStore()
const router = useRouter()
const route = useRoute()

const moveToTop = ref(false)

const integrations = computed<Integration[]>(() => store.getters.integrations)
const user = computed<User>(() => store.getters.user)
const isOnboardingRoute = computed(() => {
  return route.matched.some(matchedRoute => matchedRoute.name === ONBOARDING_WIZARD_ROUTE)
})

async function releaseUser(sendTo: 'user-details' | 'users-list' | MouseEvent): Promise<void> {
  try {
    await UsersApi.stopAgentLoginSession()

    if (sendTo === 'user-details') {
      window.location.href = getAbsoluteBackendURL(`/admin-ng/users/user/${user.value.id}/view/`, false)
    } else if (sendTo === 'users-list') {
      window.location.href = getAbsoluteBackendURL('/admin-ng/users/user/', false)
    } else {
      window.location.href = getAbsoluteBackendURL('/admin-ng/', false)
    }
  } catch {
    window.location.href = getLogoutURL()
  }
}

async function copyUserData(): Promise<void> {
  const data = {
    user: user.value,
    integrations: integrations.value,
  }

  await navigator.clipboard.writeText(JSON.stringify(data, null, 2))
}

async function skipOWAndGoToApp() {
  await store.dispatch(ONBOARDING_WIZARD_SKIP)
  OnboardingAnalytics.onboardingWizardSkippedByAgent()
  router.push({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
}
</script>

<style lang="scss" scoped>
.hijack-footer {
  align-items: center;
  background-color: #f38ba5;
  border-top: 1px solid #e64e74;
  bottom: 0;
  color: $sc-black;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  padding: 10px 60px 0 20px;
  position: fixed;
  white-space: nowrap;
  width: 100%;
  z-index: 1100;

  > * {
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.hijack-footer--top {
  border-bottom: 1px solid #e64e74;
  border-top: 0;
  bottom: auto;
  top: 0;
}

.ui-button--hijack {
  background-color: $sc-label-pink;
  border-color: #e64e74;
  color: $sc-white;
  flex-shrink: 0;
  height: 30px;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: color.mix($sc-label-pink, $sc-white, 90%);
  }
}

.hijack-footer__chevron {
  background-color: transparent;
  border: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
  width: 60px;

  &:hover,
  &:focus {
    background-color: color.mix(#e64e74, $sc-white, 90%);
  }

  .hijack-footer--top & {
    transform: rotate(0);
  }
}
</style>
