import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'

export const SETTINGS_USERS_ROUTE = 'users'
export const SETTINGS_USERS_INVITATIONS_ROUTE = 'users.invitations'
export const SETTINGS_USERS_LINKED_USERS_ROUTE = 'users.linked'

export default {
  name: SETTINGS_USERS_ROUTE,
  path: 'users',
  redirect: { name: SETTINGS_USERS_INVITATIONS_ROUTE },
  component: () => import('@/features/users-and-roles/pages/UsersNav.vue'),
  children: [
    {
      name: SETTINGS_USERS_INVITATIONS_ROUTE,
      path: 'users/invitations',
      component: () => import('@/features/users-and-roles/pages/InvitedUsersPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Users'),
        permissions: ['manage_subusers'],
        parent: 'settings-users',
      },
    },
    {
      name: SETTINGS_USERS_LINKED_USERS_ROUTE,
      path: 'users/manage',
      component: () => import('@/features/users-and-roles/pages/ActiveUsersPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Users'),
        permissions: ['manage_subusers'],
        parent: 'settings-users',
      },
    },
  ],
} satisfies RouteRecordRaw
