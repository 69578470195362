/** @typedef {import('@/features/shipping-defaults/types/shipping-defaults.types').ShippingDefaults} ShippingDefaults */

import ShippingDefaultsService from '@/features/shipping-defaults/services/shipping-defaults.service'

import { CORE_SET_USER } from '@/common/stores/core/mutation.types'

import {
  SHIPPING_FETCH_DEFAULT_SETTINGS,
  SHIPPING_UPDATE_DEFAULT_SETTINGS,
} from '@/features/shipping-defaults/stores/action.types'

import { SHIPPING_SET_DEFAULT_SETTINGS } from '@/features/shipping-defaults/stores/mutation.types'

const getters = {
  shippingDefaults: (/** @type {ModuleState} */ state) => state.shippingDefaults,
}

const actions = {
  async [SHIPPING_FETCH_DEFAULT_SETTINGS]({ commit }) {
    const defaults = await ShippingDefaultsService.getDefaults()
    commit(SHIPPING_SET_DEFAULT_SETTINGS, defaults)
  },

  async [SHIPPING_UPDATE_DEFAULT_SETTINGS](
    { commit, getters },
    {
      weight,
      insuredValue,
      insuredPercentage,
      shippingMethod,
      showOrderReference,
      showPortLetter,
      showQRCodeLetter,
      useOrderNumberAsInvoiceNumber,
      userCustomsShipmentType,
    },
  ) {
    const defaults = {
      default_weight: weight,
      parcel_default_insured_value: insuredValue,
      default_insurance_percentage: insuredPercentage,
      parcel_default_shipping_method: shippingMethod,
      parcel_show_order_reference_label: showOrderReference,
      parcel_show_port_letter: showPortLetter,
      parcel_show_qr_code_letter: showQRCodeLetter,
      use_order_number_as_invoice_number: useOrderNumberAsInvoiceNumber,
      parcel_default_customs_shipment_type: userCustomsShipmentType,
    }

    // Temporary work-around in order to maintain a correct state for both possible insurance type values
    if (defaults.parcel_default_insured_value === '') {
      defaults.parcel_default_insured_value = 0
    }
    if (defaults.default_insurance_percentage === '') {
      defaults.default_insurance_percentage = null
    }

    const updatedDefaults = await ShippingDefaultsService.updateDefaults(defaults)

    // Shipping defaults belong to the overall account, and are not local per
    // each and every subuser. Yet, historically, they are stored on user level
    // which is something we're moving away from. Yet, for now, we'll keep the
    // user-level settings in sync as well:
    const user = { ...getters.user, ...updatedDefaults }
    commit(CORE_SET_USER, user)

    return commit(SHIPPING_SET_DEFAULT_SETTINGS, updatedDefaults)
  },
}

const mutations = {
  /**
   * @param {ModuleState} state
   * @param {Array<ShippingDefaults>} defaults
   */
  [SHIPPING_SET_DEFAULT_SETTINGS](state, defaults) {
    state.shippingDefaults = defaults
  },
}

export default {
  getters,
  actions,
  mutations,
}
