import { useI18n } from 'vue-i18n'

import axios from '@/common/utils/axios'
import { NormalizedError } from '@/common/utils/errors/normalize-error'
import type { Currency } from '@/types/models'

export default {
  async fetchCurrencies(): Promise<Currency[]> {
    const { t } = useI18n()

    try {
      const response = await axios.get('/xhr/currencies')
      return response.data
    } catch {
      const message = t('Could not load currencies.')
      throw new NormalizedError(message, 'api.currencies.failed_to_load_currencies')
    }
  },
}
