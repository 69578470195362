/** @typedef {import('@/features/subscription/types/subscriptions.types').SubscriptionPlan} SubscriptionPlan */

import i18n from '@/application/i18n/i18n'

export const PRICING_PILOT_GROUPS = ['free', 'lite', 'growth', 'premium']

/*
 * !Please note! Because our back-end does not add subscriptions for users who
 * who are on an essential plan or are on a custom 12500+ shipments plan we have
 * to add them ourselves in the front-end. The "volume_to: 14999" value is a hack
 * to match the NumberStepper max value so "12500+" can be displayed in it's input.
 */

const PRICE_NEGOTIABLE = -1

export const subscriptionsStaticData = () => {
  const PRICING_PLANS_2022_DISPLAY_DATA = {
    descriptions: {
      free: {
        'Ideal for merchants shipping under 50 parcels per month': i18n.t(
          'Ideal for merchants shipping under 50 parcels per month',
        ),
      },
      lite: {
        'Ideal for merchants shipping between 50 – 400 parcels per month': i18n.t(
          'Ideal for merchants shipping between 50 – 400 parcels per month',
        ),
      },
      growth: {
        'Ideal for merchants shipping between 400 – 1000 parcels per month': i18n.t(
          'Ideal for merchants shipping between 400 – 1000 parcels per month',
        ),
      },
      premium: {
        'Ideal for merchants shipping over 1000 parcels per month': i18n.t(
          'Ideal for merchants shipping over 1000 parcels per month',
        ),
      },
      enterprise: {
        'settings.plans.list.enterprise.description': i18n.t(
          'settings.plans.list.enterprise.description',
        ),
      },
    },
    features: {
      free: {
        'Use Sendcloud discounted rates': i18n.t('Use Sendcloud discounted rates'),
        'Integrate your shop (number differ per plan)': i18n.t('Integrate your shop (number differ per plan)'),
        'Pre-selected branded tracking emails': i18n.t('Pre-selected branded tracking emails'),
        'Service points embedded in checkout': i18n.t('Service points embedded in checkout'),
        'Email and live chat support': i18n.t('Email and live chat support'),
      },
      lite: {
        'All Free plan features': i18n.t('All Free plan features'),
        'Ship with your own carrier contract': i18n.t('Ship with your own carrier contract'),
        'All branded tracking emails, 2 languages': i18n.t('All branded tracking emails, 2 languages'),
        'Checkout plugin w/ standard delivery': i18n.t('Checkout plugin w/ standard delivery'),
        'Smart shipping rules (number differ per plan)': i18n.t('Smart shipping rules (number differ per plan)'),
      },
      growth: {
        'All Lite plan features': i18n.t('All Lite plan features'),
        'Multi-language tracking emails': i18n.t('Multi-language tracking emails'),
        'Branded tracking pages': i18n.t('Branded tracking pages'),
        'Checkout plugin w/ same-day delivery': i18n.t('Checkout plugin w/ same-day delivery'),
        'Phone support': i18n.t('Phone support'),
        'Pack & Go packing solution': i18n.t('Pack & Go packing solution'),
      },
      premium: {
        'All Growth plan features': i18n.t('All Growth plan features'),
        'Return portal': i18n.t('Return portal'),
        'Pack & Go packing solution': i18n.t('Pack & Go packing solution'),
        'Checkout plugin w/ nominated-day delivery': i18n.t('Checkout plugin w/ nominated-day delivery'),
        'Customer Success Manager': i18n.t('Customer Success Manager'),
      },
    },
  }

  return {
    PRICING_PLANS_2022_DISPLAY_DATA,

    SUBSCRIPTION_DESCRIPTIONS: {
      essential: i18n.t(
        'Start offering more shipping options in your online store with our competitive shipping rates.',
      ),
      small_shop: i18n.t(
        'Combine your own carrier contract with our shipping platform and brand your entire shipping experience.',
      ),
      large_shop: i18n.t(
        'Turn shipping into a growth accelerator with our tracking widgets and automate your order processing with our packing solution Pack & Go.',
      ),
      business: i18n.t('Get a head start by integrating your workflows and stores with the help of our specialists.'),
      free: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions.free)[0],
      lite: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions.lite)[0],
      growth: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions.growth)[0],
      premium: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions.premium)[0],
      enterprise: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions.enterprise)[0],
    },

    SUBSCRIPTION_FEATURES_SUMMARY: {
      essential: [
        i18n.t('Use pre-negotiated shipping rates'),
        i18n.t('Service points in your checkout'),
        i18n.t('Branded Tracking messages'),
        i18n.t('Personalised return portal'),
      ],
      small_shop: [
        i18n.t('Use pre-negotiated shipping rates'),
        i18n.t('Max. 5 smart shipping rules'),
        i18n.t('Schedule pick-ups with carriers'),
        i18n.t('Branded Tracking page'),
        i18n.t('Dynamic Checkout: Standard delivery'),
      ],
      large_shop: [
        i18n.t('Use pre-negotiated shipping rates'),
        i18n.t('Unlimited shipping and return rules'),
        i18n.t('Widgets on Tracking page'),
        i18n.t('Pack & Go picking solution'),
        i18n.t('Dynamic Checkout: Same day delivery'),
      ],
      business: [
        i18n.t('Use pre-negotiated shipping rates'),
        i18n.t('Pack & Go picking solution'),
        i18n.t('Remove Sendcloud watermark'),
        i18n.t('Dedicated account manager'),
        i18n.t('Dynamic Checkout: Nominated day delivery'),
      ],
      free: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.features.free),
      lite: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.features.lite),
      growth: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.features.growth),
      premium: Object.values(PRICING_PLANS_2022_DISPLAY_DATA.features.premium),
    },

    /** @type {SubscriptionPlan[]} */
    ESSENTIAL_SUBSCRIPTIONS: [
      {
        code: 'essential',
        currency: null,
        discounted_price: 0,
        group: 'essential',
        is_trial_allowed: false,
        pay_per_parcel: null,
        plan_type: 'monthly',
        price: 0,
        volume_from: 0,
        volume_to: 0,
        title: i18n.t('Essential'),
      },
      {
        code: 'essential',
        currency: null,
        discounted_price: 0,
        group: 'essential',
        is_trial_allowed: false,
        pay_per_parcel: null,
        plan_type: 'yearly',
        price: 0,
        volume_from: 0,
        volume_to: 0,
        title: i18n.t('Essential'),
      },
    ],

    /** @type {SubscriptionPlan[]} */
    BUSINESS_MAX_SUBSCRIPTIONS: [
      {
        code: 'business_max_monthly',
        currency: null,
        discounted_price: null,
        group: 'business',
        is_trial_allowed: false,
        pay_per_parcel: null,
        plan_type: 'monthly',
        price: PRICE_NEGOTIABLE,
        title: i18n.t('Business'),
        volume_from: 12500,
        volume_to: 14999,
      },
      {
        code: 'business_max_yearly_discounted',
        currency: null,
        discounted_price: null,
        group: 'business',
        is_trial_allowed: false,
        pay_per_parcel: null,
        plan_type: 'yearly',
        price: PRICE_NEGOTIABLE,
        title: i18n.t('Business'),
        volume_from: 12500,
        volume_to: 14999,
      },
    ],

    USER_SUBSCRIPTIONS_LEGACY_FREE_SUBSCRIPTION: {
      code: 'legacy_free_user',
      discounted_price: 0,
      group: 'legacy_free_user',
      is_trial_allowed: false,
      is_trial: false,
      is_active: true,
      plan_type: 'monthly',
      price: 0,
      volume_from: 0,
      volume_to: 0,
      subscription: {
        code: 'legacy_free_user',
        price: 0.0,
        group: 'legacy_free_user',
        volume_from: null,
        volume_to: null,
        is_trial_allowed: false,
        plan_type: 'monthly',
        discounted_price: null,
      },
    },

    USER_SUBSCRIPTIONS_ESSENTIAL_SUBSCRIPTION: {
      code: 'essential',
      discounted_price: 0,
      group: 'essential',
      is_trial_allowed: false,
      is_trial: false,
      is_active: true,
      plan_type: 'monthly',
      price: 0,
      volume_from: 0,
      volume_to: 0,
      subscription: {
        code: 'essential',
        price: 0.0,
        group: 'essential',
        volume_from: null,
        volume_to: null,
        is_trial_allowed: false,
        plan_type: 'monthly',
        discounted_price: null,
      },
    },

    GROUP_ORDER: {
      essential: 1,
      legacy_free_user: 2,
      saas: 3,
      small_shop: 4,
      large_shop: 5,
      plus: 6,
      plus_saas: 6,
      business: 7,
    },

    GROUP_ORDER_CHARGEBEE: {
      free: 1,
      lite: 2,
      growth: 3,
      premium: 4,
      enterprise: 5,
    },
  }
}
