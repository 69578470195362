import i18n from '@/application/i18n/i18n'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { CONVERSATIONS_BETA_FEATURE } from '@/features/whatsapp-conversations/beta-features'

export const WHATSAPP_CONVERSATIONS_ROUTE = 'conversations'

export default [
  {
    name: 'conversations',
    path: '/conversations',
    component: () => import('@/features/whatsapp-conversations/pages/ConversationsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Conversations'),
      permissions: ['manage_whatsapp_chats'],
      parent: 'conversations',
    },
    beforeEnter: betaGuard(CONVERSATIONS_BETA_FEATURE, SHIPPING_INCOMING_ORDERS_ROUTE),
  },
]
