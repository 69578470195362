<template>
  <section>
    <template v-if="hasChargebeeSubscriptionEnabled">
      <AppPaymentWallChargeBee />
    </template>
    <template v-else>
      <AppPaymentModal
        v-if="showPaymentModal"
        data-test="app-payment-modal"
        @on-payment-result="openPaymentResult"
      />
      <AppPaymentResultModal
        v-else-if="showPaymentResultModal"
        :result-info="paymentResultInfo"
        data-test="app-payment-result-modal"
        @close="closePaymentResult"
      />
      <AppPaymentWall v-else-if="showPaymentWall" />
    </template>
  </section>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { TYPES } from '@/features/payment-wall/services/payment-wall.service'
import { CHARGEBEE_SUBSCRIPTION_ENABLED } from '@/features/financial/beta-features'

import AppPaymentResultModal from '@/features/payment-wall/components/AppPaymentResultModal.vue'

const AppPaymentModal = defineAsyncComponent(() => import('@/features/payment-wall/components/AppPaymentModal.vue'))
const AppPaymentWall = defineAsyncComponent(() => import('@/features/payment-wall/components/AppPaymentWall.vue'))
const AppPaymentWallChargeBee = defineAsyncComponent(() => import('@/features/payment-wall/components/AppPaymentWallChargeBee.vue'))

const store = useStore()

type ResultCode =
  | 'AUTHORISED'
  | 'CANCELLED'
  | 'ERROR'
  | 'REFUSED'
  | 'PENDING'
  | 'RECEIVED'
  | 'TECHNICAL_ERROR'

type ResultInfo = {
  resultCode: ResultCode
  isRedirect: boolean
}

const paymentResultInfo = ref<ResultInfo | undefined>(undefined)
const isPaymentRedirect = ref<boolean | undefined>(false)

const paymentInvoiceId = computed(() => store.getters.paymentInvoiceId)
const paymentWallType = computed(() => store.getters.paymentWallType)

const hasChargebeeSubscriptionEnabled = computed<boolean>(
  () => store.getters.hasBetaFeature(CHARGEBEE_SUBSCRIPTION_ENABLED),
)

const showPaymentModal = computed(() => {
  return isPaymentRedirect.value || (paymentInvoiceId.value !== undefined && paymentResultInfo.value === undefined)
})

const showPaymentResultModal = computed(() => paymentResultInfo.value !== undefined)

const showPaymentWall = computed(() => {
  return paymentWallType.value && Object.values(TYPES).includes(paymentWallType.value)
})

onMounted(() => {
  const searchParams = new URLSearchParams(window.location.search)
  if (!window.location.search) {
    isPaymentRedirect.value = false
  } else {
    isPaymentRedirect.value = searchParams.has('redirectResult') && searchParams.has('sessionId')
  }
})

function openPaymentResult(result: ResultInfo) {
  isPaymentRedirect.value = undefined
  paymentResultInfo.value = result
}

function closePaymentResult() {
  paymentResultInfo.value = undefined
}
</script>
