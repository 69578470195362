import dayJsLocale from 'dayjs/locale/en.js'
import ttMessages from '@sendcloud/tt-components/src/lang/en-US.json'
import uiMessages from '@sendcloud/ui-library/src/lang/en-US.json'

import appMessages from '@/application/locales/en-US.json'

import type { LocaleBundle } from '@/application/types/i18n.types'

export const localeBundle: LocaleBundle = {
  dayJsLocale,
  messages: {
    ...ttMessages,
    ...uiMessages,
    ...appMessages, // Override library messages with potentially more app-specific messages
  },
}
