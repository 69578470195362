import { createRouter, createWebHistory, type RouteRecordName } from 'vue-router'

import routes from '@/application/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { PACKGO_QUEUE_ROUTE } from '@/features/packgo/routes'
import callTrackPageTransition from '@/application/routes/guards/call-track-page-transition'
import redirectToTermsAndConditions from '@/application/routes/guards/redirect-to-terms-and-conditions'
import { sendSpeedCurveLuxEventGuard } from '@/application/routes/guards/send-speed-curve-lux-event'
import { setPageTitle } from '@/application/routes/guards/set-page-title'
import userRoleRouteGuard from '@/application/routes/guards/user-role-route-guard'

import { redirectAwayOrToOnboardingWizard } from '@/features/onboarding-wizard/guards/onboarding-wizard-guards'
import addBacklinkToBrands from '@/features/brands/guards/brands-backlink-route-guard'

const router = createRouter({
  history: createWebHistory(import.meta.env.VUE_APP_ROUTER_BASE_PATH),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
})

const speedcurveWhitelistedRoutes: RouteRecordName[] = [PACKGO_QUEUE_ROUTE, SHIPPING_INCOMING_ORDERS_ROUTE]

router.beforeEach(redirectToTermsAndConditions)
router.beforeEach(userRoleRouteGuard)
router.beforeEach(redirectAwayOrToOnboardingWizard)

// afterEach guards are being fired even if navigation was aborted.
// Use third argument (failure) to check for it.
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks

router.afterEach((to, from, failure) => {
  if (failure === undefined) {
    if (from.name && to.name) {
      const shouldSendEventsPreviousPage = speedcurveWhitelistedRoutes.includes(from.name)
      const shouldInitEventsNextPage = speedcurveWhitelistedRoutes.includes(to.name)

      sendSpeedCurveLuxEventGuard(shouldSendEventsPreviousPage, shouldInitEventsNextPage)
    }

    setPageTitle(to)

    callTrackPageTransition(to, from)

    addBacklinkToBrands(to)
  }
})

export default router
