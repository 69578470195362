/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
import i18n from '@/application/i18n/i18n'
import { type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router'
import type { RouteButton } from '@/common/types/route.types'
import { getHelpCenterUrl } from '@/common/services/help-center-url.service'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

import {
  SETTINGS_NEW_RETURNS_APPEARANCE_ROUTE,
  SETTINGS_NEW_RETURNS_SETTINGS_ROUTE,
  SETTINGS_RETURNS_ROUTE,
} from '@/application/routes/settings.routes.names'

const btnHelpCenter: RouteButton = {
  id: 'open-help-center',
  title: () => i18n.t('Help center'),
  name: 'Help center button',
  href: getHelpCenterUrl('returnportal', 'settings'),
  newTab: true,
  mode: 'borderless',
  icon: faInfoCircle,
  align: 'right',
  permissions: ['configure_returns'],
}

export default {
  name: SETTINGS_RETURNS_ROUTE,
  path: 'return-portal',
  redirect: { name: SETTINGS_NEW_RETURNS_SETTINGS_ROUTE },
  component: () => import('@/features/return-portal/pages/ReturnPortalSettingsWrapperPage.vue'),
  props: (route: RouteLocationNormalized) => ({
    code: route.query?.code,
    state: route.query?.state,
  }),
  meta: {
    pageTitle: () => i18n.t('Return portal'),
    permissions: ['configure_returns'],
    buttons: [btnHelpCenter],
    parent: 'returns-portal',
  },
  children: [
    {
      name: SETTINGS_NEW_RETURNS_SETTINGS_ROUTE,
      path: 'settings',
      component: () => import('@/features/return-portal/pages/ReturnPortalSettingsSettingsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Return portal'),
        permissions: ['configure_returns'],
        buttons: [btnHelpCenter],
        parent: 'returns-portal',
      },
    },
    {
      name: SETTINGS_NEW_RETURNS_APPEARANCE_ROUTE,
      path: 'appearance',
      component: () => import('@/features/return-portal/pages/ReturnPortalSettingsAppearancePage.vue'),
      meta: {
        pageTitle: () => i18n.t('Return portal'),
        permissions: ['configure_returns'],
        buttons: [btnHelpCenter],
        parent: 'returns-portal',
      },
    },
  ],
} satisfies RouteRecordRaw
