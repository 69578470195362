import { isPackGoFeatureEnabled } from '@/features/packgo/utils/utilities'
import { PACKGO_FEATURE_TEASER, PACKGO_QUEUE_ROUTE } from '@/features/packgo/routes'
import type { NavigationGuardNext, RouteLocation } from 'vue-router'

export async function packGoRouteGuard(_to: unknown, _from: unknown, next: NavigationGuardNext) {
  if (!isPackGoFeatureEnabled()) {
    next({ name: PACKGO_FEATURE_TEASER })
  }
  next()
}

export function packGoGuardUpgrade(_to: RouteLocation, _from: RouteLocation, next: NavigationGuardNext) {
  if (!isPackGoFeatureEnabled()) {
    return next()
  }
  next({ name: PACKGO_QUEUE_ROUTE })
}
