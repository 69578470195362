import axios from '@/common/utils/axios'

import type { ShippingDefaults } from '@/features/shipping-defaults/types/shipping-defaults.types'

export default {
  async getDefaults(): Promise<ShippingDefaults> {
    const { data } = await axios.get('/xhr/shipping-defaults') as { data: ShippingDefaults }
    return data
  },

  async updateDefaults(payload: ShippingDefaults): Promise<ShippingDefaults> {
    const { data } = await axios.put('/xhr/shipping-defaults', payload) as { data: ShippingDefaults }
    return data
  },
}
