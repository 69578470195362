import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw, RouteLocationNormalizedLoaded } from 'vue-router'
import type { BackLinkRoute } from '@/common/types/route.types'

export const CHECKOUT_CONFIGURATIONS_LIST_ROUTE = 'checkout-configurations-view'
export const CHECKOUT_CONFIGURATIONS_DETAIL_ROUTE = 'checkout-configuration-detail-view'
export const CHECKOUT_DELIVERY_ZONES_ADD_ROUTE = 'checkout-delivery-zones-add'
export const CHECKOUT_DELIVERY_METHOD_ADD_ROUTE = 'checkout-delivery-methods-add'
export const CHECKOUT_DELIVERY_METHOD_EDIT_ROUTE = 'checkout-delivery-methods-edit'
export const TEMPLATE_CHECKOUT_DETAIL_ROUTE = 'template-checkout-detail-view'
export const TEMPLATE_CHECKOUT_DELIVERY_METHOD_ADD_ROUTE = 'template-checkout-delivery-methods-add'
export const TEMPLATE_CHECKOUT_DELIVERY_ZONES_ADD_ROUTE = 'template-checkout-delivery-zones-add'
export const TEMPLATE_CHECKOUT_DELIVERY_METHOD_EDIT_ROUTE = 'template-checkout-delivery-methods-edit'

export default {
  name: 'checkout',
  path: '/settings/checkout',
  redirect: { name: CHECKOUT_CONFIGURATIONS_LIST_ROUTE },
  component: () => import('@/features/dynamic-checkout/pages/CheckoutBasePage.vue'),
  children: [
    {
      name: CHECKOUT_CONFIGURATIONS_LIST_ROUTE,
      path: '/settings/checkout/configurations',
      component: () => import('@/features/dynamic-checkout/pages/configurations/CheckoutOverviewPage.vue'),
      meta: {
        pageTitle: () => 'Dynamic Checkout',
        permissions: ['configure_checkout'],
        parent: 'settings-configurations',
      },
      alias: ['/checkout/configurations', '/settings/checkout/configurations'],
    },

    {
      name: TEMPLATE_CHECKOUT_DETAIL_ROUTE,
      path: '/settings/checkout/templates/:templateId',
      component: () => import('@/features/dynamic-checkout/pages/delivery-zones/TemplateDeliveryZoneListPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        templateId: route.params.templateId,
        openSectionDeliveryZoneId: route.query.openSectionDeliveryZoneId,
      }),
      meta: {
        pageTitle: () => 'Dynamic Checkout',
        permissions: ['configure_checkout'],
        backLinkRoute() {
          return {
            title: i18n.t('Go back to checkout overview'),
            to: { name: CHECKOUT_CONFIGURATIONS_LIST_ROUTE },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/templates/:templateId', '/settings/checkout/templates/:templateId'],
    },

    {
      name: CHECKOUT_CONFIGURATIONS_DETAIL_ROUTE,
      path: '/settings/checkout/configurations/:integrationId',
      component: () => import('@/features/dynamic-checkout/pages/delivery-zones/DeliveryZoneListPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        integrationId: route.params.integrationId,
        openSectionDeliveryZoneId: route.query.openSectionDeliveryZoneId,
      }),
      meta: {
        pageTitle: () => 'Dynamic Checkout',
        permissions: ['configure_checkout'],
        backLinkRoute() {
          return {
            title: i18n.t('Go back to Dynamic Checkout configurations'),
            to: { name: CHECKOUT_CONFIGURATIONS_LIST_ROUTE },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/configurations/:integrationId', '/settings/checkout/configurations/:integrationId'],
    },

    {
      name: CHECKOUT_DELIVERY_ZONES_ADD_ROUTE,
      path: '/settings/checkout/configurations/:integrationId/delivery-zones/new',
      component: () => import('@/features/dynamic-checkout/pages/delivery-zones/DeliveryZoneFormPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        integrationId: route.params?.integrationId,
      }),
      meta: {
        pageTitle: () => i18n.t('New delivery zone'),
        permissions: ['configure_checkout'],
        backLinkRoute(route?: RouteLocationNormalizedLoaded): BackLinkRoute {
          return {
            title: i18n.t('Go back to the Dynamic Checkout configuration'),
            to: { name: CHECKOUT_CONFIGURATIONS_DETAIL_ROUTE, params: { integrationId: route?.params.integrationId } },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/configurations/:integrationId/delivery-zones/new', '/settings/checkout/configurations/:integrationId/delivery-zones/new'],
    },

    {
      name: TEMPLATE_CHECKOUT_DELIVERY_ZONES_ADD_ROUTE,
      path: '/settings/checkout/configurations/:templateId/delivery-zones/new',
      component: () => import('@/features/dynamic-checkout/pages/delivery-zones/TemplateDeliveryZoneFormPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        templateId: route.params.templateId,
      }),
      meta: {
        pageTitle: () => i18n.t('New delivery zone'),
        permissions: ['configure_checkout'],
        backLinkRoute(route?: RouteLocationNormalizedLoaded): BackLinkRoute {
          return {
            title: i18n.t('Go back to template configuration'),
            to: {
              name: TEMPLATE_CHECKOUT_DETAIL_ROUTE,
              params: {
                templateId: route?.params.templateId,
                openSectionDeliveryZoneId: route?.query.openSectionDeliveryZoneId as string,
              },
            },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/configurations/:templateId/delivery-zones/new', '/settings/checkout/configurations/:templateId/delivery-zones/new'],
    },

    {
      name: TEMPLATE_CHECKOUT_DELIVERY_METHOD_ADD_ROUTE,
      path: '/settings/checkout/templates/:templateId/delivery-zones/:deliveryZoneId/delivery-methods/new',
      component: () => import('@/features/dynamic-checkout/pages/delivery-methods/TemplateDeliveryMethodFormPage.vue'),
      props: route => ({
        deliveryZoneId: route.params.deliveryZoneId,
        templateId: route.params.templateId,
        deliveryMethodType: route.query.type,
      }),
      meta: {
        pageTitle: () => i18n.t('New delivery method'),
        permissions: ['configure_checkout'],
        backLinkRoute(route?: RouteLocationNormalizedLoaded): BackLinkRoute {
          return {
            title: i18n.t('Go back to template configuration'),
            to: {
              name: TEMPLATE_CHECKOUT_DETAIL_ROUTE,
              params: { integrationId: route?.params.templateId },
            },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/templates/:templateId/delivery-zones/:deliveryZoneId/delivery-methods/new', '/settings/checkout/templates/:templateId/delivery-zones/:deliveryZoneId/delivery-methods/new'],
    },

    {
      name: TEMPLATE_CHECKOUT_DELIVERY_METHOD_EDIT_ROUTE,
      path: '/settings/checkout/templates/:templateId/delivery-zones/:deliveryZoneId/delivery-methods/:deliveryMethodId',
      component: () => import('@/features/dynamic-checkout/pages/delivery-methods/TemplateDeliveryMethodFormPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        templateId: route.params.templateId,
        deliveryZoneId: route.params.deliveryZoneId,
        deliveryMethodId: route.params.deliveryMethodId,
        deliveryMethodType: route.query.type,
      }),
      meta: {
        pageTitle: () => i18n.t('Edit delivery method'),
        permissions: ['configure_checkout'],
        backLinkRoute(route?: RouteLocationNormalizedLoaded): BackLinkRoute {
          return {
            title: i18n.t('Go back to template configuration'),
            to: {
              name: TEMPLATE_CHECKOUT_DETAIL_ROUTE,
              params: { integrationId: route?.params.integrationId },
            },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/templates/:templateId/delivery-zones/:deliveryZoneId/delivery-methods/:deliveryMethodId', '/settings/checkout/templates/:templateId/delivery-zones/:deliveryZoneId/delivery-methods/:deliveryMethodId'],
    },

    {
      name: CHECKOUT_DELIVERY_METHOD_ADD_ROUTE,
      path: '/settings/checkout/configurations/:integrationId/delivery-zones/:deliveryZoneId/delivery-methods/new',
      component: () => import('@/features/dynamic-checkout/pages/delivery-methods/DeliveryMethodFormPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        deliveryZoneId: route.params.deliveryZoneId,
        integrationId: route.params.integrationId,
        deliveryMethodType: route.query.type,
      }),
      meta: {
        pageTitle: () => i18n.t('New delivery method'),
        permissions: ['configure_checkout'],
        backLinkRoute(route?: RouteLocationNormalizedLoaded): BackLinkRoute {
          return {
            title: i18n.t('Go back to the Dynamic Checkout configuration'),
            to: { name: CHECKOUT_CONFIGURATIONS_DETAIL_ROUTE, params: { integrationId: route?.params.integrationId } },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/configurations/:integrationId/delivery-zones/:deliveryZoneId/delivery-methods/new', '/settings/checkout/configurations/:integrationId/delivery-zones/:deliveryZoneId/delivery-methods/new'],
    },

    {
      name: CHECKOUT_DELIVERY_METHOD_EDIT_ROUTE,
      path: '/settings/checkout/configurations/:integrationId/delivery-zones/:deliveryZoneId/delivery-methods/:deliveryMethodId',
      component: () => import('@/features/dynamic-checkout/pages/delivery-methods/DeliveryMethodFormPage.vue'),
      props: (route: RouteLocationNormalizedLoaded) => ({
        integrationId: route.params.integrationId,
        deliveryZoneId: route.params.deliveryZoneId,
        deliveryMethodId: route.params.deliveryMethodId,
        deliveryMethodType: route.query.type,
      }),
      meta: {
        pageTitle: () => i18n.t('Edit delivery method'),
        permissions: ['configure_checkout'],
        backLinkRoute(route?: RouteLocationNormalizedLoaded): BackLinkRoute {
          return {
            title: i18n.t('Go back to the Dynamic Checkout configuration'),
            to: { name: CHECKOUT_CONFIGURATIONS_DETAIL_ROUTE, params: { integrationId: route?.params.integrationId } },
          }
        },
        parent: 'settings-configurations',
      },
      alias: ['/checkout/configurations/:integrationId/delivery-zones/:deliveryZoneId/delivery-methods/:deliveryMethodId', '/settings/checkout/configurations/:integrationId/delivery-zones/:deliveryZoneId/delivery-methods/:deliveryMethodId'],
    },
  ],
  meta: {
    pageTitle: () => 'Dynamic Checkout',
    permissions: ['configure_checkout'],
    parent: 'settings-configurations',
  },
} satisfies RouteRecordRaw
