import { RETURNS_SET_DEFAULTS } from '@/features/return-defaults/stores/mutation.types'

import { RETURNS_FETCH_DEFAULTS } from '@/features/return-defaults/stores/action.types'

import ReturnDefaultsApi from '@/features/return-defaults/api/return-defaults.api'

import type { ReturnsState, ReturnDefaults } from '@/types/returns'

const initialState = {
  defaults: null,
}

const getters = {
  returnDefaults: (state: ReturnsState) => state.defaults,
}

const actions = {
  async [RETURNS_FETCH_DEFAULTS](
    { state, commit }: { state: ReturnsState, commit: Function },
  ) {
    if (state.defaults === null) {
      const defaults = await ReturnDefaultsApi.getReturnDefaults()
      commit(RETURNS_SET_DEFAULTS, defaults)
    }
  },
}

const mutations = {
  [RETURNS_SET_DEFAULTS](state: ReturnsState, defaults: ReturnDefaults) {
    state.defaults = defaults
  },
}

export default {
  state: () => initialState,
  getters,
  actions,
  mutations,
}
