import type { CTX } from '../types/context.types'
import type { Parser } from '../types/parsers.types'

class EarlyReturnParser<T, T2> implements Parser<T | T2> {
  constructor(
    private readonly parser: Parser<T>,
    readonly checkShouldEarlyReturn: (data: unknown, ctx: CTX) => data is T2,
    readonly expected: string,
  ) {}

  parse(data: unknown, ctx: CTX) {
    if (this.checkShouldEarlyReturn(data, ctx)) {
      return data
    }
    return this.parser.parse(data, ctx)
  }
}

/**
 * Accepts `undefined` or the correct parser type
 */
export const optional = <T>(parser: Parser<T>) =>
  new EarlyReturnParser(
    parser,
    (data: unknown): data is undefined => typeof data === 'undefined',
    `(${parser.expected} | undefined)`,
  )
/**
 * Accepts `null` or the correct parser type
 */
export const nullable = <T>(parser: Parser<T>) =>
  new EarlyReturnParser(parser, (data): data is null => data === null, `(${parser.expected} | null)`)

/**
 * maybe(...) accepts `null`, `undefined` or the correct parser type
 */
export const maybe = <T>(parser: Parser<T>) =>
  new EarlyReturnParser(
    parser,
    (data): data is null | undefined => data === null || typeof data === 'undefined',
    `(${parser.expected} | null | undefined)`,
  )
