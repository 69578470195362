export const SHIPPING_CHECK_FOR_ACTION_REQUIRED = 'SHIPPING_CHECK_FOR_ACTION_REQUIRED'
export const SHIPPING_FETCH_CARRIERS = 'SHIPPING_FETCH_CARRIERS'
export const SHIPPING_FETCH_CARRIERS_LAZILY = 'SHIPPING_FETCH_CARRIERS_LAZILY'
export const SHIPPING_FETCH_NOTIFICATION_HISTORY = 'SHIPPING_FETCH_NOTIFICATION_HISTORY'
export const SHIPPING_FETCH_SHIPPING_FUNCTIONALITIES = 'SHIPPING_FETCH_SHIPPING_FUNCTIONALITIES'
export const SHIPPING_FETCH_SHIPPING_METHODS = 'SHIPPING_FETCH_SHIPPING_METHODS'
export const SHIPPING_FETCH_SHIPPING_METHODS_LAZILY = 'SHIPPING_FETCH_SHIPPING_METHODS_LAZILY'
export const SHIPPING_FETCH_SHIPPING_PRODUCTS = 'SHIPPING_FETCH_SHIPPING_PRODUCTS'
export const SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY = 'SHIPPING_FETCH_SHIPPING_PRODUCTS_LAZILY'
export const SHIPPING_FETCH_INCOMING_SHIPPING_METHODS = 'SHIPPING_FETCH_INCOMING_SHIPPING_METHODS'
export const SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY = 'SHIPPING_FETCH_INCOMING_SHIPPING_METHODS_LAZILY'
export const SHIPPING_FETCH_SHIPPING_PRICES = 'SHIPPING_FETCH_SHIPPING_PRICES'
export const SHIPPING_FETCH_SHIPPING_PRICES_LAZILY = 'SHIPPING_FETCH_SHIPPING_PRICES_LAZILY'
