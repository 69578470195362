import type { Integration } from '@/features/integrations/types/integrations.types'

/**
 * Count how many integrations from an array are set as billable
 */
export function countBillableIntegrations(integrations: Integration[]): number {
  return integrations.reduce(
    (billableIntegrationsCount, integration) =>
      integration.billable === false ? billableIntegrationsCount : billableIntegrationsCount + 1,
    0,
  )
}
