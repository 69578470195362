import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'

export const ANALYTICS_SHIPPING_ROUTE = 'analytics-shipping'

export default [
  // redirects users to the main analytics url in case they bookmarked the old one
  {
    name: 'analytics-new',
    path: '/analytics-new',
    redirect: { name: ANALYTICS_SHIPPING_ROUTE },
    children: [
      {
        name: 'analytics-new-shipping',
        path: '/analytics-new/shipping',
        redirect: { name: ANALYTICS_SHIPPING_ROUTE },
        meta: {
          pageTitle: () => i18n.t('Analytics'),
          permissions: ['view_analytics'],
          parent: 'analytics',
        },
      },
    ],
  },
  {
    name: 'analytics',
    path: '/analytics',
    redirect: { name: ANALYTICS_SHIPPING_ROUTE },
    component: () => import('@/features/analytics/pages/AnalyticsPage.vue'),
    children: [
      {
        name: ANALYTICS_SHIPPING_ROUTE,
        path: '/analytics/shipping',
        component: () => import('@/features/analytics/pages/AnalyticsShipmentsListPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Analytics'),
          permissions: ['view_analytics'],
          parent: 'analytics',
        },
      },
    ],
    meta: {
      pageTitle: () => i18n.t('Analytics'),
      permissions: ['view_analytics'],
      parent: 'analytics',
    },
  },
] satisfies RouteRecordRaw[]
