import { statusPageAxiosClient } from '@/common/utils/axios'

import type { StatuspageIncident } from '@/features/statuspage-widget/types/statuspage'

const statuspageEndpoints = {
  incidents: 'https://4zcwhnfptdl5.statuspage.io/api/v2/incidents.json',
}

/**
 * Returns a list of incidents from the [Statuspage.io API][api].
 *
 * [api]: http://status.sendcloud.sc/api#incidents
 */
export async function getIncidents(): Promise<{ data: { incidents: StatuspageIncident[] } }> {
  return statusPageAxiosClient.get(statuspageEndpoints.incidents)
}
