import axios from '@/common/utils/axios'

export default {
  create(address) {
    return axios.post('/xhr/pickupaddress', address)
  },

  destroy(id) {
    return axios.delete(`/xhr/pickupaddress/${id}`)
  },

  find(id) {
    return axios.get(`/xhr/pickupaddress/${id}`)
  },

  findAll() {
    return axios.get('/xhr/pickupaddress')
  },

  update(address) {
    return axios.patch(`/xhr/pickupaddress/${address.id}`, address)
  },
}
