import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw, RouteLocationNormalized } from 'vue-router'

export const SETTINGS_ADDRESSES_ROUTE = 'addresses'
export const SETTINGS_ADDRESSES_SENDER_LIST_ROUTE = 'addresses.sender_address'
export const SETTINGS_ADDRESSES_RETURN_LIST_ROUTE = 'addresses.return_address'
export const SETTINGS_ADDRESSES_PICKUP_LIST_ROUTE = 'addresses.pickup_address'
export const SETTINGS_ADDRESSES_INVOICE_ADD_ROUTE = 'addresses.invoice_address'
export const SETTINGS_ADDRESSES_ADD_ROUTE = 'addresses.add'
export const SETTINGS_ADDRESSES_EDIT_ROUTE = 'addresses.edit'

export default {
  name: SETTINGS_ADDRESSES_ROUTE,
  path: 'addresses',
  redirect: { name: SETTINGS_ADDRESSES_SENDER_LIST_ROUTE },
  component: () => import('@/features/addresses/pages/AddressesPage.vue'),
  meta: {
    pageTitle: () => i18n.t('Addresses'),
    permissions: ['manage_addresses'],
    parent: 'settings-addresses',
  },
  children: [
    {
      name: SETTINGS_ADDRESSES_SENDER_LIST_ROUTE,
      path: 'sender',
      component: () => import('@/features/addresses/pages/SenderAddressesPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Addresses'),
        permissions: ['manage_addresses'],
        parent: 'settings-addresses',
      },
    },
    {
      name: SETTINGS_ADDRESSES_RETURN_LIST_ROUTE,
      path: 'returns',
      component: () => import('@/features/addresses/pages/ReturnAddressesPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Addresses'),
        permissions: ['manage_addresses'],
        parent: 'settings-addresses',
      },
    },
    {
      name: SETTINGS_ADDRESSES_PICKUP_LIST_ROUTE,
      path: 'pickup',
      component: () => import('@/features/addresses/pages/PickupAddressesPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Addresses'),
        permissions: ['manage_addresses'],
        parent: 'settings-addresses',
      },
    },
    {
      name: SETTINGS_ADDRESSES_INVOICE_ADD_ROUTE,
      path: 'invoice_address',
      component: () => import('@/features/addresses/pages/AddressFormPage.vue'),
      props: {
        initialAddressType: 'invoice',
      },
      meta: {
        pageTitle: () => i18n.t('Addresses'),
        permissions: ['manage_addresses'],
        parent: 'settings-addresses',
      },
    },
    {
      name: SETTINGS_ADDRESSES_ADD_ROUTE,
      path: 'add',
      component: () => import('@/features/addresses/pages/AddressFormPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        initialAddressType: route.query.addressType,
      }),
      meta: {
        pageTitle: () => i18n.t('Add address'),
        permissions: ['manage_addresses'],
        parent: 'settings-addresses',
      },
    },
    {
      name: SETTINGS_ADDRESSES_EDIT_ROUTE,
      path: ':id',
      component: () => import('@/features/addresses/pages/AddressFormPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        addressId: Number.parseInt(route.params.id as string),
        initialAddressType: route.query.addressType,
      }),
      meta: {
        pageTitle: () => i18n.t('Edit address'),
        permissions: ['manage_addresses'],
        parent: 'settings-addresses',
      },
    },
  ],
} satisfies RouteRecordRaw
