import type { RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

export const SETTINGS_PLANS_ROUTE = 'settings.subscriptions.plans'
export const SETTINGS_PLANS_ROUTE_SWITCH = 'settings.subscriptions.plans.switch'
export const SETTINGS_OLD_PLANS_ROUTE = 'settings.subscriptions.plans-old'

export default [
  {
    name: SETTINGS_PLANS_ROUTE,
    path: '/accounts/subscriptions/plans',
    component: () => import('@/features/subscription/pages/PlansSettingsPage.vue'),
    props: {
      isPilotPage: true,
    },
    meta: {
      pageTitle: () => i18n.t('Plans'),
      permissions: ['manage_subscriptions'],
      parent: 'account-plans',
    },
    alias: ['/settings/subscriptions/plans', '/accounts/subscriptions/plans'],
  },

  {
    name: SETTINGS_PLANS_ROUTE_SWITCH,
    path: '/accounts/subscriptions/plans-switch',
    component: () => import('@/features/subscription/pages/PlansSettingsPage.vue'),
    props: {
      isPilotPage: true,
      isSwitchPromotionPage: true,
    },
    meta: {
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PLANS_ROUTE },
        }
      },
      pageTitle: () => i18n.t('Plans'),
      permissions: ['manage_subscriptions'],
      parent: 'account-plans',
    },
    alias: ['/settings/subscriptions/plans-switch', '/accounts/subscriptions/plans-switch'],
  },

  {
    name: SETTINGS_OLD_PLANS_ROUTE,
    path: '/accounts/subscriptions/plans-old',
    component: () => import('@/features/subscription/pages/PlansSettingsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Plans'),
      permissions: ['manage_subscriptions'],
      parent: 'account-plans',
    },
    alias: ['/settings/subscriptions/plans-old', '/accounts/subscriptions/plans-old'],
  },
] satisfies RouteRecordRaw[]
