import type { RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

export const SHIPPING_DUPLICATE_ROUTE = 'parcels.parcel.duplicate'
export const SHIPPING_NEW_SHIPMENT_ROUTE = 'parcels.new'

export default [
  {
    name: SHIPPING_NEW_SHIPMENT_ROUTE,
    path: '/shipping/new',
    component: () => import('@/app/parcels/views/NewShipmentView.vue'),
    meta: {
      pageTitle: () => i18n.t('Create new shipment'),
      permissions: ['manage_shipping'],
      parent: 'shipping-orders',
    },
  },

  {
    name: SHIPPING_DUPLICATE_ROUTE,
    path: '/shipping/:id/duplicate',
    component: () => import('@/app/parcels/views/DuplicateShipmentView.vue'),
    props: (route: RouteRecordRaw & { params: { id: string } }): { parcelId: number } => ({
      parcelId: Number.parseInt(route.params.id),
    }),
    meta: {
      pageTitle: () => i18n.t('Duplicate shipment'),
      permissions: ['manage_shipping'],
      parent: 'shipping-orders',
    },
  },
] satisfies RouteRecordRaw[]
