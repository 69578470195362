import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import store from '@/common/stores/store'
import ToastService from '@/common/services/toast.service'

import i18n from '@/application/i18n/i18n'
import { FALLBACK_ROUTE } from '@/application/routes/fallback-route'

import type { UserPermissions } from '@/types/models'

/**
 * Check route permissions to see if the user permissions are sufficient to enter that route.
 */
function hasPermission(routePermissions: Array<keyof UserPermissions>, userPermissions: UserPermissions): boolean {
  if (routePermissions.length === 0) {
    return true
  }

  return routePermissions.some(permission => userPermissions[permission])
}

function userRoleRouteGuard(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
  const routePermissions = to.meta?.permissions ?? []
  const userPermissions = store?.getters?.user?.permissions ?? {}
  if (hasPermission(routePermissions, userPermissions)) {
    next()
  } else {
    ToastService.warning(
      i18n.t(
        'Your user role does not give you access to this feature. Please contact your administrator for more information.',
      ),
      { timeout: 10000 },
    )
    next({ name: FALLBACK_ROUTE })
  }
}

export default userRoleRouteGuard
