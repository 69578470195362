import type { ContractFields, Contract, Contract_V2 } from '@/features/carriers/types/contract.types'

import axios from '@/common/utils/axios'

export default {
  QUERY_PARAM_SYNC_VALIDATION: 'sync_validation',

  async find(id: number): Promise<{ data: Contract }> {
    return axios.get(`/xhr/contracts/${id}`)
  },

  async findAll(): Promise<{ data: Contract[] }> {
    return axios.get('/xhr/contracts')
  },

  /**
   * Retrieves information about all the available contracts the user has in the SC account together with broker and
   * sub-broker contracts.
   */
  async fetchAll_v2(onlyActive = true): Promise<Contract_V2[]> {
    const params = onlyActive ? { is_active: true } : undefined
    return axios
      .get('/api/v2/contracts', { params })
      .then(({ data: { contracts } }) => contracts)
      .catch(() => [])
  },

  async create(payload: { carrier: number, fields: ContractFields }): Promise<{ data: Contract }> {
    return axios.post('/xhr/contracts', payload)
  },

  // eslint-disable-next-line @stylistic/max-len
  async update(id: number, payload: { is_active: boolean } | { fields: ContractFields }, shouldValidate: boolean = false, patch: boolean = false): Promise<{ data: Contract }> {
    const options = shouldValidate ? { params: { [this.QUERY_PARAM_SYNC_VALIDATION]: true } } : undefined
    if (patch) {
      return axios.patch(`/xhr/contracts/${id}`, payload, options)
    }
    return axios.put(`/xhr/contracts/${id}`, payload, options)
  },

  async destroy(id: number): Promise<void> {
    await axios.delete(`/xhr/contracts/${id}`)
  },

  async uploadContractPrices(id: number, file: File): Promise<void> {
    const payload = new FormData()
    payload.append('file', file)

    return axios.post(`/api/v2/contracts/${id}/pricing`, payload)
  },

  async register(payload: { carrier: number }): Promise<{ data: Contract }> {
    return axios.post(`/xhr/contracts/${payload.carrier}/register`, payload)
  },
}
