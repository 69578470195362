const RE_NEXT = /<(\S+?)>; rel="next"/
const RE_PREV = /<(\S+?)>; rel="prev"/

type PaginationLinks = { next: string | null, prev: string | null }

/**
 * Extract the pagination links (next, prev) from the Link header.
 * See https://sendcloud.atlassian.net/wiki/spaces/ADR/pages/1528398619/API+Pagination+Info for more information
 */
export function getPaginationLinks(linkHeader?: string): PaginationLinks {
  if (!linkHeader) {
    return {
      next: null,
      prev: null,
    }
  }

  return {
    next: RE_NEXT.exec(linkHeader)?.[1] ?? null,
    prev: RE_PREV.exec(linkHeader)?.[1] ?? null,
  }
}
