import i18n from '@/application/i18n/i18n'
import ToastService from '@/common/services/toast.service'
import { getAbsoluteBackendURL } from '@/common/utils/backend'
import { logError } from '@/common/utils/errors/error-handlers'
import mockProxy from '@/features/opportunity-center/mocks/mocker'
import type { ActionResponse, OpportunityCenterResponse } from '@/features/opportunity-center/types/opportunity-center.types'
import axiosRetry from '@/features/opportunity-center/utils/axiosRetry'

const useMockService = import.meta.env.VUE_APP_USE_OPPO_MOCK_SERVICE === 'yes'

/**
 * Generate the base URL from env variable.
 */
const baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL as string)

/**
 * Endpoint to get the opportunity center data for a user.
 */
export const fetchAllOpportunities = async (): Promise<OpportunityCenterResponse | null> => {
  if (useMockService) {
    return new Promise((resolve) => {
      mockProxy['GET /opportunity-center/opportunities'](null, {
        json: (data: OpportunityCenterResponse) => resolve(data),
      })
    })
  }
  try {
    const response = await axiosRetry.get<OpportunityCenterResponse>(`${baseUrl}/opportunity-center/opportunities`)
    return response.data
  } catch (error: unknown) {
    ToastService.error(i18n.t('There was a problem with your request, try again later'))
    logError(error)
    return null
  }
}

/**
 * Mark an opportunity action as completed
 */
export const completeAction = async (action: ActionResponse): Promise<void> => {
  await axiosRetry.patch<void>(`${baseUrl}/opportunity-center/actions/${action.codename}/complete`, { status: 'completed' })
}
