<template>
  <UiModal
    :is-open="isModalOpen"
    :has-close-button="resultInfo.resultCode !== RESULT_CODES.TECHNICAL_ERROR"
    :hide-title="title === '' ? true : false"
    :esc-emits-close-event="resultInfo.resultCode !== RESULT_CODES.TECHNICAL_ERROR"
    :outside-click-emits-close-event="resultInfo.resultCode !== RESULT_CODES.TECHNICAL_ERROR"
    @close="closeModal"
  >
    <template #title>
      <span class="ui-margin-12--right">
        <Fa
          v-if="isNonTechnicalError"
          class="app-payment-result-modal__error-icon"
          :icon="$options.faExclamationTriangle"
        />
        <Fa
          v-else-if="resultInfo.resultCode === RESULT_CODES.AUTHORISED"
          class="app-payment-result-modal__success-icon"
          :icon="$options.faCheckCircle"
        />
        <Fa
          v-else
          class="app-payment-result-modal__info-icon"
          :icon="$options.faInfoCircle"
        />
      </span>
      <span>
        {{ title }}
      </span>
    </template>

    <section v-if="resultInfo.resultCode === RESULT_CODES.TECHNICAL_ERROR">
      <div class="ui-text:center ui-margin-16--top">
        <img
          class="app-payment-result-modal__image"
          src="@/application/assets/img/payment-technical-error.svg"
          :alt="$t('Technical error image')"
        >

        <h3>{{ $t('Oops something went wrong') }}</h3>
        <p class="app-payment-result-modal__info">
          {{ $t('The payment provider is unresponsive or another error response occurred') }}
        </p>

        <button
          type="button"
          class="ui-button ui-button--primary ui-margin-12--top ui-margin-16--bottom"
          @click="closeModal"
        >
          {{ $t('Try again later') }}
        </button>
      </div>
    </section>

    <section v-else>
      <p class="app-payment-result-modal__info">
        {{ extraInfo }}
      </p>

      <button
        class="ui-button ui-button--primary ui-margin-12--top app-payment-result-modal__button"
        type="button"
        @click="closeModal"
      >
        {{ $t('Ok') }}
      </button>
    </section>
  </UiModal>
</template>

<script>
import Fa from 'vue-fa'
import { faCheckCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { UiModal } from '@sendcloud/ui-library'
import { mapGetters } from 'vuex'
import { RETURN_SETTINGS_ONBOARDING } from '@/features/return-portal/constants'
import { SETTINGS_RETURNS_ROUTE } from '@/application/routes/settings.routes.names'
import Segment from '@/common/utils/tracking/segment'

import i18n from '@/application/i18n/i18n'
import AdyenPaymentService from '@/features/payment-wall/services/adyen-payment.service.js'
import { CORE_DISPLAY_PAYMENT_MODAL } from '@/common/stores/core/action.types'
import EventBus from '@/common/utils/event-bus'

const EXTRA_INFO_MAP = {
  [AdyenPaymentService.RESULT_CODES.AUTHORISED]: i18n.t('Your payment was successful!'),
  [AdyenPaymentService.RESULT_CODES.CANCELLED]: i18n.t('Your payment was interrupted, please try again'),
  [AdyenPaymentService.RESULT_CODES.ERROR]: i18n.t(
    'An error occurred while processing your payment, please try again later',
  ),
  [AdyenPaymentService.RESULT_CODES.REFUSED]: i18n.t('The transaction was refused by your bank'),
  [AdyenPaymentService.RESULT_CODES.PENDING]: i18n.t('Your payment is being processed'),
  [AdyenPaymentService.RESULT_CODES.RECEIVED]: i18n.t('Your payment is being processed'),
}

const TITLES_MAP = {
  [AdyenPaymentService.RESULT_CODES.AUTHORISED]: i18n.t('Payment successful'),
  [AdyenPaymentService.RESULT_CODES.CANCELLED]: i18n.t('Payment cancelled'),
  [AdyenPaymentService.RESULT_CODES.ERROR]: i18n.t('An error occurred'),
  [AdyenPaymentService.RESULT_CODES.REFUSED]: i18n.t('Payment declined'),
  [AdyenPaymentService.RESULT_CODES.PENDING]: i18n.t('Payment in progress'),
  [AdyenPaymentService.RESULT_CODES.RECEIVED]: i18n.t('Payment in progress'),
}

export default {
  name: 'AppPaymentResultModal',

  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,

  components: {
    Fa,
    UiModal,
  },

  props: {
    resultInfo: {
      type: Object,
      default: () => ({
        resultCode: '',
        isRedirect: false,
      }),
      required: true,
    },
  },

  emits: ['close'],

  data() {
    return {
      RESULT_CODES: AdyenPaymentService.RESULT_CODES,
      INVOICE_REF_TYPES: AdyenPaymentService.PAYMENT_INVOICE_REF_TYPE,
    }
  },

  computed: {
    ...mapGetters(['paymentInvoiceId', 'user']),

    extraInfo() {
      return EXTRA_INFO_MAP[this.resultInfo.resultCode]
    },

    isNonTechnicalError() {
      return (
        this.resultInfo.resultCode === this.RESULT_CODES.ERROR ||
        this.resultInfo.resultCode === this.RESULT_CODES.REFUSED ||
        this.resultInfo.resultCode === this.RESULT_CODES.CANCELLED
      )
    },

    title() {
      return TITLES_MAP[this.resultInfo.resultCode] || ''
    },

    isModalOpen() {
      return Boolean(this.resultInfo?.resultCode)
    },
  },

  methods: {
    /**
     * Clears the current invoice ID, emits the error event for the payment modal, and triggers closing the modal.
     *
     * Until the beta-testing period is over, the error event cannot be emitted from the payment modal.
     * The reason is that the user still has the possibility to use the old implementation.
     */
    closeModal() {
      const isError = this.resultInfo.resultCode === this.RESULT_CODES.TECHNICAL_ERROR || this.isNonTechnicalError
      const invoiceId = this.paymentInvoiceId || undefined
      if (isError && !!invoiceId) {
        EventBus.$emit('payment-modal-error', invoiceId)
      }

      const hasStatusChanged =
        this.resultInfo.resultCode === this.RESULT_CODES.AUTHORISED ||
        this.resultInfo.resultCode === this.RESULT_CODES.PENDING ||
        this.resultInfo.resultCode === this.RESULT_CODES.RECEIVED

      if (this.$route.query?.onboarding === RETURN_SETTINGS_ONBOARDING) {
        Segment.track(
          'Return portal onboarding - enable direct debit completed',
          {
            user_id: this.user.id,
            country: this.user.invoice_country_iso_2,
            subscription_type: this.user.new_plan_group,
          },
          { integrations: { All: true, Mixpanel: true } },
        )
        this.$router.push({ name: SETTINGS_RETURNS_ROUTE, query: { onboarding: RETURN_SETTINGS_ONBOARDING } })
        this.$emit('close')
      } else if (this.resultInfo.isRedirect && hasStatusChanged) {
        window.location.reload()
      } else {
        this.$store.dispatch(CORE_DISPLAY_PAYMENT_MODAL, this.INVOICE_REF_TYPES.NONE)
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
.app-payment-result-modal__info-icon {
  color: $sc-informative;
}

.app-payment-result-modal__error-icon {
  color: $sc-danger;
}

.app-payment-result-modal__success-icon {
  color: $sc-positive;
}

.app-payment-result-modal__button {
  width: 100%;
}

.app-payment-result-modal__fallback-notification svg {
  margin-top: 0 !important;
  display: flex;
  align-self: center;
}

.app-payment-result-modal__fallback-notification-content {
  display: flex;
  align-items: center;
}

.app-payment-result-modal__fallback-notification-message {
  flex-grow: 1;
}

.app-payment-result-modal__image {
  width: 50%;
}

.app-payment-result-modal__info {
  font-size: 16px;
}
</style>
