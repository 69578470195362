import type { Box, BoxCreated } from '@/features/boxes/types/boxes.types'

import axios from '@/common/utils/axios'

export default {
  create(box: BoxCreated): Promise<{ data: Box }> {
    return axios.post('/orders/xhr/box-manager', box)
  },

  delete(boxId: number): Promise<void> {
    return axios.delete(`/orders/xhr/box-manager/${boxId}`)
  },

  update(box: Box): Promise<{ data: Box }> {
    return axios.put(`/orders/xhr/box-manager/${box.id}`, box)
  },

  findAll(): Promise<{ data: Box[] }> {
    return axios.get('/orders/xhr/box-manager')
  },
}
