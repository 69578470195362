import { fetchNotificationSummary } from '@/features/notifications/api/notifications.api'
import poll from '@/common/utils/poll'

import store from '@/common/stores/store'
import { NOTIFICATIONS_UPDATE_NOTIFICATION_COUNT } from '@/features/notifications/stores/notifications/action.types'
import { NEW_NOTIFICATIONS_BETA } from '@/features/support/beta-features'

const pollIntervalInMinutes = 10

function startPollingForNotifications() {
  if (store.getters.hasBetaFeature(NEW_NOTIFICATIONS_BETA)) {
    return
  }
  poll(fetchNotificationUpdates, pollIntervalInMinutes * 60 * 1000)
}

async function fetchNotificationUpdates() {
  try {
    const notificationSummary = await fetchNotificationSummary()
    store.dispatch(NOTIFICATIONS_UPDATE_NOTIFICATION_COUNT, notificationSummary.unread_count)
  } catch {
    // Explicitly ignore; otherwise, Sentry is flooded with false-positives
  }
}

export default startPollingForNotifications
