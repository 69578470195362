import axios from 'axios'

import ModalService from '@/common/services/modal.service'

import { getAbsoluteBackendURL } from '@/common/utils/backend'

export function createAxios() {
  const client = axios.create({
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: getAbsoluteBackendURL(''),
    // withCredentials, because app.sendcloud.com ->
    // eu-central-1-0.app.sendcloud.com, or localhost:8082 -> localhost:3030
    // crosses origins.
    withCredentials: true,
  })

  client.interceptors.response.use(
    response => response,
    (error) => {
      // When the session cookie has expired or deleted
      // NOAUTH will be true in the error response
      if (error.response?.data?.NOAUTH) {
        ModalService.build('SessionTimeoutModal')
      }
      return Promise.reject(error)
    },
  )

  return client
}

const globalAxiosClient = createAxios()

// For interacting with the print client, we cannot use the global axios client,
// as interacting with the print client should not include
// `withCredentials`. Also, CSRF cookies are not relevant. Therefore, we keep a
// specific axios client around for printing.
export const printAxiosClient = axios.create({})

// Same remark as with the `printAxiosClient`.
export const instagramAxiosClient = axios.create({})

// Same remark as with the `printAxiosClient`.
export const statusPageAxiosClient = axios.create({})

export default globalAxiosClient

export const cancelTokenFactory = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export const isCancel = axios.isCancel
