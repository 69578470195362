import { RouterView, type RouteRecordRaw } from 'vue-router'

import i18n from '@/application/i18n/i18n'

import boxesRouteGuard from '@/features/boxes/guards/boxes-route-guard'

export const SETTINGS_BOXES_ROUTE = 'boxes'
export const SETTINGS_BOXES_LIST_ROUTE = 'boxes.list'
export const SETTINGS_BOXES_ADD_ROUTE = 'boxes.add'
export const SETTINGS_BOXES_EDIT_ROUTE = 'boxes.edit'

export default {
  name: SETTINGS_BOXES_ROUTE,
  path: '/shipping/boxes',
  redirect: { name: SETTINGS_BOXES_LIST_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_BOXES_LIST_ROUTE,
      path: '/shipping/boxes/list',
      component: () => import('@/features/boxes/pages/BoxSettingsPage.vue'),
      beforeEnter: boxesRouteGuard,
      meta: {
        pageTitle: () => i18n.t('Boxes'),
        permissions: [
          /* Empty -- listing is just fine. */
        ],
        parent: 'shipping-boxes',
      },
      alias: ['/settings/boxes/list', '/shipping/boxes/list'],
    },
    {
      name: SETTINGS_BOXES_ADD_ROUTE,
      path: '/shipping/boxes/add',
      component: () => import('@/features/boxes/pages/BoxFormPage.vue'),
      beforeEnter: boxesRouteGuard,
      meta: {
        pageTitle: () => i18n.t('Add box'),
        permissions: ['configure_packing'],
      },
      alias: ['/settings/boxes/add', '/shipping/boxes/add'],
    },
    {
      name: SETTINGS_BOXES_EDIT_ROUTE,
      path: '/shipping/boxes/:boxId',
      component: () => import('@/features/boxes/pages/BoxFormPage.vue'),
      beforeEnter: boxesRouteGuard,
      props: (route: RouteRecordRaw & { params: { boxId: string } }) => ({
        boxId: Number.parseInt(route.params.boxId),
      }),
      meta: {
        pageTitle: () => i18n.t('Edit box'),
        permissions: ['configure_packing'],
      },
      alias: ['/settings/boxes/:boxId', '/shipping/boxes/:boxId'],
    },
  ],
} satisfies RouteRecordRaw
