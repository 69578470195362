import type { RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'
import { opportunityCenterRouteGuard } from '@/features/opportunity-center/guards/opportunity-center-guards'

export const OPPORTUNITY_CENTER_ROUTE = 'opportunity.center'

export default {
  name: OPPORTUNITY_CENTER_ROUTE,
  path: '/opportunity-center',
  beforeEnter: opportunityCenterRouteGuard,
  component: () => import('@/features/opportunity-center/pages/OpportunityPage.vue'),
  meta: {
    pageTitle: () => i18n.t('Your details'),
    permissions: ['manage_account'],
    parent: 'discover-sendcloud',
  },
} satisfies RouteRecordRaw
