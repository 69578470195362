import InvoiceAddressService from '@/features/addresses/api/invoice-address.api.js'
import {
  INVOICE_ADDRESS_FETCH_ADDRESSES,
  INVOICE_ADDRESS_UPDATE_ADDRESS,
  INVOICE_ADDRESS_FETCH_ADDRESS,
} from './action.types.js'
import { INVOICE_ADDRESS_SET_ADDRESS, INVOICE_ADDRESS_SET_ADDRESSES } from './mutation.types.js'

const state = {
  address: {},
  invoiceAddresses: [],
}

const getters = {
  address: state => state.address,

  invoiceAddresses: state => state.invoiceAddresses,
}

const actions = {
  async [INVOICE_ADDRESS_FETCH_ADDRESSES]({ commit }) {
    const response = await InvoiceAddressService.findAll()
    commit(INVOICE_ADDRESS_SET_ADDRESSES, response.data)
  },

  async [INVOICE_ADDRESS_FETCH_ADDRESS]({ commit }) {
    const response = await InvoiceAddressService.findAll()
    const address = response.data && response.data.length > 0 ? response.data[0] : {}
    commit(INVOICE_ADDRESS_SET_ADDRESS, address)
  },

  async [INVOICE_ADDRESS_UPDATE_ADDRESS]({ dispatch }, address) {
    await InvoiceAddressService.update(address)
    await dispatch(INVOICE_ADDRESS_FETCH_ADDRESSES)
  },
}

const mutations = {
  [INVOICE_ADDRESS_SET_ADDRESSES](state, addresses) {
    state.invoiceAddresses = addresses
  },

  [INVOICE_ADDRESS_SET_ADDRESS](state, address) {
    state.address = address
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
