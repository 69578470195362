import store from '@/common/stores/store'
import { SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE } from '@/features/tracking-messages/routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export const isStaffGuard = () => (
  _to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext,
) => {
  if (store.getters.user?.is_staff) {
    next()
  } else {
    next({ name: SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE })
  }
}
