import i18n from '@/application/i18n/i18n'
import { isStaffGuard } from '@/features/tracking-messages-v2/guards/is-staff-guard'
import { btnHelpCenterMessagesV2 } from '@/features/tracking-messages/routes'
import type { RouteRecordRaw } from 'vue-router'

export const SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_ROUTE = 'notify.tracking-messages-v2'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_OVERVIEW_ROUTE = 'notify.tracking-messages-v2.overview'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_SETTINGS_ROUTE = 'notify.tracking-messages-v2.settings'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_EMAIL_ROUTE = 'notify.tracking-messages-v2.email'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_SMS_ROUTE = 'notify.tracking-messages-v2.sms'

export default {
  name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_ROUTE,
  path: '/tracking/messages-v2',
  component: () => import('@/features/tracking-messages-v2/pages/TrackingMessagesWrapperPage.vue'),
  redirect: { name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_OVERVIEW_ROUTE },
  beforeEnter: isStaffGuard(),
  meta: {
    pageTitle: () => i18n.t('Tracking messages'),
    permissions: ['configure_tracking'],
    buttons: [btnHelpCenterMessagesV2],
  },
  children: [
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_OVERVIEW_ROUTE,
      path: '/tracking/overview',
      component: () => import('@/features/tracking-messages-v2/pages/TrackingMessagesOverviewPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
      },
    },
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_SETTINGS_ROUTE,
      path: '/tracking/settings',
      component: () => import('@/features/tracking-messages-v2/pages/TrackingMessagesSettingsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
      },
    },
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_EMAIL_ROUTE,
      path: '/tracking/:notificationId/:countryCode/email',
      props: true,
      component: () => import('@/features/tracking-messages-v2/pages/TrackingEmailPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
        backLinkRoute: () => {
          return {
            title: i18n.t('settings.tracking.messages.return_to_overview'),
            to: { name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_OVERVIEW_ROUTE },
          }
        },
      },
    },
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_SMS_ROUTE,
      path: '/tracking/:notificationId/:countryCode/sms',
      props: true,
      component: () => import('@/features/tracking-messages-v2/pages/TrackingSMSPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
        backLinkRoute: () => {
          return {
            title: i18n.t('settings.tracking.messages.return_to_overview'),
            to: { name: SETTINGS_NOTIFY_TRACKING_MESSAGES_V2_OVERVIEW_ROUTE },
          }
        },
      },
    },
  ],
} satisfies RouteRecordRaw
