import type { User } from '@/types/models'
import type { HubspotQueue } from '@/common/types/window.types'
import { getVariable, LOCAL_STORAGE_KEYS, setVariable } from '@/common/utils/storage'
import { sessionId } from '@/application/utils/session-id'

let _hsq: HubspotQueue | null = null

export function shouldTriggerHubspot(user: User) {
  const isMainUser = user.id === user.corporation.id && !user.is_staff // not sub-user and staff
  const canTriggerHubspot = sessionId() !== getVariable(user.id, LOCAL_STORAGE_KEYS.IS_HUBSPOT_TRIGGERED)
  return isMainUser && canTriggerHubspot
}

export function initializeHubspot(user: User) {
  // Don't load this script if it's not needed.
  if (!shouldTriggerHubspot(user)) {
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.async = true
  scriptElement.defer = true
  scriptElement.id = 'hs-script-loader'
  scriptElement.src = 'https://js-eu1.hs-scripts.com/4114841.js'

  const firstScript = document.getElementsByTagName('script')[0]
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(scriptElement, firstScript)
  }

  scriptElement.onload = () => {
    _hsq = window._hsq = window._hsq || []
  }
}

export function hsqTrackPage(email: string, userId: number) {
  if (_hsq) {
    _hsq.push(['identify', { email }])
    _hsq.push(['trackPageView'])
    setVariable(userId, LOCAL_STORAGE_KEYS.IS_HUBSPOT_TRIGGERED, sessionId())
  }
}
