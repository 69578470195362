import axios from '@/common/utils/axios'
import { strictParse } from '@/common/utils/parsing-utils/strict-parse'
import {
  UnreadMessagesCounterResponse,
  ChatsPaginatedResponse,
  MessagesPaginatedResponse,
  WhatsappMessage,
  FileInfo,
  type SendMessagePayload,
} from '../types/conversations-data-parsers'
import type { AxiosRequestConfig } from 'axios'
// TODO: temp, uncomment for testing
// import { whatsappChatsFactory, whatsappMessagesFactory } from '../fixtures/whatsapp-conversations.fixture'

/**
 * Will return a number from 0 to 100
 * (for performance reasons 100 is the highest number, since we only display 99+ for this counter)
 */
export const fetchUnreadMessagesCount = async () => {
  const { data } = await axios.get<UnreadMessagesCounterResponse>('/xhr/notify/whatsapp/messages/unread')
  const { count } = strictParse(data, UnreadMessagesCounterResponse, 'fetchUnreadMessagesCount')
  return count
}

type FetchChatsPayload = {
  cursor: string | null
  onlyUnread: boolean
}

// TODO: temp, uncomment for testing
// let maxMessageCalls = 5
// let maxChatCalls = 5

export const fetchChats = async (
  { cursor, onlyUnread }: FetchChatsPayload,
  config?: AxiosRequestConfig<FetchChatsPayload>,
): Promise<ChatsPaginatedResponse> => {
  // TODO: temp, uncomment for testing
  // await new Promise(res => setTimeout(() => res(null), 100))
  // const chatsFix = whatsappChatsFactory()
  // return {
  //   data: chatsFix,
  //   next: --maxChatCalls ? 'next-cursor' : null,
  //   previous: --maxChatCalls ? 'prev-cursor' : null,
  // }

  const url = cursor || '/xhr/notify/whatsapp/chats'
  const params = !cursor && onlyUnread ? { unread_only: true } : undefined

  const response = await axios.get<ChatsPaginatedResponse>(url, {
    params,
    ...config,
    // cursor link already contains baseUrl
    ...(cursor && { baseURL: '' }),
  })

  const { data, next, previous } = strictParse(response.data, ChatsPaginatedResponse, 'fetchWhatsAppChatsPage')
  return { data, next, previous }
}

type FetchMessagesPayload = {
  chatId: string
  cursor: string | null
}
export const fetchMessages = async (
  { chatId, cursor }: FetchMessagesPayload,
  config?: AxiosRequestConfig<FetchMessagesPayload>,
): Promise<MessagesPaginatedResponse> => {
  // TODO: temp: uncomment for testing
  // await new Promise(res => setTimeout(() => res(null), 100))
  // const messagesFix = whatsappMessagesFactory(20)
  // return {
  //   data: messagesFix,
  //   next: --maxMessageCalls ? 'next-cursor' : null,
  //   previous: --maxMessageCalls ? 'prev-cursor' : null,
  // }

  const url = cursor || `/xhr/notify/whatsapp/chats/${chatId}/messages`

  const response = await axios.get<MessagesPaginatedResponse>(url, {
    ...config,
    // cursor link already contains baseUrl
    ...(cursor && { baseURL: '' }),
  })

  const { data, next, previous } = strictParse(response.data, MessagesPaginatedResponse, 'fetchWhatsAppMessagesPage')
  return { data, next, previous }
}

export const sendMessage = async (
  { chatId, payload }: SendMessagePayload,
): Promise<WhatsappMessage> => {
  // TODO: temp: uncomment for testing
  // await new Promise(res => setTimeout(() => res(null), 100))
  // const newMesageFix = whatsappMessagesFactory(1)
  // newMesageFix[0].text = payload.type === 'text' ? payload.text ?? '' : ''
  // newMesageFix[0] = {
  //   ...newMesageFix[0],
  //   direction: 'outbound',
  //   sent_at: Date.now().toString(),
  //   calls_to_actions: [],
  //   media: payload.type === 'text'
  //     ? []
  //     : [{
  //         url: 'http://localhost:8082',
  //         type: 'document',
  //         mime_type: payload.mime_type ?? '',
  //         caption: null,
  //         filename: payload.filename ?? null,
  //       }],
  // }
  // return newMesageFix[0]

  const { data } = await axios.post<{ data: WhatsappMessage }>(`/xhr/notify/whatsapp/chats/${chatId}/messages`, { ...payload })

  return strictParse(data.data, WhatsappMessage, 'sendMessage')
}

type UploadMediaPayload = {
  chatId: string
  file: File
}
export const uploadWhatsappMedia = async ({ chatId, file }: UploadMediaPayload): Promise<FileInfo> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await axios.post<{ data: FileInfo }>(`/xhr/notify/whatsapp/chats/${chatId}/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return strictParse(data.data, FileInfo, 'uploadWhatsappMedia')
}
