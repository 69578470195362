<template>
  <li class="aside-navigation__item">
    <a
      v-if="typeof href === 'string' && href.startsWith('http')"
      :id="id"
      :class="{ 'aside-navigation__item-link--active': linkIsActive}"
      :data-test="id"
      :href="href"
      :target="target"
      :rel="rel"
      :data-smoke-test="id?.replace('smoke-test-', '')"
      class="aside-navigation__item-link"
      @click="NavigationAnalytics.trackNavigationMenuItemClick(params)"
    >
      <Fa
        v-if="icon"
        :icon="icon"
        class="aside-navigation__item-icon"
      />
      <slot />

    </a>

    <router-link
      v-else
      :id="id"
      :class="{ 'aside-navigation__item-link--active': linkIsActive}"
      :data-test="id"
      :to="href"
      class="aside-navigation__item-link"
      @click="NavigationAnalytics.trackNavigationMenuItemClick(params)"
    >
      <Fa
        v-if="icon"
        :icon="icon"
        class="aside-navigation__item-icon"
      />
      <slot />
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { computed, inject, type Ref } from 'vue'
import { useRoute } from 'vue-router'
import Fa from 'vue-fa'
import { type IconDefinition } from '@fortawesome/pro-regular-svg-icons'

import NavigationAnalytics from '@/application/utils/navigation.analytics'
import type { NavigationItemTrackingData } from '@/application/types/navigation-tracking.types'

const props = defineProps<{
  href: string | { name: string }
  parentSection?: string
  icon?: IconDefinition
  id?: string
  target?: string
  rel?: string
}>()

const route = useRoute()

const linkIsActive = computed(() => {
  const parentPath = route.meta.parent as string
  return props.id ? parentPath?.includes(props.id) : false
})

const isExpanded = inject<Ref<boolean>>('isExpanded')
const isMobileMenuVisible = inject<Ref<boolean>>('isMobileMenuVisible')

const params = computed<NavigationItemTrackingData>(() => ({
  name: props.id || '',
  parentSection: props.parentSection,
  navigationMenuIsExpanded: isExpanded?.value || false,
  navigationIsTabletOrMobile: isMobileMenuVisible?.value || false,
}))

</script>
