import { parse, type Parser } from '@/common/data-parsers'
import { logError, showFullscreenErrorOverlay } from '@/common/utils/errors/error-handlers'

export const strictParse = <T>(
  data: unknown,
  parser: Parser<T>,
  inContextOf: string,
  { isEnabledInProd = true } = {},
) => {
  // we will remove this later
  if (!isEnabledInProd && process.env.NODE_ENV !== 'development') {
    return data as T
  }

  return parse(data, parser, inContextOf, (errorMsg) => {
    const error = new Error(errorMsg)
    if (['test', 'development'].includes(process.env.NODE_ENV || '')) {
      // eslint-disable-next-line no-console
      console.error(errorMsg)
      process.env.NODE_ENV === 'development' && showFullscreenErrorOverlay(error)
      throw error
    }
    logError(error)
  })
}
