export type OpportunityAnalyticsButtonAction = 'Return to Discover Sendcloud' | 'Close Discover Sendcloud' | `${string}:${string}:${string}`

// Enums
export enum STATE {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum ACTION_RULE {
  ALL = 'all',
  ANY = 'any',
}

// Base types (Mixins)
export type StampedMixin = {
  created_at?: Date | null
  updated_at?: Date | null
}

// State-related types
export type State = StampedMixin & {
  id?: number | null
  name: STATE
}

export type StateMixin = {
  state_id: number
  state?: State | null
}

// Opportunity-related types
export type Opportunity = StampedMixin & {
  id?: number | null
  codename: string
  title: string
  description: string
  actions_rule: ACTION_RULE
  is_actionable?: boolean
  order: number
  state: STATE
  topics?: Topic[] | null
  actions?: Action[] | null
  dependents?: Opportunity[] | null
  dependencies?: Opportunity[] | null
}

export type OpportunityMixin = {
  opportunity_id?: number | null
  opportunity?: Opportunity | null
}

// Topic-related types
export type Topic = StampedMixin & {
  id?: number | null
  codename: string
  name: string
  description: string
  order: number
  opportunities?: Opportunity[] | null
}

// Action-related types
export type Action = OpportunityMixin & StampedMixin & {
  id?: number | null
  codename: string
  event_name: string
  destination_url: string
  help_url: string
  name: string
  description: string
  is_link?: boolean
  required_time?: number
  order: number
}

// User-related types
export type User = {
  user_id: number
  user_topics?: UserTopic[] | null
  user_opportunities?: UserOpportunity[] | null
}

export type UserMixin = {
  user_id?: number | null
  user?: User | null
}

// UserTopic and UserOpportunity types
export type UserTopic = UserMixin & StateMixin & StampedMixin & {
  id?: number | null
  topic_id: number
  completed_opportunities?: number[] | null
  expected_opportunities?: number[] | null
  user_opportunities?: UserOpportunity[] | null
  topic?: Topic | null
}

export type UserOpportunity = UserMixin &
  OpportunityMixin &
  StateMixin &
  StampedMixin & {
    id?: number | null
    completed_actions?: number[] | null
    expected_actions?: number[] | null
    is_actionable?: boolean
    completed_dependencies?: number[] | null
    expected_dependencies?: number[] | null
    user_actions?: UserAction[] | null
    user_topics?: UserTopic[] | null
    dependents?: UserOpportunity[] | null
    dependencies?: UserOpportunity[] | null
  }

// UserAction type
export type UserAction = UserMixin & StateMixin & StampedMixin & {
  id?: number | null
  action_id: number
  user_opportunity_id?: number | null
  action?: Action | null
  user_opportunity?: UserOpportunity | null
}

// Response types
export type OpportunityCenterResponse = {
  topics: TopicResponse[]
}

export type TopicResponse = {
  codename: string
  name: string
  description: string
  order: number
  state: string
  opportunities: OpportunityResponse[]
}

export type OpportunityResponse = {
  codename: string
  title: string
  description: string
  order: number
  actions_rule: string
  is_actionable: boolean
  state: STATE
  actions: ActionResponse[]
  depends_on: string[]
}

export type ActionResponse = {
  codename: string
  destination_url: string
  help_url?: string
  name: string
  description: string
  is_link: boolean
  required_time?: number
  order: number
  state: STATE
}
