import type { ParcelDirection, ShippingMethodChargingType } from '@/types/shipping.models'
import { helpers } from '@vuelidate/validators'

export const DIRECTION_INCOMING: ParcelDirection = 'i'
export const DIRECTION_OUTGOING: ParcelDirection = 'o'
export const DIRECTION_FOLLOW_UP: ParcelDirection = 'f'

export const CHARGED_ON_SCAN: ShippingMethodChargingType = 'first_scan'
export const CHARGED_ON_CREATE: ShippingMethodChargingType = 'label_creation'

export const PARCEL_STATUS_ID = {
  READY_TO_SEND: 1000,
  ANNOUNCED: 1,
  TO_SORTING: 3,
  DELAYED: 4,
  SORTED: 5,
  UNSORTED: 6,
  SORTING: 7,
  DELIVERY_FAILED: 8,
  DELIVERY_FORCED: 10,
  DELIVERED: 11,
  AWAITING_CUSTOMER_PICKUP: 12,
  ANNOUNCED_UNCOLLECTED: 13,
  COLLECT_ERROR: 15,
  UNSORTED2: 18,
  UNDELIVERABLE: 80,
  SHIPMENT_ON_ROUTE: 91,
  DRIVER_ON_ROUTE: 92,
  PICKED_UP_BY_DRIVER: 22,
  COLLECTED_BY_CUSTOMER: 93,
  NO_LABEL: 999,
  ANNOUNCING: 1001,
  CANCELLING_UPSTREAM: 2001,
  CANCELLING: 1999,
  CANCELLED: 2000,
  CANCELLED_UPSTREAM: 1998,
  UNKNOWN: 1337,
  ANNOUNCEMENT_FAILED: 1002,
}

export const LETTER_SHIPPING_METHOD_ID = 8

export const SERVICE_POINT_INPUT = {
  NONE: 'none',
  REQUIRED: 'required',
  OPTIONAL: 'optional',
}

export const phoneNumberValidator = helpers.regex(/^[-+ 0-9]*$/)
export const extendedPhoneNumberValidator = helpers.regex(/^[-+ 0-9 /(/)]*$/)
export const mandatoryPrefixPhoneNumberValidator = helpers.regex(/\+[0-9]{5,20}/)

export const LABEL_PRICE_BREAKDOWN_TYPE = {
  LABEL: 'price_without_insurance',
  BASE: 'base_rate',
  INSURANCE: 'insurance_price',
  FUEL_SURCHARGE: 'fuel',
}
