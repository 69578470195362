import type { RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'

export const ONBOARDING_WIZARD_ROUTE = 'onboarding'
export const ONBOARDING_WIZARD_YOUR_DETAILS_ROUTE = 'onboarding.your-details'
export const ONBOARDING_WIZARD_INTEGRATIONS_ROUTE = 'onboarding.integrations'
export const ONBOARDING_WIZARD_CARRIERS_ROUTE = 'onboarding.carriers'
export const ONBOARDING_WIZARD_YOUR_ADDRESS_ROUTE = 'onboarding.your-address'
export const ONBOARDING_WIZARD_PAYMENT_METHOD_ROUTE = 'onboarding.payment-method'
export const ONBOARDING_WIZARD_YOUR_BRAND_ROUTE = 'onboarding.your-brand'

export default {
  name: ONBOARDING_WIZARD_ROUTE,
  path: '/onboarding',
  redirect: { name: ONBOARDING_WIZARD_YOUR_DETAILS_ROUTE },
  component: () => import('@/features/onboarding-wizard/pages/OWPage.vue'),
  children: [
    {
      name: ONBOARDING_WIZARD_YOUR_DETAILS_ROUTE,
      path: '/onboarding/your-details',
      component: () => import('@/features/onboarding-wizard/pages/OWYourDetailsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Your details'),
        permissions: ['manage_account'],
      },
    },
    {
      name: ONBOARDING_WIZARD_INTEGRATIONS_ROUTE,
      path: '/onboarding/integrations',
      component: () => import('@/features/onboarding-wizard/pages/OWIntegrationsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Connect your store'),
        permissions: ['manage_integrations'],
      },
    },
    {
      name: ONBOARDING_WIZARD_CARRIERS_ROUTE,
      path: '/onboarding/carriers',
      component: () => import('@/features/onboarding-wizard/pages/OWCarriersPage.vue'),
      meta: {
        pageTitle: () => i18n.t('onboarding_wizard.carriers.page_title'),
        permissions: ['configure_shipping'],
      },
    },
    {
      name: ONBOARDING_WIZARD_YOUR_ADDRESS_ROUTE,
      path: '/onboarding/your-address',
      component: () => import('@/features/onboarding-wizard/pages/OWAddressPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Add your address'),
        permissions: ['manage_addresses'],
      },
    },
    {
      name: ONBOARDING_WIZARD_PAYMENT_METHOD_ROUTE,
      path: '/onboarding/payment-method',
      component: () => import('@/features/onboarding-wizard/pages/OWPaymentPage.vue'),
      meta: {
        pageTitle: () => i18n.t('onboarding.payment.title'),
        permissions: [],
      },
    },
    {
      name: ONBOARDING_WIZARD_YOUR_BRAND_ROUTE,
      path: '/onboarding/your-brand',
      component: () => import('@/features/onboarding-wizard/pages/OWBrandPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Your brand'),
        permissions: ['manage_brands'],
      },
    },
  ],
} satisfies RouteRecordRaw
