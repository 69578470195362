import { SETTINGS_BRANDS_EDIT_ROUTE } from '@/features/brands/routes'
import i18n from '@/application/i18n/i18n'

import type { RouteLocationNormalizedLoadedGeneric, RouteLocationNormalized } from 'vue-router'
import type { BackLinkRoute } from '@/common/types/route.types'

/**
 * Add UI backlink to the page which called with query parameter containing brand ID
 */

function addBacklinkToBrands(to: RouteLocationNormalized) {
  if (to?.query?.back_to_brand && !isNaN(Number(to.query.back_to_brand))) {
    to.meta.backLinkRoute = (route?: RouteLocationNormalizedLoadedGeneric): BackLinkRoute => {
      return {
        title: i18n.t('settings.brand.come_back_link'),
        to: {
          name: SETTINGS_BRANDS_EDIT_ROUTE,
          params: {
            id: route?.query.back_to_brand as string,
          },
        },
      }
    }
  }
}

export default addBacklinkToBrands
