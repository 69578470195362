import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
const isSentryEnabled = import.meta.env.VUE_APP_SENTRY_ENABLED === 'yes'

export default function startSentryMonitoring(app: App) {
  if (isSentryEnabled) {
    // @sentry/browser uses a public DSN and that can be hardcoded and we manage environments using
    // Sentry Environments[1] feature instead of creating one project per environment.
    // [1] https://docs.sentry.io/product/sentry-basics/environments/
    const dsn = 'https://659c8a33c12041268345692eb07e237e@o421644.ingest.sentry.io/5509352'
    const environment = import.meta.env.VUE_APP_SENTRY_ENVIRONMENT
    const release = import.meta.env.VUE_APP_SENTRY_RELEASE
    const denyUrls = [
      /.+\.appcues\.com/,
      /.+\.google-analytics\.com/,
      /.+\.hotjar\.com/,
      /.+\.intercomcdn\.com/,
      /.+\.statuspage\.io/,

      // Copied from the recommended ignore rules:
      // https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/#decluttering-sentry
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ]

    const ignoreErrors = [
      // The same ResizeObserver's errors for different browsers.
      // More info why we ignore it here:
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Safari browser extensions
      /.*@webkit-masked-url.*/,
      // Errors caused by unsupported browsers:
      'Failed to initialize NumberFormat since used feature is not supported in the linked ICU version',
      "Can't find variable: structuredClone",
      'currencyDisplay must be either "code", "symbol", or "name"',
      'crypto.randomUUID is not a function',
      'structuredClone is not defined',
      'Object.hasOwn is not a function.',
    ]

    Sentry.init({
      app,
      dsn,
      environment,
      release,
      denyUrls,
      logErrors: true,
      ignoreErrors,
      beforeSend(event, hint) {
        if (
          typeof hint.originalException === 'object' &&
          hint.originalException !== null &&
          'stack' in hint.originalException &&
          typeof hint.originalException.stack === 'string'
        ) {
          // Filter out any errors from Google PageAd (usually caused by ad-blockers)
          if (hint.originalException.stack.includes('pagead')) {
            return null
          }
        }
        return event
      },
    })
  }
}
