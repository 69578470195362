/** @typedef {import('@/types/models').ShippingMethod} ShippingMethod */
/** @typedef {import('@/types/models').ShippingMethodDeliveryDay} ShippingMethodDeliveryDay */
/** @typedef {import('@/types/models').ShippingMethodState} ShippingMethodState */

import axios from '@/common/utils/axios'
import i18n from '@/application/i18n/i18n'
import { NormalizedError } from '@/common/utils/errors/normalize-error'

export default {
  /**
   * @param {object} params
   * @returns {Promise<ShippingMethod[]>}
   */
  async findAll(params = {}) {
    const response = await axios.get('/xhr/shippingmethod', { params })
    return response.data
  },

  async findByParams(params = {}) {
    const response = await axios.get('/xhr/shippingmethod', { params })
    return response.data
  },

  /**
   * @param {{ shipping_product_code: string }} params
   * @returns {Promise<ShippingMethodDeliveryDay[]>}
   */
  async findDeliveryDays(params) {
    try {
      const response = await axios.get('/xhr/shippingmethod/delivery-days', { params })
      return response.data
    } catch {
      const message = i18n.t('Could not load shipping method delivery days.')
      throw new NormalizedError(message, 'api.shippingmethod.failed_to_load_delivery_days')
    }
  },

  /**
   * @returns {Promise<string[]>}
   */
  async fetchDestinationCountries() {
    try {
      const response = await axios.get('/xhr/shippingmethod/destination-countries')
      return response.data.destination_countries
    } catch {
      const message = i18n.t('Could not load destination countries')
      throw new NormalizedError(message, 'api.shippingmethod.failed_to_load_destination_countries')
    }
  },

  /**
   * Toggles the state of one or multiple shipping methods.
   * @param {ShippingMethodState[]} shippingMethodsToToggle list of methods whose state must be changed
   * @returns {Promise<ShippingMethodState[]>}
   */
  async toggleShippingMethodState(shippingMethodsToToggle) {
    const response = await axios.post('/xhr/shippingmethod/toggle', shippingMethodsToToggle)
    return response.data
  },
}
