import store from '@/common/stores/store'
import { logError } from '@/common/utils/errors/error-handlers'
import { AnalyticsBrowser } from '@segment/analytics-next'
import sessionRetryMiddleware from '@/common/utils/tracking/session-retry.middleware'

const baseProperties = () => {
  return {
    user_id: store.getters.user.id,
    enabled_beta_features: store.getters.user.enabled_beta_features,
    new_plan_group: store.getters.user.new_plan_group,
    is_enterprise: store.getters.user.is_enterprise_user,
  }
}

const analytics = new AnalyticsBrowser()
analytics.addSourceMiddleware(sessionRetryMiddleware)

export default {
  isTrackingAllowed: false,

  analytics,

  /**
   * Records whenever a user sees a page
   * https://segment.com/docs/connections/spec/page/
   */
  page(pageProperties: Record<string, unknown>) {
    if (!this.isTrackingAllowed) return

    const properties = {
      ...baseProperties(),
      ...pageProperties,
    }

    try {
      this.analytics.page(properties)
    } catch (error) {
      logError(error)
    }
  },

  install(installParams: { isTrackingAllowed: boolean, segmentKey: string }) {
    this.isTrackingAllowed = installParams.isTrackingAllowed
    if (!this.isTrackingAllowed) return

    const writeKey = installParams.segmentKey
    return this.analytics
      .load({ writeKey: writeKey })
      .catch((error: unknown) => logError(error))
  },

  // Ties a user to their actions
  // https://segment.com/docs/connections/spec/identify/
  identify() {
    if (!this.isTrackingAllowed) return

    try {
      this.analytics.identify(store.getters.user.id,
        {
          // createdAt is required by SatisMeter destination
          createdAt: store.getters.user.date_joined,
        },
      )
    } catch (error) {
      logError(error)
    }
  },

  /**
   * Records a specific action a users performs
   * https://segment.com/docs/connections/spec/track/
   * */
  track(eventName: string, eventProperties?: Record<string, unknown>, options?: Record<string, unknown>) {
    if (!this.isTrackingAllowed) return

    const properties = {
      ...baseProperties(),
      ...eventProperties,
    }

    try {
      this.analytics.track(eventName, properties, options)
    } catch (error) {
      logError(error)
    }
  },
}
