import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import store from '@/common/stores/store'

import IntegrationsApi from '@/features/integrations/api/integrations.api'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { countBillableIntegrations } from '@/features/integrations/utils/count-billable-integrations'

/**
 * Check if the user has reached the limit for the number of integrations on their plan.
 */
async function hasReachedIntegrationLimit() {
  const user = store.getters.user

  if (user.features.integrations === -1) {
    return false
  } else {
    const integrations = await IntegrationsApi.findAll()
    const billableIntegrations = countBillableIntegrations(integrations)
    return billableIntegrations > user.features.integrations
  }
}

/**
 * Prevent users from accessing the add route if they have too many integrations.
 */
async function integrationsRouteGuard(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const shouldPreventAddingNewIntegrations = await hasReachedIntegrationLimit()
  if (shouldPreventAddingNewIntegrations) {
    next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  } else {
    next()
  }
}

export default integrationsRouteGuard
