import type { ReturnRule } from '@/types/rules.models'
import axios from '@/common/utils/axios'
import type { AxiosResponse } from 'axios'
import type { CommonPossibilities } from '@/features/rules/types/rules.types'

export async function fetchReturnRules(): Promise<AxiosResponse<ReturnRule[]>> {
  return axios.get('/xhr/rules/incoming')
}

export async function fetchReturnRule(id: string | number): Promise<AxiosResponse<ReturnRule>> {
  return axios.get(`/xhr/rules/incoming/${id}`)
}

export async function createReturnRule(payload: Partial<ReturnRule>): Promise<AxiosResponse<ReturnRule>> {
  return axios.post('/xhr/rules/incoming', payload)
}

export async function updateReturnRule(payload: Partial<ReturnRule>): Promise<AxiosResponse<ReturnRule>> {
  return axios.put(`/xhr/rules/incoming/${payload.id}`, payload)
}

export async function toggleReturnRule(
  id: number,
  payload: Pick<ReturnRule, 'is_active'>,
): Promise<AxiosResponse<ReturnRule>> {
  return axios.put(`/xhr/rules/incoming/${id}`, payload)
}

export async function deleteReturnRule(id: number): Promise<AxiosResponse<void>> {
  return axios.delete(`/xhr/rules/incoming/${id}`)
}

export async function reorderReturnRules(payload: string[]): Promise<AxiosResponse<void>> {
  return axios.post('/xhr/rules/incoming/reorder', payload)
}

export async function fetchReturnRulePossibilities(): Promise<AxiosResponse<CommonPossibilities>> {
  return axios.get('/xhr/rules/incoming/possibilities')
}
