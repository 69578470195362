/**
 * Returns unique item arrays.
 * For comparison it uses the keys, that are returned by `getUniqueKey` for each item.
 * uniqueKey can be of any type (string, objects, numbers, etc.).
 * Comparison is made using direct equality (===)
 */
export const uniqueBy = <T>(
  arr: T[],
  getUniqueKey: (item: T) => unknown,
): T[] => {
  const uniqueMap = new Map<unknown, true>()
  return arr.filter((item) => {
    const uniqueKey = getUniqueKey(item)
    if (!uniqueMap.get(uniqueKey)) {
      uniqueMap.set(uniqueKey, true)
      return true
    }
    return false
  })
}
