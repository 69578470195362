<template>
  <template v-if="user">
    <AppPaymentWallWrapper />
    <AppLoadingBar />
    <template v-if="showOnboardingWizard && isOnboardingRoute">
      <router-view />
    </template>

    <template v-else>
      <div class="main-container layout-with-sidebar">
        <AppSidebar />
        <main :class="wrapperClasses">
          <AppMaxVolumeAlert />
          <AppTitleBar v-if="!isSettingsRoute && !isConversationsRoute && !isOpportunityCenterRoute" />
          <router-view />
        </main>
      </div>
      <template v-if="userHasNewNotificationsBeta">
        <NotificationSidebar />
        <NotificationWidget />
      </template>
      <NotificationPanel v-else />
      <StatuspageWidget v-if="settings" />
      <AppModal />
      <OpportunityFooter v-if="userHasOpportunityCenter" />
    </template>

    <TheHijackFooter v-if="isHijacked" />
  </template>

  <AppErrorPage v-else />
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import AppErrorPage from '@/application/components/AppErrorPage.vue'
import AppLoadingBar from '@/application/components/AppLoadingBar.vue'
import AppMaxVolumeAlert from '@/application/components/AppMaxVolumeAlert.vue'
import AppPaymentWallWrapper from '@/features/payment-wall/components/AppPaymentWallWrapper.vue'
import AppTitleBar from '@/application/components/AppTitleBar.vue'
import AppSidebar from '@/application/components/navigation/AppSidebar.vue'
import OpportunityFooter from '@/features/opportunity-center/components/OpportunityFooter.vue'
import StatuspageWidget from '@/features/statuspage-widget/components/StatuspageWidget.vue'
import TheHijackFooter from '@/application/components/TheHijackFooter.vue'

import type { Settings, User } from '@/types/models'

import { SHIPPING_LABEL_DETAILS_ROUTE } from '@/features/label-details/routes'
import { RETURN_DETAILS_ROUTE } from '@/features/returns/routes'
import { SETTINGS_PRODUCTS_ROUTE, SETTINGS_ROUTE } from '@/application/routes/settings.routes.names'
import { PACKGO_QUEUE_ROUTE } from '@/features/packgo/routes'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { NEW_NOTIFICATIONS_BETA } from '@/features/support/beta-features'
import { OPPORTUNITY_CENTER } from '@/features/opportunity-center/beta-features'
import { WHATSAPP_CONVERSATIONS_ROUTE } from '@/features/whatsapp-conversations/routes'
import { OPPORTUNITY_CENTER_ROUTE } from '@/features/opportunity-center/routes'
import { hsqTrackPage, shouldTriggerHubspot } from '@/common/utils/marketing-tools/hubspot'

const NotificationPanel = defineAsyncComponent(
  () => import('@/features/notifications/components/NotificationPanel.vue'),
)
const NotificationSidebar = defineAsyncComponent(
  () => import('@/features/notifications/components/NotificationSidebar.vue'),
)
const NotificationWidget = defineAsyncComponent(
  () => import('@/features/notifications/components/NotificationWidget.vue'),
)
const AppModal = defineAsyncComponent(() => import('@/application/components/AppModal.vue'))

const store = useStore()
const route = useRoute()

const user = computed<User>(() => store.getters.user)
const settings = computed<Settings>(() => store.getters.settings)
const isHijacked = computed<boolean>(() => store.getters.isHijacked)
const showOnboardingWizard = computed<boolean>(() => store.getters.showOnboardingWizard)
const userHasNewNotificationsBeta = computed<boolean>(() => store.getters.hasBetaFeature(NEW_NOTIFICATIONS_BETA))

const userHasOpportunityCenter = computed<boolean>(() => store.getters.hasBetaFeature(OPPORTUNITY_CENTER))

onBeforeMount(async () => {
  const preloader = document.querySelector('.preloader')
  if (preloader) {
    preloader.remove()
  }

  if (shouldTriggerHubspot(user.value) && !isOnboardingRoute.value) {
    hsqTrackPage(user.value.email, user.value.id)
  }
})

const wrapperClasses = computed<string>(() => {
  if (route.name === SHIPPING_LABEL_DETAILS_ROUTE || route.name === RETURN_DETAILS_ROUTE) {
    return 'main-wrapper main-wrapper--narrow'
  }
  if (route.name === SHIPPING_INCOMING_ORDERS_ROUTE) {
    return 'main-wrapper main-wrapper--overflow'
  }
  if (route.name === WHATSAPP_CONVERSATIONS_ROUTE) {
    return 'main-wrapper main-wrapper--even-padding'
  }

  // new nav is made with flex row, so we help non-fitting content to shrink with this
  if (route.name === PACKGO_QUEUE_ROUTE || route.name === SETTINGS_PRODUCTS_ROUTE) {
    return 'main-wrapper main-wrapper--min-width-0 main-wrapper--increased-padding-bottom'
  }

  return 'main-wrapper'
})

const isSettingsRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === SETTINGS_ROUTE)
})

const isOnboardingRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === ONBOARDING_WIZARD_ROUTE)
})

const isConversationsRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === WHATSAPP_CONVERSATIONS_ROUTE)
})

const isOpportunityCenterRoute = computed<boolean>(() => {
  return route.matched.some(route => route.name === OPPORTUNITY_CENTER_ROUTE)
})

</script>
