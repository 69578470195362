import type { CTX } from '../types/context.types'
import type { Parser } from '../types/parsers.types'
import { toReadableLiteral, keysOf } from '../utils/helpers'

class EnumValuesParser<T, U extends readonly T[]> implements Parser<U[number]> {
  expected: string
  valuesSet: Set<T>
  constructor(values: U) {
    const expectedString = values.map(toReadableLiteral).join(' | ')
    this.expected = values.length > 1 ? `(${expectedString})` : expectedString
    this.valuesSet = new Set(values)
  }

  parse(data: unknown, ctx: CTX): U[number] {
    ctx.assert(
      (typeof data === 'string' || typeof data === 'number') && this.valuesSet.has(data as T),
      this.expected,
      toReadableLiteral(data),
    )
    return data as U[number]
  }
}

/**
 * `enumValue()` will check if data is of Enum type.
 *
 * Parser can accept both TS Enums and array literals.
 *
 * ```ts
 * // Examples:
 *
 * enum A {
 *   A = 'some',
 *   B = 1,
 *   C = 'hey',
 * }
 * // Both parsers will check for the same thing:
 * enumValue(A) // valid: 'some', 1, 'hey', invalid: anything else
 * enumValue(['some', 1, 'hey']) // valid: 'some', 1, 'hey', invalid: anything else
 *
 * enum B {
 *   A,
 *   B,
 *   C,
 * }
 * // Supports numeric enums too (both will parse the same thing):
 * enumValue(B) // valid: 0, 1, 2, invalid: anything else
 * enumValue([1, 2, 3]) // valid: 0, 1, 2, invalid: anything else
 * ```
 */
// Overload 1: Can accept array of values V[]
export function enumValue<V extends string | number, T extends readonly V[]>(values: T): Parser<T[number]>
// Overload 2: can accept enum
export function enumValue<TEnum extends Record<string, string | number>>(
  enumDescription: TEnum,
): Parser<TEnum[keyof TEnum]>
// Overload 3: combines overloads above - can accept enum | V[]
export function enumValue<V extends string | number, TEnum extends Record<string, V>>(
  enumDescription: TEnum | V[],
): Parser<V> {
  if (Array.isArray(enumDescription)) {
    return new EnumValuesParser(enumDescription)
  }
  const values = keysOf(enumDescription).reduce<V[]>((acc, key) => {
    // isNaN to filter out reverse mapping (number keys)
    // enum keys can only be strings https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
    //  so if there is a number, TS added it and it is just for reverse mapping
    if (!isNaN(+String(key))) {
      return acc
    }
    acc.push(enumDescription[key])
    return acc
  }, [])

  return new EnumValuesParser(values)
}
