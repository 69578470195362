export const SHIPPING_SET_CARRIERS = 'SHIPPING_SET_CARRIERS'
export const SHIPPING_SET_HAS_ACTION_REQUIRED = 'SHIPPING_SET_HAS_ACTION_REQUIRED'
export const SHIPPING_SET_INCOMING_SHIPPING_METHODS = 'SHIPPING_SET_INCOMING_SHIPPING_METHODS'
export const SHIPPING_SET_NOTIFICATION_HISTORY = 'SHIPPING_SET_NOTIFICATION_HISTORY'
export const SHIPPING_SET_SELECTED_ACTIONS = 'SHIPPING_SET_SELECTED_ACTIONS'
export const SHIPPING_SET_SHIPPING_FUNCTIONALITIES = 'SHIPPING_SET_SHIPPING_FUNCTIONALITIES'
export const SHIPPING_SET_SHIPPING_METHODS = 'SHIPPING_SET_SHIPPING_METHODS'
export const SHIPPING_SET_SHIPPING_PRODUCTS = 'SHIPPING_SET_SHIPPING_PRODUCTS'
export const SHIPPING_SET_ANNOUNCEMENT_PROGRESS = 'SHIPPING_SET_ANNOUNCEMENT_PROGRESS'
export const SHIPPING_SET_LOADING_SHIPPING_PRICES = 'SHIPPING_SET_LOADING_SHIPPING_PRICES'
export const SHIPPING_SET_SHIPPING_PRICES = 'SHIPPING_SET_SHIPPING_PRICES'
export const SHIPPING_RESET_SHIPPING_PRICES = 'SHIPPING_RESET_SHIPPING_PRICES'
export const SHIPPING_RESET_SHIPPING_PRICES_BY_ORDER_IDS = 'SHIPPING_RESET_SHIPPING_PRICES_BY_ORDER_IDS'
