import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import store from '@/common/stores/store'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'

/**
 * Check if the user has access to the Onboarding Wizard
 */
export const redirectAwayOrToOnboardingWizard = (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!store.getters.onboardingWizardSkipped && store.getters.showOnboardingWizard && !to.path.includes('onboarding')) {
    return next({ name: ONBOARDING_WIZARD_ROUTE })
  } else if (!store.getters.showOnboardingWizard && to.path.includes('onboarding')) {
    return next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  } else {
    return next()
  }
}
