import PickupAddressService from '@/features/addresses/api/pickup-address.api.js'
import {
  PICKUPS_CREATE_ADDRESS,
  PICKUPS_FETCH_ADDRESSES,
  PICKUPS_DELETE_ADDRESS,
  PICKUPS_UPDATE_ADDRESS,
  PICKUPS_FETCH_ADDRESS,
} from '@/features/addresses/stores/pickup-addresses/action.types.js'
import { PICKUPS_SET_ADDRESS, PICKUPS_SET_ADDRESSES } from '@/features/addresses/stores/pickup-addresses/mutation.types.js'

const state = {
  pickupAddresses: [],
  pickupAddress: {},
}

const getters = {
  pickupAddresses: state => state.pickupAddresses,
  pickupAddressById: state => id => state.pickupAddresses.find(item => item.id === id),
}

const actions = {
  async [PICKUPS_FETCH_ADDRESSES]({ commit }) {
    const response = await PickupAddressService.findAll()
    commit(PICKUPS_SET_ADDRESSES, response.data)
  },
  async [PICKUPS_FETCH_ADDRESS]({ commit }, id) {
    const response = await PickupAddressService.find(id)
    commit(PICKUPS_SET_ADDRESS, response.data)
  },
  async [PICKUPS_DELETE_ADDRESS]({ dispatch }, id) {
    await PickupAddressService.destroy(id)
    await dispatch(PICKUPS_FETCH_ADDRESSES)
  },
  async [PICKUPS_CREATE_ADDRESS]({ dispatch }, address) {
    await PickupAddressService.create(address)
    await dispatch(PICKUPS_FETCH_ADDRESSES)
  },
  async [PICKUPS_UPDATE_ADDRESS]({ dispatch }, address) {
    await PickupAddressService.update(address)
    await dispatch(PICKUPS_FETCH_ADDRESSES)
  },
}

const mutations = {
  [PICKUPS_SET_ADDRESSES](state, addresses) {
    state.pickupAddresses = addresses
  },
  [PICKUPS_SET_ADDRESS](state, address) {
    state.pickupAddress = address
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
