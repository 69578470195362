import store from '@/common/stores/store'
import { PICKUPS_FEATURE_TEASER, PICKUPS_ARCHIVE_SCHEDULED_ROUTE } from '@/features/pickups/routes'
import type { NavigationGuardNext, RouteLocation } from 'vue-router'

export function isPickupsFeatureEnabled() {
  return store.getters.user?.new_plan_group === 'free'
}

// Make CarrierPickupsFeature active for users that have Invoice Address with countries in a specific subset
export function isPickupsAllowedCountries() {
  const countriesDisabled = store.getters.user?.invoice_country_iso_2
  if (countriesDisabled.includes('DE') || countriesDisabled.includes('AT') || countriesDisabled.includes('FR') || countriesDisabled.includes('IT')) {
    return false
  } else {
    return true
  }
}

export function pickupsGuard(_to: RouteLocation, _from: RouteLocation, next: NavigationGuardNext) {
  if (isPickupsFeatureEnabled() && isPickupsAllowedCountries()) {
    next({ name: PICKUPS_FEATURE_TEASER })
  } else {
    next()
  }
}

export function pickupsGuardUpgrade(_to: RouteLocation, _from: RouteLocation, next: NavigationGuardNext) {
  if (isPickupsFeatureEnabled() && isPickupsAllowedCountries()) {
    next()
  } else {
    next({ name: PICKUPS_ARCHIVE_SCHEDULED_ROUTE })
  }
}
