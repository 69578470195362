import store from '@/common/stores/store'
import { SETTINGS_RETURN_RULES_LIST_ROUTE } from '@/features/return-rules/routes'

import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export function userHasReturnRulesAccess() {
  // is staff account OR the return_rules feature should be enabled
  return store.getters.user?.is_staff || store.getters.user?.features?.return_rules
}

export function returnRuleDetailsGuard(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (!userHasReturnRulesAccess()) {
    next({ name: SETTINGS_RETURN_RULES_LIST_ROUTE })
  } else {
    next()
  }
}
