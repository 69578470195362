import type { AnyFunction } from '@/common/data-parsers'

/**
 * TODO: ATTENTION:
 * This function was just found in one of the OPTIONS API components and moved to common.
 * It is not properly tested or reviewed.
 *
 * It needs a lot of improvements to be better.
 * It does not accept arguments, does not allow you to config trailing/leading calls.
 * If you have time, please, improve it. !Do not just use lodash version!
 */
export function debounce<T extends AnyFunction>(func: T, delay: number): () => void {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (this: unknown, ...args: Parameters<T>): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const lastThis = this
    clearTimeout(timeoutId)

    function debouncedCall() {
      func.apply(lastThis, args)
    }

    timeoutId = setTimeout(debouncedCall, delay)
  }
}
