import axios from '@/common/utils/axios'
import { ref } from 'vue'

export function useAxiosRequestCounter() {
  const requestCount = ref<number>(0)
  const increaseCount = () => {
    requestCount.value++
  }
  const decreaseCount = () => {
    requestCount.value--
  }

  axios.interceptors.request.use(
    (config) => {
      increaseCount()
      return config
    },

    (error) => {
      decreaseCount()
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (response) => {
      decreaseCount()
      return response
    },

    (error) => {
      decreaseCount()
      return Promise.reject(error)
    },
  )

  return requestCount
}
