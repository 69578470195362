type ModuleState = typeof state
import type { Commit } from 'vuex'
import type { Box } from '@/features/boxes/types/boxes.types'

import { BOXES_FETCH_ALL_BOXES } from '@/features/boxes/store/boxes/action.types'
import { BOXES_SET_BOXES } from '@/features/boxes/store/boxes/mutation.types'

import BoxApi from '@/features/boxes/api/box.api'

const state = {
  boxes: [] as Box[],
}

const getters = {
  boxes: (state: ModuleState) => state.boxes,
  boxById: (state: ModuleState) => (boxId: number) => state.boxes.find((box: Box) => box.id === boxId),
}

const actions = {
  async [BOXES_FETCH_ALL_BOXES]({ commit }: { commit: Commit }) {
    const response = await BoxApi.findAll()
    commit(BOXES_SET_BOXES, response.data)
  },
}

const mutations = {
  [BOXES_SET_BOXES](state: ModuleState, boxes: Box[]) {
    state.boxes = boxes
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
