import axios from '@/common/utils/axios'
import type { ReturnDefaults } from '@/types/returns'

export default {
  async getReturnDefaults(): Promise<ReturnDefaults> {
    const { data } = await axios.get('/xhr/return-defaults')
    return data
  },

  async updateReturnDefaults(returnFormShippingMethod: number | string, returnOrderShippingMethod: number | string) {
    await axios.patch('/xhr/return-defaults', {
      return_form_shipping_method: returnFormShippingMethod,
      return_order_shipping_method: returnOrderShippingMethod,
    })
  },
}
